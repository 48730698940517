@import 'variables';

$quantity-color: #666666;

.basket-item {
  border-bottom: 1px solid $pz-color-text-disabled-dark;
  padding: 1rem 0;

  .product-info {
    padding-left: 15px;

    &-detail {
      display: flex;
      justify-content: space-between;
      
      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }

      &__price {
        display: flex;
        justify-content: space-between;
        align-items: start;
      }
    }

    .shipping-note{
      margin-top: 24px;
  
      &__area{
        display: flex;
        align-items: center;
        margin-bottom: 12px;
      }
      
      &__text{
        margin-left: 5px;
        font-size: 14px;

        &.message-add {
          color: #dd1111;
        }
      }

      &__input {
        accent-color: #000;
        &.input-checked {
          accent-color: #dd1111;
        }
      }
  
      &__note{
        height: 100px;
        width: 100%;
        resize: none;
      }
  
      &__note::placeholder { 
        font-size: 12px !important;
      }
  
      &__footer{
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
      }
  
      &__save-btn{
        width: 180px;
        height: 40px;
        font-size: 14px;
        background-color: transparent;
        border: 1px solid black;
        outline: none;
      }
  
      &__char-info-container{
        display: flex;
      }
  
      &__charcount, &__maxchar{
        font-size: 12px;
        color: #818181;
      }
    }
  }

  &__remove {
    &>a {
      color: $basket-item-remove;
      background: white;
    }
  }

  &__quantity {
    border: 1px solid #dfdfdf;
    font-size: 12px;
    height: 36px;
    width: 74px;
    margin-bottom: 10px;

    &>button {
      display: block;
      border: none;
      width: 2rem;
      min-width: 1.5rem;
      padding: 0;
      background-color: transparent;
      color: #666666;
    }

    &__input {
      width: 1rem;
      height: 2rem;
      border: none;
      padding: 0;
      text-align: center;
      color: #666666;
    }

    &>button,
    &__input {
      &:focus {
        outline: 0;
      }
    }
  }

  @media (min-width: 767px) {
    &-product-image {
      max-width: 100px;
    }
  }

  &__image {
    width: 94px !important;
    height: 143px !important;
  }

  &__product-name {
    padding:0 30px 0 0;

    &>a {
      color: $basket-item-product-name;
      font-size: 14px;
      margin-bottom: 10px;
      display: block;
    }
  }

  &__product-description {
    color: $pz-color-text-secondary-dark;
    font-size: 0.9rem;
  }

  &__product-attr {
    color: #818181;
    font-size: 12px;
  }

  .price-container {
    padding-left: 50px;
  }

  &__retail-price,
  &__price {
    font-family: 'Financier Regular';
  }

  &__retail-price {
    color: $basket-item-retail-price;
    text-decoration: line-through;
    color: #818181;
    font-size: 14px;
  }

  &__price-currency {
    font-size: 0.95rem;
  }

  &__price {
    display: flex;
    align-items: center;
    font-weight: bold;
    color: $basket-item-price;
    font-size: 1.2rem;
  }
}

pz-modal{
  .basket-item-remove-button{
    width: 100%;
    max-width: 110px;
    height: 50px;
    padding: 15px;
    border: solid 1px #000;
    font-size: 16px;
    text-align: center;
    display: inline-block;
    line-height: 1;
    cursor: pointer;
    @media (max-width: 767px) {
      max-width: 100%;
      display: block;
      margin-top: 15px;
      order: 2;
    }
  }

  .basket-item-product-remove-and-fav{
    width: 100%;
    max-width: 330px;
    height: 50px;
    padding: 15px 50px;
    line-height: 1;
    background-color: #000;
    border: solid 1px #000;
    font-size: 16px;
    text-align: center;
    color: #fff;
    display: inline-block;
    cursor: pointer;
    @media (max-width: 767px) {
      max-width: 100%;
      display: block;
      order: 1;
    }
  }
  
  .button-wrapper{
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
    @media (max-width: 767px) {
      flex-direction: column;
    }
  }
}
@import './variables';

.input-wrapper {
  margin-bottom: $input-wrapper-margin-bottom;
}

.input[type="text"], .input[type="password"],
.input[type="phone"], .input[type="email"], 
.input[type="tel"] {
  width: $input-width;
  height: 54px;
  line-height: 50px;
  border: solid 1px #c9c9c9;
  padding: 0 16px;
  font-size: 14px;

  &::placeholder {
	  color: #9d9d9d;
	}
}
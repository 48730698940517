.land-image {
  padding: 20px 0;

  @media (max-width: 768px) {
    padding: 30px 0 0;
    text-align: center;
  }

  &__prd-title, &__prd-price {

    @media (max-width: 768px) {
      font-size: .9rem;
      line-height: 1.25rem;    }
  }

  a > *:last-child {
    margin-bottom: 60px;
  }

  .col-6 {
    @media (max-width: 768px) {
      padding: 0 5px !important;
    }
  }

  .col-md-6 {
    @media (max-width: 768px) {
      padding: 0 !important;
    }
  }
}
.user-panel{
  &__auth{
    a {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    i{
      color: #9d9d9d;
      font-size: 24px;

      @media only screen and (max-width: 768px) {
        color: #000000;
      }
    }
    .user-panel-icon-text {
      font-size: 12px;
      color: #000;

      @media only screen and (max-width: 768px) {
        display: none !important;
      }
    }
  }
}
@font-face {
  font-family: 'Financier Regular';
  src: url('./FinancierText-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Financier Medium';
  src: url('./FinancierDisplay-Medium.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Financier Bold';
  src: url('./FinancierText-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Plain Regular';
  src: url('./Plain-Regular.otf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Plain Medium';
  src: url('./Plain-Medium.otf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Plain Bold';
  src: url('./Plain-Bold.otf') ;
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Plain Light';
  src: url('./Plain-Light.otf') ;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Plain UltraThin';
  src: url('./Plain-Ultrathin.otf') ;
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Plain Thin';
  src: url('./Plain-Thin.otf') ;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
.app-download {
  display: flex;
  flex-direction: column;
  background-color: #dedcd5;
  font-family: FinancierDisplay;
  
  &__content {
    padding:0 145px;
  }

  &__first {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 145px 200px 50px;
    
    @media (max-width:768px) {
      margin: 25px 15px;
    }

    .title {
      font-size: 100px;
      font-weight: 300;
      margin-bottom: 15px;
      text-transform: uppercase;

      @media (max-width:768px) {
        font-size: 32px;
      }
    }

    .subtitle {
      font-size: 45px;
      font-weight: 500;
      line-height: 1.1;
      margin: 0 80px;

      @media (max-width:768px) {
        font-size: 24px;
        margin: 0;
      }
    }
  }

  &__second {
    margin-top: 30px;

    .img_one {
      max-width: 808px;
      margin: 0 auto;
      position: relative;

      @media (max-width:768px) {
        width: 280px;
      }  
      
      @media (max-width:320px) {
        width: 230px;
      }  
    }

    .img_two {
      max-width: 295px;
      position: absolute;
      left: -16%;
      top: 25%;

      @media (max-width:768px) {
        width: 100px;
      }
      
      @media (max-width:320px) {
        width: 80px;
      } 
    }

    .img_three {
      max-width: 377px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);

      @media (max-width:768px) {
        width: 135px;
      }  

      @media (max-width:320px) {
        width: 115px;
      } 
    }

    .img_four {
      max-width: 295px;
      position: absolute;
      right: -17%;
      top: 25%;

      @media (max-width:768px) {
        width: 100px;
      }  

      @media (max-width:320px) {
        width: 80px;
      } 
    }

  }

  &__third {
    text-align: center;
    width: 808px;
    margin:40px auto 100px;

    @media (max-width:768px) {
      width: 280px;
      margin: 20px auto 35px;
    }

    @media (max-width:320px) {
      width: 230px;
    } 

    .second-subtitle {
      font-size: 45px;
      margin-bottom: 20px;

      @media (max-width:768px) {
        font-size: 16px;
      }

      span{
        font-weight: bold;
      }
    }

    &-button {
      @media (max-width:768px) {
        margin-right: 15px !important;
      }
    }

    img {
      border-radius: 25px;

      @media (max-width:768px) {
        border-radius: 5px;
      }
    }
  }

  .border-line {
    height: 2px;
    width: 290px;
    background-color: #000;
    margin: 0 auto;

    @media (max-width:768px) {
      width: 175px;
    }
  }

  &__fourth {
    width: 1100px;
    margin: 100px auto;
    font-size: 27px;

    @media (max-width:768px) {
      width: unset;
      font-size: 17px;
      margin: 20px 15px 20px 0;
    }

    &-subtitle {
      line-height: 1.1;
    }

    ul {
      list-style: disc;
      font-weight: bold;
    }
  }
}

.app-download__fifth {
  margin-top: 100px;

  @media (max-width:768px) {
    margin-top: 20px;
  }
}
@import './variables.scss';

.product__variant--image {
  width: $product-variant-image-width;
  height: $product-variant-image-height;

  @media (max-width: 991px){
    width: 80px;
    height: 130px;
  }
}

.product__variant {
  position: relative;
  padding-bottom: 10px;
  border-bottom: 2px solid transparent;
  margin-right: 20px;
  cursor: pointer;

  @media (max-width:768px) {
    margin-right: 5px;
  }

  &:after {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 100%;
  }

  &.disabled {
    opacity: 0.3;
    @media (max-width:768px){
      text-decoration: line-through !important;
    }
  }

  &.disabled-color {
    opacity: 0.3;
  }

  &.selected:after {
    background-color: #000;
  }

  &[data-attribute-name="Drop"]{
    pointer-events: none;
  }

  &--title {
    font-size: 12px;
    margin-bottom: 10px;

    .choose-size {
      padding: 5px 5px 5px 0;
      @media (max-width:768px) {
        border: 1px solid #6c757d;
        padding: 5px;
        text-align: center;
        color: #000 !important;
        max-width: 49%;
      }
    }

    a {
      i {
        @media (max-width:768px) {
          font-size: 0;
        }
      }
    }
  }

  &--img {
    @media (max-width:768px) {
      overflow: auto;
      flex-wrap: nowrap !important;
    }
  }

  &--error {
    font-size: 12px;
    
    &.red {
      color: #d72b01;
      border: 2px solid #d72b01;
      font-size: 16px;
      padding: 5px 5px 7px;
      font-weight: 600;
      margin-bottom: 5px;
      display: inline-block;
    }
  }

  &--sizes {
    @media (max-width:768px) {
      border: 1px solid #000;
      padding: 3px;
      border-radius: 5px;
      width: 35px;
    }
  }
}

.product__size-variant {
  @media (max-width:768px) {
    padding-bottom: 0 !important;
  }

  &.selected {
    @media (max-width:768px) {
      border-radius: 5px;
      background-color: #000;
      color: #fff !important;
    }
  }
}

.account-checkbox {
  display: inline-block;
  vertical-align: top;
  height: 30px;

  &:before {
    top: 0 !important;
    font-size: 22px;
  }
  &[type=checkbox]:checked:after {
    top: 0px;
  }

  &:checked:after {
    font-size:22px;
  }
}

.input-info-text {
  color: #9d9d9d;
  font-size: 14px;
  width: calc(100% - 38px);
  margin-left: 16px;
  display: inline-block;
}
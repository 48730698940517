.cookie-modal {
  position: fixed;
  bottom: 0;
  right: 40px;
  width: 408px;
  max-width: 100%;
  z-index: 99999;
  padding: 15px;
  height: 150px;
  line-height: 1.4;
  color: #fff;
  background-color: rgba(16, 16, 16, 0.87);
  right:0;
  
  .cookie-content {
    display: inline-block;
    font-size: 13px;

    a {
      text-decoration: underline;
    }
  }

  .cookie-close-btn {
    cursor: pointer;
    padding: 10px;
    font-size: 12px;
    background-color: #000;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 8px 80px !important;
  }

  .cookie-close-icon {
    cursor: pointer;
    float: right;
    font-size: 20px;
  }
  
}

.page-order-tracking {
	.button {
		font-size: 16px !important;
	}
	.login-form__title {
		font-size: 24px;
		font-weight: 500 !important;
		color: #000000;
		font-family: "Plain Light";
	}
	.form-group .text-reset {
		text-decoration: underline !important;
	}
	.button-wrapper button {
		background-color: #000;
		color: #fff;
	}
	.text-muted {
		color: #6c757d !important;
	}
	.auth__form--facebook {
		i {
			color: #4267b2;
		}
		.button {
			text-align: center;
			font-weight: 600;
			border: solid 2px #4267b2;
			padding: 15px;
			background-color: #fff;
			text-decoration: none !important;
			color: #000;
		}
	}
	.auth__form__bottom {
		.text {
			color: #6c757d !important;
			font-size: 1rem;
		}
		.register {
			display: block;
			font-weight: 600;
		}
	}

	.order-tracking {
		&__form {
			.input-wrapper {
				margin-bottom: 20px;
				.email,
				.order-no {
					font-size: 0.9rem;
					font-weight: 600;
					margin-left: 0;
				}
				.email__input,
				.order-no__input {
					width: 100%;
					height: 56px;
					padding: 19px 11px 18px 11px;
					border: solid 1px #c9c9c9;
					background-color: #ffffff;
					outline: none;
				}

				&__label {
					font-size: 14px;
					margin-bottom: 5px;
					display: block;
				}
			}
			.order-tracking-btn {
				width: 100%;
				background-color: #000;
				color: #fff;
				height: 52px;
				line-height: 50px;
				text-align: center;
				border: 0 none;
			}
		}
		&__description {
			font-family: Plain;
			font-size: 14px;
			font-weight: 500;
			color: #000;
		}

		&__login {
			color: #000;
			text-align: center;
			display: block;
			text-decoration: underline !important;
			margin-top: 5px;
			font-size: 14px;
		}
	}
	.error {
		color: #b80000;
		padding-bottom: 10px;
	}
}

@include media-breakpoint-down(sm) {
	.auth-tabs {
		.tab {
			.active {
				border-bottom: none;
				border-color: #e5e5e5;
				background-color: #eee;
				z-index: 1;
			}
			a {
				color: #000 !important;
			}
		}
	}
	.order-tab-1 {
		padding: 0 2px;
	}
	[data-content].active {
		background-color: #eee;
	}
	.auth__form,
	.order_tracking__form {
		padding: 37px 28px 20px;
	}
	.auth__form__bottom {
		padding-bottom: 20px;
	}
	.order-tracking {
		padding: 36px 22px 55px;
		border: solid 1px #f2f2f2;
	}
	.order-tracking__detail {
		padding: 0 4% !important;
	}
}

.account-content {
	.account-menu {
		display: none;
	}

	.order-detail__title {
		font-size: 19px;
	}

	.order-timeline__icon {
		width: 75%;

		span {
			position: relative;
			width: 9px;
			height: 9px;
			border-radius: 50%;
			background-color: #6c757d;

			&:not(:last-child):after {
				content: "";
				position: absolute;
				top: 4px;
				left: 50%;
				width: 100vw;
				height: 1px;
				background-color: #6c757d;
			}

			&.active,
			&.active:after {
				background-color: #000000;
			}
		}
	}

	.order-timeline {
		font-size: 13px;
		color: #6c757d;
	}

	.button-wrapper {
		padding-bottom: 33px;
		width: 50%;

		.button {
			font-size: 16px;
			border: none;
			color: #fff !important;	
		}
	}

	.button-border {
		border-bottom: solid 1px #c9c9c9;
	}

	.order-detail__mid {
		&__title {
			font-size: 19px;
			cursor: pointer;
			border-bottom: solid 1px #c9c9c9;

			i {
				transition: all 0.2s ease;
			}

			&.active {
				border-bottom: none;

				i {
					transform: rotate(180deg);
				}
			}
		}

		&__content {
			font-size: 13px;

			&.active {
				border-bottom: solid 1px #c9c9c9;
			}
		}
	}

	.order-detail__bottom {
		&__title {
			font-size: 19px;

			span {
				font-size: 13px;
			}
		}

		&__content {
			font-size: 13px;
			border-bottom: solid 1px #c9c9c9;

			.order-item,
			.order-item__subtotal {
				border-bottom: solid 1px #c9c9c9;
			}

			.order-item__name,
			.order-item__total {
				font-weight: 600;
			}

			.order-item__code {
				margin-top: 65px;
			}
		}
	}

	@include media-breakpoint-up(sm) {
		.account-menu {
			display: block;
		}

		.order-detail__title {
			margin-top: 72px;
			padding-bottom: 22px;
			border-bottom: solid 1px #eee;
		}

		.order-timeline__icon {
			width: 60%;
		}

		.order-timeline {
			width: 65%;
		}

		.button-wrapper {
			width: 20%;
		}

		.order-detail__mid {
			width: 66.6%;

			&__title {
				cursor: unset;
				border-bottom: none;
			}

			&--container {
				width: 50%;
			}
		}

		.order-detail__bottom {
			width: 33.3%;

			&__content {
				border-bottom: none;
			}

			.button-wrapper {
				width: 60%;
			}
		}
	}
}
.order-tracking__detail {
	padding: 0 2%;
}

.order-detail__return-code {
	width: 350px;
	border: 1px solid #7f0910;
	margin: 0 auto;
	border-radius: 5px;
  padding: 5px;

	span {
		color: #c2000b;
	}
}

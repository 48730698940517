.blog-categories {
  h3 {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    color: #000;
    font-weight: bold;
    font-size: 35px;
  }
}

.mini-basket-item {
  border-bottom: 1px solid #f1f1f1;
  padding: 0.5rem 0;
  height: auto;

  .js-mini-basket-item-remove-button .icon-cancel {
    font-size: 12px;
    color: #666666;
  }

  &__remove {
    position: absolute;
    bottom: 0.5rem;
    right: 15px;

    & > a {
      color: $mini-basket-item-remove;
      text-decoration: underline !important;
    }
  }

  &__quantity {
    border: 1px solid $pz-color-text-secondary-dark;
    width: fit-content;
    margin-bottom: 1rem;
    font-size: 12px;    
    color: #666666;

    & > button {
      background: transparent;
      display: block;
      font-size: 0.9rem;
      border: none;
      width: 2rem;
      min-width: 1.5rem;
      height: 2.5rem;
      padding: 0;
      color: #666666;

      &:focus {
        outline: 0;
      }

      i {
        font-size: 12px;
      }
    }
  }

  &__image {
    width: 94px !important;
    height: 143px !important;
  }

  &__product-name > a {
    font-size: 0.9rem;
    color: $mini-basket-item-product-name;
    margin-bottom: 8px;
  }

  &__product-description {
    color: $pz-color-text-secondary-dark;
    font-size: 0.9rem;
  }

  &__product-attr {
    color: #818181;
    font-size: 12px;
    margin-bottom: 13px;
  }

  &__retail-price, &__price {
    font-family: 'Financier Regular';
  }

  &__retail-price {
    color: $pz-color-text-secondary-dark;
    text-decoration: line-through;
    padding-top: 3px;
  }

  &__price-currency {
    font-size: 0.95rem;
  }

  &__price {
    color: $mini-basket-item-price;
    font-size: 1.1rem;
    font-weight: bold;
  }

}

/* The Modal (background) */
pz-modal {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.500);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  transition: all 0.3s;
}

/* Modal Content/Box */
.pz-modal-dialog {
  max-width: 500px;
  min-width: 335px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 15px;
  background: white;
}

.pz-modal-dialog__header{
  display: flex;
  justify-content: space-between;
}

/* The Close Button */
.close-button {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.title{
  font-size: 20px;
  font-weight: 500;
  color: #212529;
  display: inline-flex;
}
$product-content-margin-left: 30px !default;

$product-title-size: 1rem !default;
$product-title-color: #000000 !default;

$product-brand-size: 1rem !default;
$product-brand-color: $product-title-color !default;

$product-text-size: 1rem !default;
$product-text-color: $product-title-color !default;

$product-price-size: 1.25rem !default;
$product-retail-price-color: #818181 !default;
$product-price-color: #000 !default;
$product-price-currency-size: 1.25rem !default;

$product-retail-price-size: 1.5rem !default;
$product-retail-price-color: #000000 !default;
$product-retail-price-decoration: line-through !default;

$product-favorite-size: 0.75rem !default;
$product-favorite-color: $product-title-color !default;
$product-favorite-color-active: red !default;
.akifast-wrapper {
  --radius: 18px;
  --padding: 6px;
  --inner-radius: calc(var(--radius) - var(--padding));
  --outer-size: calc(var(--inner-radius) * 2);
  position: relative;
  align-items: center;
  margin-top: auto;
  display: flex;
  flex-wrap: wrap;
  &__login {
    display: block;
  }
  &.disabled .btn-akifast {
    pointer-events: none;
    opacity: 0.5;
  }

  &-detail,
  &-list {
    .pz-providers-quick-pay-button {
      display: none !important;
    }
  }
  .form-group {
    font-size: 14px;
  }
  .pz-providers-quick-pay-button {
    margin-top: 0 !important;
    background-color: #000;
    color: #fff;
    height: auto;
    padding: 1rem 1rem 1rem calc(var(--radius) + var(--padding));
    border: none;
    line-height: 1;
    font-size: 14px;
    text-transform: unset;
    &:hover {
      background-color: #000;
      color: #fff;
    }
  }
  .belt {
    position: absolute;
    left: 0;
    background-color: #fff;
    border-radius: var(--radius);
    padding: var(--padding);
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    &__button {
      width: var(--inner-radius);
      height: var(--inner-radius);
      border-radius: var(--inner-radius);
      background-color: #d02c2f;
    }
  }
  .btn-akifast {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 1rem 1rem calc(var(--radius) + var(--padding));
    width: 100%;
    background-color: #000;
    color: #fff !important;
    border: none;
    line-height: 1;
    font-size: 14px;
    font-weight: 600;
    &--disabled {
      background-color: #e2e2e2;
      color: #a9a9a9 !important;
      &:hover {
        cursor: not-allowed;
      }
      .belt {
        &__button {
          background-color: #a9a9a9;
        }
      }
    }
  }
  &-list {
    --radius: 15px;
    --padding: 5px;
    .btn-akifast {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
    }
  }
}

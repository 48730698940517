$pz-color-primary: #009688 !default;
$pz-color-primary-light: #05a193 !default;
$pz-color-primary-dark: #009184 !default;
$pz-color-secondary: #f50057 !default;
$pz-color-error: #d32f2f !default;
$pz-color-warning: #f57c00 !default;
$pz-color-info: #1976d2 !default;
$pz-color-success: #388e3c !default;

$pz-color-text-primary-dark: rgba(0, 0, 0, 0.87) !default;
$pz-color-text-secondary-dark: rgba(0, 0, 0, 0.54) !default;
$pz-color-text-disabled-dark: rgba(0, 0, 0, 0.38) !default;

$pz-color-text-primary-light: #fff !default;
$pz-color-text-secondary-light: rgba(255, 255, 255, 0.7) !default;
$pz-color-text-disabled-light: rgba(255, 255, 255, 0.5) !default;

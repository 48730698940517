@import "../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "../node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
@import "../node_modules/@fortawesome/fontawesome-free/scss/brands.scss";
@import "../node_modules/@fortawesome/fontawesome-free/scss/regular.scss";

.arrow-down {
  @extend %fa-icon;
  @extend .fas;

  &:after {
    content: fa-content($fa-var-chevron-down);
  }
}

.arrow-up {
  @extend %fa-icon;
  @extend .fas;

  &:after {
    content: fa-content($fa-var-chevron-up);
  }
}

.arrow-right {
  @extend %fa-icon;
  @extend .fas;

  &:after {
    content: fa-content($fa-var-chevron-right);
  }
}

.arrow-left {
  @extend %fa-icon;
  @extend .fas;

  &:after {
    content: fa-content($fa-var-chevron-left);
  }
}

.trash {
  @extend %fa-icon;
  @extend .fas;

  &:after {
    content: fa-content($fa-var-trash-alt);
  }
}

.sync {
  @extend %fa-icon;
  @extend .fas;

  &:after {
    content: fa-content($fa-var-sync);
  }
}

.plus {
  @extend %fa-icon;
  @extend .fas;

  &:after {
    content: fa-content($fa-var-plus);
  }
}

.minus {
  @extend %fa-icon;
  @extend .fas;

  &:after {
    content: fa-content($fa-var-minus);
  }
}

.heart-outline {
  @extend %fa-icon;
  @extend .far;

  &:after {
    content: fa-content($fa-var-heart);
  }
}

.heart-solid {
  @extend %fa-icon;
  @extend .fas;

  &:after {
    content: fa-content($fa-var-heart);
  }
}

.close {
  @extend %fa-icon;
  @extend .fas;

  &:after {
    content: fa-content($fa-var-times);
  }
}


.truck {
  @extend %fa-icon;
  @extend .fas;

  &:after {
    content: fa-content($fa-var-truck);
  }
}


.bolt {
  @extend %fa-icon;
  @extend .fas;

  &:after {
    content: fa-content($fa-var-bolt);
  }
}

.archive {
  @extend %fa-icon;
  @extend .fas;

  &:after {
    content: fa-content($fa-var-archive);
  }
}

.user {
  @extend %fa-icon;
  @extend .fas;

  &:after {
    content: fa-content($fa-var-user);
  }
}

.map-marker {
  @extend %fa-icon;
  @extend .fas;

  &:after {
    content: fa-content($fa-var-map-marker);
  }
}

.at {
  @extend %fa-icon;
  @extend .fas;

  &:after {
    content: fa-content($fa-var-at);
  }
}


.lock {
  @extend %fa-icon;
  @extend .fas;

  &:after {
    content: fa-content($fa-var-lock);
  }
}

.cog {
  @extend %fa-icon;
  @extend .fas;

  &:after {
    content: fa-content($fa-var-cog);
  }
}

.gem {
  @extend %fa-icon;
  @extend .far;

  &:after {
    content: fa-content($fa-var-gem);
  }
}

.envelope {
  @extend %fa-icon;
  @extend .fas;

  &:after {
    content: fa-content($fa-var-envelope);
  }
}

.shopping-cart {
  @extend %fa-icon;
  @extend .fas;

  &:after {
    content: fa-content($fa-var-shopping-basket);
  }
}

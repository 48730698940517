.account__menu {
  display: none;
  border-right: 1px solid #dfdfdf;
  padding-bottom: 250px;
}

.account-menu__item {
  &-title {
    font-family: 'Financier Regular';
    font-size: 24px;
    margin-bottom: 16px;
    line-height: 1;
    color: #000 !important;
  }

  &-link {
    margin-bottom: 12px;
    line-height: 1;

    &.active {
      text-decoration: underline;
    }
  }
}

.mini-profile {
  position: relative;
}
.user-panel-icon {
  display: flex;
  flex-direction: column;
  align-items: center;

  .user-panel-icon-text {
    font-size: 12px;
    color: #000000;

    @media only screen and (max-width: 768px) {
      display: none !important;
    }
  }
}
.mini-profile__content {
  top: 0;
  z-index: 101;
  right: -100%;
  width: 350px;
  display: flex;
  height: 100vh;
  position: fixed;
  flex-direction: column;
  background-color: #ffffff;
  padding-bottom: 150px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  .profile__title {
    color: #000000;
    padding: 15px 0;
    position: relative;
    border-bottom: solid 1px #dfdfdf;

    &--text {
      font-family: 'Financier Regular';
      font-size: 1.5rem;
      color: #000;
      margin: 0;
    }

    .close__button {
      top: 18px;
      right: 25px;
      color: #818181;
      cursor: pointer;
      font-size: 14px;
      position: absolute;
    }
  }

  .account-menu__item-title {
    display: none;
  }

  .account-menu__item-list {
    flex-direction: column;
    padding: 1rem 1rem 0 1rem;
    margin-bottom: 0;

    a {
      height: 44px;
      display: flex;
      color: #666666;
      font-size: 14px;
      padding-left: 10px;
      align-items: center;
      vertical-align: middle;
      margin-bottom: 0;

      i {
        display: inline-block;
        color: #9d9d9d;
        font-size: 20px;
        padding-right: 15px;
      }
    }
  }

  form{
    padding-left: 25px;

    button {
      padding: 0;
      border: none;
      display: flex;
      color: #666666;
      font-size: 14px;
      background: none;
      align-items: center;

      i {
        color: #9d9d9d;
        font-size: 20px;
        padding-right: 15px;
      }
    }
  }

  &.active{
    right: 0;
    transition: all 0.3s linear;
  }

  @media only screen and (max-width: 768px) {
    width: 100%
  }
}

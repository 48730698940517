@mixin visible {
    opacity: 1;
    pointer-events: all;
  }
  
  @mixin invisible {
    opacity: 0;
    pointer-events: none;
  }
  
  .click-collect {
    $box: &;
    overflow: hidden;
    font-size: 12px;
  
    &-disabled {
      opacity: .3;
      pointer-events: none;
    }
  
    &__loader {
      @include invisible();
  
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: white;
      z-index: 50;
      transition: .4s all ease;
  
      &::before {
        content: '';
        display: block;
        width: 30px;
        height: 30px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 4px solid transparent;
        border-top-color: gray;
        border-radius: 100%;
        animation: loader-animation .7s infinite linear;
      }
  
      &.-visible {
        @include visible();
      }
    }
  
    &__select {
      width: 180px;
      height: 40px;
      border: 1px solid #eee;
      padding: 0 10px;
      margin-top: 10px;
    }
  
    &__btn,
    &__retail-stores {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    &__btn {
      .icon {
        font-size: 20px;
        height: 24px;
        margin-right: 10px;
      }
    }
  
    &__retail-stores {
      flex-direction: column;
      display: none;
    }
  
    &__content {
      &::after {
        content: none !important;
      }
    }
  
    &__close-btn {
      position: absolute;
      top: 10px;
      right: 10px;
      border: none;
      outline: none;
      background: none;
    }
  
    &.-mounted {
      position: absolute;
      bottom: 0;
      max-width: initial;
      z-index: 50;
    }
  
    &.-active {
      #{$box}__btn {
        display: none;
      }
  
      #{$box}__retail-stores {
        display: flex;
      }
    }
  }
  
  @media screen and (max-width: 768px) {
    .click-collect {
      &.-active {
        height: 220px !important;
      }
    }
  }
  
  @keyframes loader-animation {
    from { transform: translate(-50%, -50%) rotate(0deg); }
    to { transform: translate(-50%, -50%) rotate(360deg); }
  }
.blog-detail {
  h1 {
    font-weight: bold;
    text-transform: uppercase;
  }

  .detail-img {
    display: flex;
    justify-content: space-around;
  }

  .blog-breadcrumb__item {
    .breadcrumb__item {
      color: #a5a5a5;
    }

    span {
      color: #a5a5a5;
    }
  }
  a {
    color: #000;
    text-transform: uppercase;
  }

  h2 {
    text-transform: uppercase;
  }
}

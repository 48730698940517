.blog-banner {
  h3 {
    font-size: 17px;
    color: #fff;
    font-style: italic;
  }

  h2 {
    font-weight: bold;
    font-size: 40px;
    color: #fff;
    font-style: italic;

    @media (max-width: 767px) {
      font-size: 24px;
    }
  }

  span {
    background-color: #fff;
    color: #000;
  }

  &__content {
    bottom: 15px;
    left: 15px;
    width: 70%;

    @media (max-width: 767px) {
      width: 95%;
      left: 10px;
    }

    i {
      margin-left: 10px;

      @media (max-width: 767px) {
        margin-left: 5px;
      }
    }
  }

  .load-more {
    background-color: #000;
    color: #fff;
    text-transform: uppercase;
  }
  
  .js-blog-banner {
    display: none;
  }
}

@import './variables';

.address {
  &__box {
    height: $pz-address-box-height;

    &:hover {
      border-color: $pz-address-box-border-color-hover;
    }

    &.checked {
      border: 2px solid #000000 !important;
    }
  }

  &__aciton--link {
    cursor: pointer;
    margin-right: 30px;

    &:hover {
      text-decoration: underline;
    }
  }
}
.header {
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #ffffff;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05);
  @media only screen and (max-width: 768px) {
    padding-left: 10px;
    padding-right: 10px;
  }

  &__logo-box {
    a {
      color: #000000;
      
      @media only screen and (min-width: 767px) {
        width: 100px;
        display: inline-block;
      }

      i {
        font-size: 100px;

        @media only screen and (max-width: 1024px) {
          font-size: 45px;
        }
      }
    }
    @media only screen and (max-width: 1024px) {
      padding-right: 10px;
      padding-left: 40px;
    }
    img{
      @media only screen and (max-width: 1024px) {
        width: 45px;
      }
    }
  }

  &__account {
    margin-top: 57px;
    @media only screen and (max-width: 767px) {
      margin-top: 0;
    }
  }

  &__lang-box {
    a {
      color: #000000;
      font-size: 14px;
      text-decoration:underline !important;
    }
  }

  @media only screen and (max-width: 767px) {
    padding-top: 15px;
    position: relative;
  }

  .menu__button{
    position: absolute;
    top: 25px;
    display: inline-block;
    
    @media only screen and (min-width: 1025px) {
      display: none;
    }

    i{
      font-size: 18px;
    }
  }
}
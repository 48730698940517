.navigation__box {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  max-width: 1440px;
  margin: auto;
  margin-bottom: 15px;

  @media only screen and (max-width: 1024px) {
    display: none !important;
  }
}

.navigation__menu__item {
  padding: 5px 0px;
  
  .navigation__menu__main {
    a {
      font-family: 'Plain Thin';
      color: #0d2144;
      font-size: 18px;
      transition: all .3s ease;
      padding-bottom: 5px;
      &:after{
        content: "";
        left:0;
        bottom: 0;
        width: 0;
        height: 3px;
        position: absolute;
        background-color: #000000;
        transition: all .3s ease;
      }
    }
  }

  .navigation__submenu {
    display: flex;
    &__link {
      color: #535353;
      font-family: 'Plain UltraThin';
    }
  }
  a {
    font-size: 15px;
    position: relative;
    align-items: center;
  }
  .red {
    color: #d72b01 !important;
  }
}



.navigation__menu__item{
  &:hover {
    .navigation__menu__main{
      a {
        font-weight: bold;
        &::after {
          width: 100%;
        }
      }
    }
    .navigation__submenu {
      opacity: 1;
      display: flex;
      visibility: visible;
      transition: all .3s ease;
      z-index: 100;

      .navigation__link__wrapper{
        display: flex;
        flex-wrap: wrap;
        align-self: flex-start;
      }

      &__link{
        display: flex;
        flex: 1 1 100%;
        &:hover{
          span {
            border-bottom: 3px solid #000000;
          }
        }
      }
      .two-column {
        flex: 1 1 50%;
      }
    }
  }
}

.navigation__submenu {
  z-index: 10;
  opacity: 0;
  width: 100%;
  padding: 40px;
  margin-top: 5px;
  position: absolute;
  left: 0;
  right: 0;
  visibility: hidden;
  line-height: 2.14;
  min-width:1440px;
  background-color: rgba(255,255,255,.95);
  border: 1px solid #eee;
  border-top: none;

  @media only screen and (max-width: 1440px) {
    min-width: 100%
  }

  .navigation__action__link{
    font-size: 19px;
    font-family: 'Plain Thin';
    color: #0d2144;
    text-decoration:underline !important;
    i{
      bottom: 3px;
      right: -20px;
      font-size: 12px;
      font-weight: bold;
      position: absolute;
      &:last-child{
        right: -24px;
      }
    }
  }
}

ul > li[data-bundle="yes"] {
  padding-right: 20px;
  max-width: 180px;
  margin-right: 20px;

  h6 {
    display: none;
  }

  a {
    padding-right: 15%;
    
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10%;
    width: 1px;
    background: #eee;
    height: 50%;
  }
}

.navigation__megamenu--links {
> ul {
  display: flex;
  margin: 0;
  padding: 0;
  flex-flow: column wrap;
  height: 500px;
  width: 50%;

  > li {
    position: relative;
    width: 16vw;
    max-width: 215px;
    min-width: 150px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: -15px;
      width: 1px;
      background: #ccc;
      height: 100%;
    }

    span.text-change{
      display: block !important;      
      font-family: "Plain Light";
      height: 22px;
      
      >a {
        color: #000;
        padding-bottom: 3px;
        font-size: 13px;
        color: #4d4d4d;
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        line-height: 1.7;
        height: 22px;
      }
    }

    .link-title {
      font-size: 14px;
      font-weight: 700;
      color: #000;
      text-transform: uppercase;

      >a {
        color: #000;
        padding-bottom: 3px;
        &:after{
          content: "";
          left:0;
          bottom: 0;
          width: 0;
          height: 1px;
          position: absolute;
          background-color: #000000;
          transition: all .3s ease;
        }

        &:hover {
          color: #333;
          &::after {
            width: 100%;
          }
        }
      }
    }

    > a {
      font-size: 13px;
      color: #4d4d4d;
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      line-height: 1.7;



      &:hover {
        color: #000;
      }

      &:last-child {
        padding-bottom: 25px;
        margin-bottom: 0;
      }
    }
  }
}

ul > li[data-bundle="yes"] {
  padding-right: 20px;
  max-width: 180px;
  margin-right: 20px;

  h6 {
    display: none;
  }

  a {
    padding-right: 15%;
    font-size: 18px;
    font-weight: bold;

    &:last-child {
      margin-top: 50px;
      text-decoration: underline !important;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10%;
    width: 1px;
    background: #000;
    height: 100%;
  }
}
}

.mega-menu-main{
  width: 20%;
  a {
    padding-right: 15%;
    font-size: 15px;
    font-weight: bold;
    display: block;
    color: #000;
    transition: all .3s ease;

    &:hover {
      opacity: .6;
    }

    &:last-child {
      margin-top: 30px;
      text-decoration: underline !important;
    }
  }
}

.normal-menu {
  width: 84%;
}

.small-menu {
  width: 34%;
}

.big-menu {
  width: 60%
}

.break {
  flex-basis: 100%;
  width: 0;
  border: 0.5px solid #eee;
  margin: 0;
  content: "";
  padding: 0;
}

.navigation__megamenu--images {
  img {
    width: 100%;
  }
}
.order-detail{
  font-weight: bold;
}

.order-detail-count{
  color: #666;
}

.order-detail-title {
  font-family: 'Financier Regular';
  font-size: 20px;
  line-height: 1;
  color: #666;

  span{
    color: #c2000b;
  }
}

.order-detail-shipping {
  color: #666;
  font-size: 16px;
  margin-bottom: 10px;
  border-bottom: 1px solid #1a1a1a;
  padding-bottom: 20px;

  @media (max-width: 991px){ 
    margin-bottom: 10px;
  }
}

.order-detail-wrapper {
  width: 100%;
  max-width: 684px; 
}

.order-detail-list-head {
  padding: 12px 0;
  border-bottom: solid 1px #1a1a1a;

  @media (max-width: 768px){ 
    flex-direction: column;
  }

  &__status{
    width: 68%;
    &.cancelled-or-returned{
      width: 55%;
      .order-detail-list-head__box{
        padding-left: 8px;
        .status-box{
          width: 80px;
        }
      }
    }
    &, &.cancelled-or-returned{
      @media (max-width: 768px){ 
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }

  &__box{
    @media (max-width: 768px){ 
      font-size: 14px;
    }

    .status-box{
      width: 85px;
    }

    .box-color{
      color: #c2000b;
    }
  }

  form {
    width: 100%;
  }

  .order-detail-select-wrapper {
    width: calc(100% - 122px);
  }

  button {
    height: 36px;
    width: 122px;
    line-height: 34px;
    font-size: 14px;

    @media (max-width: 991px){ 
      width: 141px;
    }
  }

  .select2-selection--single {
    height: 36px !important;
  }

  .select2-selection__rendered, .select2-selection__placeholder {
    line-height: 34px !important;
  }
}

.order-detail-list-head-label {
  font-size: 14px;

  &:last-child {
    color: #666666;
  }
}

.oder-detail-product-item {
  padding: 10px 0;
  

}

.order-detail-product-info {
  padding-left: 15px;

  @media (max-width: 991px){
    float: left;
    width: calc(100% - 94px);
    margin-bottom: 20px;
  }
}

.order-detail-product-amount {
  @media (max-width: 991px){
    float: left;
    width: calc(100% - 94px) !important;
    padding-left: 15px;
  }
}

.order-detail-image-wrapper {
  width: 94px;
  height: 143px;

  @media (max-width: 991px){
    float: left;
  }
}

.order-detail-product-name {
  margin-bottom: 5px;
  font-size: 14px;
  color:#212529;
}

.order-detail-product-attributes {
  color: #818181;
  font-size: 14px;
}

.order-detail-price {
  font-family: 'Financier Bold';

  @media (max-width: 991px){
    display: inline-block !important;
    margin-right: 10px;
  }
}

.order-detail-retail-price {
  color: #818181;
  text-decoration: line-through;
  font-size: 14px;

  @media (max-width: 991px){
    display: inline-block !important;
  }
}

.order-detail-product-quantity {
  color: #666666;
  font-size: 14px;
}

.order-detail-summary {
  padding: 22px 0;
  border-bottom: solid 1px #dfdfdf;
  margin-bottom: 15px;
  border-top: solid 1px #dfdfdf;
}

.order-detail-summary-item {
  font-size: 14px;

  &:first-child {
    font-family: 'Plain Bold';
  }
}

.order-detail-summary-total {
  .order-detail-summary-item:last-child {
    font-size: 26px;
  }

  .payment-info{
    color: #c2000b;
    font-weight: bold;
    margin-bottom: 10px;
  }
}

.order-detail-list-head-labels {
  @media (max-width: 991px){ 
    width: 100% !important;
    margin-bottom: 20px;
  }
}


.order-detail-list-action-items {
  width: 30%;

  @media (max-width: 991px){ 
    width: 100% !important;
    padding-left: 0;
  }

  button{
    outline: none;
    border: solid 2px #c2000b;
    border-radius: 3px;
    background: #fff;
    margin-bottom: 10px;
    color: #c2000b;
    font-size: 17px;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
  }
  a{
    color: #c2000b;
  }

  a.disabled{
    pointer-events: none;
    color: #666;
  }

  .order-button{
    border-color: #666;
    color: #666;
  }
}

.order-detail-summary > div,
.order-detail-summary-total > div {
  @media (max-width: 991px){ 
    width: 100% !important;
  }
}

.order-detail-easy_return {
  &-wrapper {
    border: solid 1px #0030af;
    padding: 6px 10px;
    text-align: center;
    margin: 10px 0;
  }

  &-text {
    font-size: 11px;
  }

  &-code {
    font-size: 13px;
    color: #ff6e00;
  }

  &-image {
    width: 48px;
    display: block;
    margin: 0 auto;
  }
}

.shipping-info{
  display:block;
  color: #666;
  
  .shipping_info_w{
    font-family: 'Financier Regular';
    text-align: left;
    font-size: 20px;
    margin-bottom: 0;
    &-text{
      color: #666;
    }
  }
}
.account-order-detail__info-msg{
  margin-top: 10px;
}
.hr-line{
  margin-top: 10px;
}
// .shipping-area-container-full{
//   @media (max-width: 768px){ 
//     position: inherit;

//   }
//   @media (max-width: 1200px){ 
//     right: 50px;
//   }
//   position: absolute;
//   top: 50%;
//   right: 180px;
// }
.w-md-50{
  width: 50%;
  @media (max-width: 768px){ 
    width: 100%;
  }
}
.shipping-btn{
  background-color: #000;
  height: 36px;
  width: 100%;
  border: none;
  outline: none;
  a {
    color: #fff!important;
  }
  @media only screen and (min-width: 576px) and (max-width: 768px) {
    height: 52px;
  }
}
.shipping-area-container{
  margin: 20px 0;

  .shipping-number{
    font-size: 14px;
  }

  .show-invoice-btn {
    outline: none;
    border: none;
    background: #000;
    font-size: 16px;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    height: 36px;
    width: 100%!important;

 
    a{
      color: #fff;
    }
  
    a.disabled{
      pointer-events: none;
      color: #666;
    }
  }
  &-btn{
    display: flex;
    gap: 15px;
    width: 100%;
  }
}



.shipping-firm{
  max-width: 150px;
  margin: 10px 0;
  padding-right: 20px;
  img{
    width: 100%;
  }
  .shipping-img {
    height: 45px;
  }
}
.shipping-firm__wrapper{
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}
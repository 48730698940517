.basket-list {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column-reverse;

  &.dowry-products {
    flex-direction: column;
  }

  .dowry-box {
    border: solid 0.5px #fff;
    background-color: #f5f7fa;
    padding: 15px;
    color: #0d2144;
    line-height: 1.35;
    font-weight: 300;
    font-family: 'Plain Regular';
    font-size: 18px;
  }
  
  .products-box {
    background-color: #fff;
    color: #fff;
    display: inline;
    text-align: right;
    margin-top: 18px;
    font-weight: 300;
    font-size: 18px;

    &__title {
      background-color: #0d2144;
      display: inline-block;
      padding: 6px 27px;

      img {
        padding-right: 11px;
        margin-top: -3px;
      }

      a {
        color: #fff;
        font-size: 16px;
        font-weight: 700;
      }
    }
  }

  .items-all {
    border: solid 0.5px #fff;
    background-color: #f5f7fa;
    padding: 15px;
    color: #0d2144;
    line-height: 1.35;
    color: #0d2144;
    font-weight: 300;
    font-family: 'Plain Regular';
    margin-top: 40px;

    @media (max-width:768px){
      margin-top: 12px;
    }
  }

  .akifast-wrapper .pz-providers-quick-pay-button{
    display: none;
  }
  .akifast-wrapper .pz-providers-quick-pay-button:nth-child(2){
    display: block !important;
  }
}
.product-image-wrapper {
	padding-bottom: 149.567%;
  position: relative;
  overflow: hidden;

  &:hover {
    .fast-buy {
      display: flex;
    }
  }
	.product-item__img {
		position: absolute;
		top: 0;
		left: 0;
    transition: opacity 0.2s linear, transform 300ms ease-out;
      &:hover {
        transform: scale(1.05);
      }
	}

  .fast-buy {
    display: none;
    position: absolute;
    bottom: -1px;
    background-color: rgba(255, 255, 255, 0.86);
    padding: 9px 15px 28px 15px;
    width: 100%;
    flex-direction: column;
    align-items: center;
    cursor: default;

    @media (max-width:768px) {
      display: flex;
      padding: 9px 0;
      border: solid 0.2px #8b8b8b;

      &.-active {
        padding: 7px 0;
      }

    }

    &__text {
      font-size: 10px;
      font-weight: 600;
      line-height: 1.65;
      letter-spacing: 0.51px;
      text-align: center;
      color: #000;

      &.-mobile {
        display: none;
        background-color: transparent;
        border: none;
        width: 100%;

      }
      &.-desktop {
        display: block;
      }

      @media (max-width:768px) {
        &.-mobile {
          display: block;

          &.-hidden {
            display: none;
          }
        }
        &.-desktop {
          display: none;
        }
      }
    }

    &__sizes {
      display: flex;
      color: #000;
      flex-wrap: wrap;
      text-align: center;
      gap: 0.5rem;
      font-size: 14px;
      line-height: 1.59;
      letter-spacing: normal;
      text-align: center;
      margin-top: 4px;
      justify-content: center;

      @media (max-width:768px) {
          display: none;
          font-size: 12px;
          gap: 0;
          margin-top: 0;
          font-weight: 600;

          &.-active {
            display: flex;
          }
      }
    }

    &__size {
      cursor: pointer;
      padding: 2px 8px;

      &:hover {
        background: rgb(196, 195, 195);

      }
      &.-outoffstock {
        color: #acacac;
        pointer-events: none;
      }
    }
  }
}

.product-item-favourite-elem {
	position: relative !important;
  height: 44px;

  &:after {
  	font-family: 'icomoon';
  	content: "\e902";
  	color: #212529;

  }

  &.active:after {
  	content: '\e901';
  }
}

.product-item__price-border {
	border-left-style: solid;
  border-left-color: #000;
  border-left-width: 0.5px;
}

.product-item-offer-box {
	display: flex;
  flex-direction: column;
  align-items: center;
}

.product-item__price--retail {
	margin-right: 0 !important;
}

.product__retail__price {
  margin: 0 !important;
}

.product-item-campaign {
	font-size: 12px;
  color: #b32a2d;
  font-family: 'Plain Medium';
  padding-bottom: 1px;

  &.product-item-campaign-list {
    margin-left: 0;
  }

  &-price {
    font-family: 'Plain Bold' !important;
    font-size: 17px;
    margin-left: 10px;
	}
}

.product-item__name {
  font-size: 14px;
  font-family: "Plain Regular";
  width: 85%;
}

.product-item-price-wrapper-list {
  .product-item-campaign {
    margin-left: 0;
  }

  .product-item-campaign-price {
    margin-left: 5px;
    @media (max-width:768px) {
      font-size: 14px;
      margin-left: 0;
    }
  }
}
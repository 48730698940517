.gift {
  &-banner {
    p {
      bottom: 0;
      right: 0;
      padding-right: 10px;
    }
  }

  &-content{
    margin: 25px 0;
    text-align: center;
      &__title {
        font-weight: bold;
        margin-bottom: 5px;
        padding: 0 15px;
        font-size: 36px;
        @media (max-width:576px){
          font-size: 24px;
        }
      }

      &__subtitle {
        padding: 0 15px;
        font-size: 18px;
        @media (max-width:576px){
          font-size: 14px;
        }
      }
    
  }

  &-section {
    &__first {
      @media (max-width:576px){
        display: none;
      }
      .content {
        position: absolute;
        bottom: 10%;
        left: 10%;
        width: 80%;
        font-size:17px;
        text-align: center;
      }

      .title {
        font-weight: bold;
        text-transform: uppercase;
        width: 90%;
      }
    }

    &__first-sm {
      @media (min-width:576px ){
        display: none;
      }
      .content {
        position: absolute;
        top: 15%;
        left: 20%;
        width: 70%;
      }

      .title {
        font-weight: bold;
        text-transform: uppercase;
        width: 90%;
      }
    }

    &__second {
      @media (max-width:576px){
        display: none;
      }
      .content {
        position: absolute;
        bottom: 5%;
        padding-left: 20px;
        width: 80%;
        font-size:17px;
      }

      .title {
        font-weight: bold;
        text-transform: uppercase;
        width: 90%;
      }
    }

    &__second-sm {
      @media (min-width:576px ){
        display: none;
      }
      .content {
        position: absolute;
        top: 10%;
        padding-left: 20px;
        width: 80%;
        font-size:13px;
      }

      .title {
        font-weight: bold;
        text-transform: uppercase;
        width: 90%;
      }
    }

    &__third {
      @media (max-width:576px){
        display: none;
      }
      .content {
        position: absolute;
        top: 10%;
        padding-left: 20px;
        width: 80%;
        font-size:17px;
      }

      .title {
        font-weight: bold;
        text-transform: uppercase;
        width: 90%;
      }
    }
    &__third-sm {
      @media (min-width:576px ){
        display: none;
      }

      .content {
        position: absolute;
        top: 10%;
        padding-left: 20px;
        width: 80%;
        font-size:14px;
      }

      .title {
        font-weight: bold;
        text-transform: uppercase;
        width: 90%;
      }
    }


  }

  &-slider {
    .inovative {
      position: relative;
      @media (max-width:576px){
        display: none;
      }

      .content {
        position: absolute;
        top: 5%;
        padding-left: 20px;

        .title {
          font-weight: bold;
        }
      }
    }

    &__photos {      
      &-slide{
        overflow-x: hidden;
        display:flex;
        flex-wrap: nowrap;
        white-space: nowrap;
        min-width: 100%;

        .slider{
          display : flex;
          flex-shrink: 0;
          align-items: center;
          animation: slide-left 20s linear infinite;

          a{
            padding-left: 0.5em;
          }

          @keyframes slide-left {
            from {
              transform: translateX(0);
            }
            to {
              transform: translateX(-100%);
            }
          }
        }
      }
    }
  }

  &-footer{
    &__banner {
      position: absolute;
      top: 10%;
      left: 10%;
      text-align: center;
      width: 80%;

      &-title {
        font-weight: bold;
        margin-bottom: 10px;
      }
    }
  }
  a {
    color: #000;
  }
}
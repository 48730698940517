.campaign-page-wrapper {
    padding: 50px;
    @media (max-width: 1024px) {
        padding: 20px 0;
    }
}

.campaign-page {
    &__top-bar {
        padding: 5px 0;
        border: solid 1px #979797;
        margin-bottom: 34px;

        @media (max-width: 767px) {
            margin: 0 15px 15px;
        }

        h1 {
            font-size: 30px;
            text-align: center;

            @media (max-width: 767px) {
                font-size: 22px;
            }
        }
    }
}

.campaign-item {
    color: #072249;
    margin-bottom: 30px;
    &__content {
        padding-left: 20px;
        width: 50%;
        position: relative;

        @media (max-width: 767px) {
            width: 100%;
            margin-top: 30px;
            padding: 0 15px 30px;
        }
    }
    &__img {
        width: 50%;

        @media (max-width: 767px) {
            width: 100%;
        }

        img {
            width: 100%;
            height: 330px;
            @media (max-width: 767px) {
                height: auto;
            }
        }
    }
    &__title {
        font-size: 18px;
        color: #072249;
    }
    &__desc {
        margin-top: 20px;
        font-size: 14px;
        padding-top: 5px;
        @media (max-width: 767px) {
            font-size: 13px;
        }
        ul {
            color: #072249;
            list-style: disc;
            padding-left: 30px;

            li {
                padding-left: 15px;
                line-height: 20px;
            }
        }
    }

    &__button {
        position: absolute;
        bottom: 0;
        left: 20px;
        @media (max-width: 767px) {
            text-align: center;
            width: 100%;
            left: 0;
        }
        
        a {
            background-color: #050d2d;
            display: inline-block;
            color: #fff;
            padding: 5px 35px 7px;
            font-size: 14px;
            transition: all .3s ease;

            @media (max-width: 767px) {
                width: 80%;
                margin: 0 auto;
                text-align: center;
                font-size: 13px;
            }

            &:hover {
                opacity: .8;
            }
        }
    }
}

@import './variables';

.select {
  width: $select-width;
  height: 100%;
  border-radius: 0;
  border-color: #c9c9c9;
  padding-left: 12px;
  appearance: none;
  background-color: transparent;
  font-size: 14px;

  &-wrapper {
    position: relative;
    height: $select-height;

    &:before {
      position: absolute;
      font-family: 'icomoon';
      display: inline-block;
      content: "\e910";
      top: 50%;
      right: 16px;
      margin-top: -8px;
      line-height: 1;
    }

    .loader {
      width: 100%;
      display: none;
      justify-content: center;
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      left: 50%;
      width: $pz-spinner-size;
      height: $pz-spinner-size;
      margin-top: (($pz-spinner-size / 2) * -1);
      margin-left: (($pz-spinner-size / 2) * -1);
      border-radius: 50%;
      border: $pz-spinner-thickness solid $pz-spinner-color;
      border-top-color: transparent;
      animation: spinner $pz-spinner-speed linear infinite;
      z-index: 3;
    }

    &.loading *,
    &.loading::before {
      display: none;
    }

    &.loading .loader {
      display: flex;

    }
  }

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }
}

.checkout-district-error, .checkout-shipping-error {
  font-size: 12px;
  text-align: right;
  display: block;
  color: #cc5965;
}

.checkout-address-error {
  font-size: 12px;
  text-align: left;
  display: block;
  color: #cc5965;
  margin-top: 5px;
}
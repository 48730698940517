.collection__banners--wrapper {
  width: 100%;
  overflow: hidden;
}

#collection__banners {
  max-width: 100vw;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  @media screen and (min-width:786px) {
    padding-inline: 15px;
  }
}



body .collection--banners {
  display: flex;
  gap: 3px;

  &__item {
    position: relative;
    display: block;
    text-align: center;

    @media screen and (min-width:1300px) {
      min-height: 296px;
    }

    &:hover .collection--banners__item--content__title {
      color: #fcea10;
      text-shadow: none;
    }

    &::before,
    &::after {
      left: 0;
      z-index: 1;
      width: 100%;
      position: absolute;
      content: "";
      bottom: 0;
    }

    &::before {
      height: 30%;
      background-image: linear-gradient(to top, #ffffff 0 48%, #ffffff00);
    }

    &::after {
      z-index: 1;
      content: "";
      height: 66%;
      background-image: linear-gradient(to top, #999999, transparent);
    }

    img {
      height: 100%;
      width: 100%;
      transition: filter 1s;
      object-fit: cover;
    }

    &--content {
      position: absolute;
      left: 50%;
      width: 100%;
      z-index: 2;
      color: #fff;
      transform: translateX(-50%);
      padding-inline: 7px;
      bottom: 8px;

      @media screen and (min-width:768px) {
        bottom: 38px;
      }

      &__title {
        font-size: 10px;
        margin-bottom: 10px;

        @media screen and (min-width:768px) {
          font-size: 24px;
          margin-bottom: 18px;
        }

        font-weight: 700;
        font-family: 'Plain Bold';
        text-shadow: 0 0 16px rgba(0, 0, 0, .14);
        text-transform: uppercase;
        transition: transform 0.6s 0.1s;
        text-decoration: underline;
      }

      &__description {
        font-weight: 800;
        font-size: 6.5px;

        @media screen and (min-width:768px) {
          font-size: 12px;
        }

        letter-spacing: .3px;
        left: 0;
        right: 0;
        width: 100%;
        transition: opacity 0.6s 0.1s;
        list-style-type: none;
      }
    }
  }
}

.product {
  &-item {
    @media (max-width: 575px) {
      &:nth-child(odd) {
        padding-right: 5px;
        padding-left: 0px;
      }

      &:nth-child(even) {
        padding-left: 5px;
        padding-right: 0px;
      }
    }

    &__content {
      padding: 8px;
      flex: 1;

      @media (min-width: 1024px) {
        padding: 8px 0;
      }
    }

    &__name {
      font-size: 13px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-height: 22px;
      max-height: 44px;
      overflow: hidden;

      @media (min-width: 1024px) {
        font-size: 14px;
      }
    }

    &__category {
      font-size: 12px;
    }

    &__price {
      font-size: 14px;
      margin-top: -2px;

      &--retail {
        margin-right: 5px;
        color: #818181;
        text-decoration: line-through;
        font-size: 13px;
      }
    }

    &__badge {
      right: 0px;
      position: absolute;
      @media (max-width: 450px) {
        margin-bottom: 5px;
      }

      @media (max-width: 900px) {
        &:nth-child(n+5) {
          display: none !important;
        }
      }
      &.relative {
        position: relative;
      }
      &.lefttop {
        left: 0;
      }
      &.righttop {
        right: 0;
      }
      &.leftbottom {
        left: 13px;
        bottom: 13px;
      }
      &.rightbottom {
        right: 0;
        bottom: 13px;
      }
    }
  }
}

.product-item-price-wrapper {
  margin-bottom: 10px;

  @media (min-width: 1024px) {
    margin-bottom: 13px;
  }
}

.product-item__basket-offer-wrapper {
  color: #fff;
  border-radius: 50px;
  background-color: #f27a1a;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 28px;
  display: inline-flex;
  max-width: 100%;

  @media (min-width: 1024px) {
    align-items: center;
    justify-content: center;
  }
}

.product-item__basket-offers-label {
  font-size: 13px;
  padding: 5px 10px;

  @media (min-width: 1024px) {
    font-size: 16px;
  }
}

.badge-box {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  height: auto;
}

.list {
  &__filters-btn {
    width: 54px;
    height: 54px;
  }


  &__categories {
    white-space: nowrap;
    overflow-x: auto;

    @media (max-width: 575px) {
      width: 100vw;
    }

    a {
      font-size: 12px;

      &.selected {
        background-color: #efefef;
      }
    }
  }

  &__categories-desktop {
    white-space: normal;
    display: flex;
    scrollbar-width: thin;
    scrollbar-color: grey #fff;
    a {
      white-space: nowrap;
    }

  }

  &__description__text-wrapper {
    border-top: 1px solid #dfdfdf;
    padding: 22px 15px;

    .list__description__text {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      height: 75px;

      &.show {
        display: block;
        height: unset;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }

    .list__description__text-special {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      height: 93px;

      &.show {
        display: block;
        height: unset;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }

    .read-less {
      align-items: center;
      color: #afafaf;
      font-weight: 700;
      display: none;

      &.show {
        display: inline-block;
      }
    }

    .read-more {
      align-items: center;
      color: #afafaf;
      font-weight: 700;

      &.show {
        display: none;
      }
    }
  }

  &__facets--mobile {
    height: calc(100% - 97px);
    overflow: auto;
  }

  &__border {
    border: 1px solid #c9c9c9;
  }

  &__filters {
    .list__filters-choice-wrapper {
      margin-bottom: 29px;

      .category-label {
        display: flex;
      }
    }

    &-checkbox {
      display: inline-block;
    }

    .color-secondary {
      margin-bottom: 13px;
    }

    .choice-label {
      padding-left: 20px;
      line-height: 1.4;
    }

    &--arrow {
      &:after {
        content: "\e910";
        display: inline-block;
        font-family: 'icomoon';
        font-size: 10px;
        margin-left: 8px;
      }
    }


    .dropdown.show {
      .nav-link {
        color: $pz-color-primary-dark !important;
      }

      .list__filters--arrow:after {
        content: "\e911";
      }
    }

    .nav-link {
      padding-top: 12px;
      padding-bottom: 12px;
    }

    &[data-type="selectboxes"] {
      .nav-link:first-child {
        padding-left: 0;
      }
    }

    &-menu {
      box-shadow: 0 2px 26px 0 rgba(0, 0, 0, 0.06);
      border: 0px;
      min-width: 290px;
      padding: 28px 49px 39px 37px;
      border-radius: 0px;
      text-align: center;

      button {
        width: 100%;
        line-height: 44px;
        padding: 0 20px;
      }

      &--bigger {
        div {
          display: grid;
          grid-template-columns: auto auto;
          grid-gap: 5px;
          margin-bottom: 10px;

          label {
            padding: 5px;
            margin-bottom: 0px;
            min-width: 100px;
          }
        }
      }
    }
  }
}

.list__sorter--wrapper {
  .dropdown-menu.show {
    margin-top: 12px;
  }

  &>div {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    line-height: 1 !important;
    margin-bottom: 0 !important;
    align-self: center !important;
  }
}

.list__grid__icon {
  margin-right: 10px;
  color: #000 !important;

  &:last-child {
    margin-right: 0;
  }
}


.list__facets--mobile {
  @media (max-width: 992px) {

    &__subfilter {
      display: none;
    }

    &__filter--title {
      padding: 0px 15px 0px 30px;
      border-top: 1px solid #d0d0d0;
    }

    .last-item {
      border-bottom: 1px solid #d0d0d0;
    }

    div[data-key=integration_beden1] {
      flex-wrap: wrap;
      max-width: 349px;
      border-top: solid 1px #c9c9c9;
      border-left: solid 1px #c9c9c9;

      label {
        width: calc(100% / 6);
        height: 44px;
        margin-bottom: 0;
        max-width: 58px;

        input {
          display: none;

          &+span {
            display: inline-block;
            width: 100%;
            line-height: 44px;
            text-align: center;
            border-right: solid 1px #c9c9c9;
            border-bottom: solid 1px #c9c9c9;
            padding-left: 0 !important;
          }

          &:checked+span {
            background-color: #000;
            color: #fff;
          }
        }
      }
    }
  }
}

.list-filters-clear-button {
  font-style: bold !important;
}

.list-filters-clear-button:hover {
  text-decoration: underline;
}

.full-screen__apply {
  display: none;
}

.full-screen__header-filter {
  display: none;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.list__facets--mobile {
  &-selected {
    color: #818181;

    .selected-box {
      flex-wrap: wrap;

      .selected-items {
        border: 1px solid #d0d0d0;

        .choice {
          color: #2c2c2c !important;
        }
      }
    }
  }
}

.m-open-sub-filters {

  #ListFacetsMobileCloser {
    display: none !important;
  }

  .mobile-facet-wrapper {
    display: none;
  }

  .list__facets--mobile-selected {
    display: none !important;
  }

  .active {
    display: block !important;

    .list__facets--mobile {

      &__filter--title {
        display: none !important;
      }

      &__subfilter {
        display: block;
        input{
          &:before , &:after {
            font-size: 15px;
          }
        }
        .category-label {
          display: flex;
        }
      }
    }

    div[data-key=integration_beden1] {
      display: flex !important;
    }
  }

  .close-btn {
    display: block !important;
  }

  .full-screen__apply {
    display: block !important;
    position: absolute;
    width: 100%;
    bottom: 0;
  }

  .back-botton {
    display: block !important;
  }

}

.clear-filter-mobile {
  color: #818181;
}

.back-botton {
  display: none;
}

.list__empty__product {
  padding: 100px 0;
}

.product__list--row {
  flex-basis: 100%;
}

.list_banner__image__wrapper {
  position: relative;

  img {
    &+.list__banner__content .list__banner__title {
      font-size: 36px;
    }
  }

  .list__banner__content {
    top: 147px;
    left: 25px;

    @media (max-width: 992px) {
      width: 100%;
      left: 0;
      text-align: center;
    }


    .list__categories {
      width: 100%;

      a.selected {
        background-color: transparent;
      }
    }
  }
}

.icon-uclu {
  &.active:before {
    content: "\e935";
  }
}

.icon-lu {
  &.active:before {
    content: "\e934";
  }
}

.sorter-mobile-label {
  padding-left: 15px;
}

.scroll-top-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 20px;
  right: 30px;
  font-size: 18px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  background-color: #000;
  color: white;
  transition: opacity 0.5s linear;

  &.invisible {
    opacity: 0;
  }

  .icon-arrow_left {
    transform: rotate(90deg);
  }
}

.infinite-pagination {
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;

  &-body {
    width: 100%;
    margin: 10px 0;
  }

  &-prev {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    button {
      background: none;
      border: solid 1px #dedede;
      border-radius: 4px;
      padding: 10px;
      width: 100%;

      &:hover {
        border-color: #999;
      }
    }

    &.loading {
      button {
        display: none;
      }
    }
  }

  &-loader {
    display: none;
    border: 8px solid #f3f3f3;
    border-top: 8px solid #130f49;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
  }

  &.loading,
  &-prev.loading {
    .infinite-pagination-loader {
      display: block;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width:768px) {
  .mobile-facets-group{
    margin-top: 14px;
  }
}
.appointment-page{
  overflow-x: hidden;
}

.appointment-form {
  position: fixed;
  left: 0;
  right: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  height: 620px;
  padding: 25px 20px;
  margin: 0 auto;
  z-index: 12;
  display: none;
  border: 2px solid #050d2d;


  input[type="checkbox"]:checked:after {
    background-color: #050d2d;
  }

  &.show {
    display: block;
  }

  .form-success-message {
    display: none;
    text-align: center;
  }

  .exit-icon {
    position: absolute;
    right: 18px;
    top: 30px;
    cursor: pointer;
  }

  &__title {
    text-align: center;
    margin-bottom: 30px;
    font-weight: bold;
    color: #050d2d;
    font-size: 24px;
  }

  label {
    font-size: 13px;
  }

  .appointment-content {
    .input-wrapper {
      flex: 0 0 49%;
      
      &:nth-child(odd) {
        margin-right: 2%;
      }

      .input{
        border: none;
        border-bottom: 1px solid #000;
        padding: 0;
        width: 100%;
        outline: none;
      }

      .text-area {
        height: 35px !important;
        border: none;
        border-bottom: 1px solid #000;
        overflow-y: hidden;
        padding: 10px 0 !important;
        line-height: 1.2;
        width: 100%;
        outline: none;
    
        &.error {
          border-bottom: 1px solid #d72b01;
        }
      }
    }

    .input-wrapper-date {
      flex: 0 0 48%;
      margin-right: 1%;
    }
  }

  .select-wrapper-flatpage {
    margin-left: 25px;
  }
  .input-wrapper {
    display: flex;

    .select2-container--default  {
      .select2-selection--single {
        border: 1px solid #916f5d;
      }
    }


    .form-icon{
      width: 15px;
      margin-right: 10px;
    }

    .input {
      &.error {
        border-bottom: 1px solid #d72b01;
      }
    }
    
    input, select {
      height: 35px !important;
    }
  }

  .checkbox-wrapper {
    font-family: "Financier Light";
    .checkbox-aggrement {
      margin-left: 10px;
      font-size: 14px;
      margin-bottom: 5px;
    }
  }

  a {
    color: $pz-color-primary-dark;
    font-weight: bold;
  }

  .button-wrapper{
    text-align: center;

    .button {
      background-color: #916f5d;
      font-weight: bold;
      font-size: 21px;
      height: 35px;
      line-height: 1.2px;
      width: 300px;
      padding: 0 10px;
    }
  }

  .required-error{
    display: none;
    &.error {
      font-size: 14px;
      color: #9d9d9d;
      display: block;
    }
  }
}

.black-background {
  background: rgba(0, 0, 0, 0.5);
}

.appointment-container {
  
  &-row {
    margin-bottom: 80px;
  }

  &-text {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 25px 0;

    &__title {
      color: #050d2d;
      font-weight: 500;
      font-size: 60px;
      font-family: "Financier Medium";
      line-height: 1;
    }
    &__subtitle {
      font-weight: 300;
      font-size: 51px;
      color: #a07d2e;
      font-family: 'Financier Regular';
      line-height: 1.3;
    }

    &__content {
      font-size: 14px;
      flex-grow: 1;
      padding-top: 10px;
    }

    &__button {
      display: flex;
      align-items: center;

      a:hover {
        color: #fff;
      }

      &-line{
        height: 2px;
        background-color: black;
        margin-right: 15px;
        flex: 1;
      }

      &-box {
        padding: 5px 50px;
        background-color: #050d2d;
        color: #fff;
        border: none;
        text-transform: uppercase;
        font-size: 18px;
      }
      &-box:focus {
        outline: none;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .appointment-form {
    top: 0;
    transform: unset;
    height: 100vh;
    overflow-y: scroll;
    left: 0;

    .input-wrapper{
      .appointment-shop + .select2-container{
        width: 93% !important;
      }
    }
  }

  .appointment-container {
    &-row {
      margin-bottom: 40px;
    }

    &-img {
      margin-bottom: 10px;
    }

    &-text {
      padding: 0 15px;

      &__title {
        font-size: 40px;
        margin-bottom: 0;
      }
      &__subtitle {
        font-size: 35px;
      }
      &__content {
        margin: 10px 0;
      }
      
      &__button{
        &-box {
          padding: 5px 25px;
          font-size: 16px;
        }
      }
    }
  }
}
.home-look-by {
  min-height: auto;
  margin: 5rem 0;
  position: relative;

  @include media-breakpoint-down(md) {
    margin: 3rem 0 0 0;
  }
  &-showroom {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    height: auto;
    @include media-breakpoint-up(md) {
      display: none;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .home-look-slider {
    display: flex;
    align-items: center;

    @include media-breakpoint-up(md) {
      &:hover {
        img {
          opacity: 0.6;

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    &-item {
      max-width: 250px;
      height: 700px;
      position: relative;

      img {
        position: absolute;
        top: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }

       @include media-breakpoint-down(md) {
        max-width: 48px;
        height: 170px;

        img {
          height: 95px;
          margin-top: 20px;
        }

        &.active-slide {
          max-width: 70px !important;

          img {
            height: 135px;
            margin-top: 0;

          }
        }
       }
    }
  }

  .slider-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    width: 100%;
    
    button {
      background-color: transparent;
      border: none;
      padding: 0;

      i {
        font-size: 32px;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .slider-action {
      display: none;
    }

    .lSAction {
      a {
        background: none !important;

        i {
          color: #000;
          font-size: 32px;
        }
      }
    }
  }
}
.bank-account-no {
  font-size: 23px;
}

.company-name {
  font-size: 23px;
}

.funds-transfer-account {
  height: 23px;
}
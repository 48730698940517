@import './variables.scss';

.pz-breadcrumb {
  font-size: $pz-breadcrumb-font-size;
  padding: $pz-breadcrumb-padding;
  color: $pz-breadcrumb-link-color;

  &__link {
    color: $pz-breadcrumb-link-color;

    &:after {
      content: attr(data-divider);
      display: inline;
      padding: 0 8px;
      color: #dfdfdf;
    }

    &:hover {
      color: $pz-breadcrumb-active-color;
    }

    &:last-child {
      color: #9d9d9d !important;
      pointer-events: none;

      &:after {
        display: none;
      }
    }
  }

  &__text {
    color: $pz-breadcrumb-active-color;
  }
}

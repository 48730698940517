.coupon-list--head {
  margin-bottom: 50px;

  @media (max-width: 991px){
    margin-bottom: 25px;
  }
}

.coupon-list__item {
  margin-bottom: 75px;

  @media (max-width: 991px){
    margin-bottom: 25px;
    border-bottom: 1px solid #cfcfcf;
  }

  &__head-title {
    font-size: 14px;
    margin-bottom: 30px;

    @media (max-width: 991px){
      margin-bottom: 15px;
    }
  }

  &__sub-title-wrapper {
    border-bottom: solid 1px #dfdfdf;
    padding-bottom: 13px;
    margin-bottom: 13px;
  }

  &__sub-title {
    font-size: 12px;
    font-family: 'Plain Bold';
    margin-bottom: 0;
  }

  &--text-wrapper {
    @media (max-width: 991px){
      flex-direction: column;
      text-align: right;
    }
  }

  &--text {
    font-size: 12px;
    color: #666666;

    @media (max-width: 991px){
      width: 100% !important;
      position: relative;
      color: #000;

      &:before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        color: #666666;
      }
    }
  }

  &--empty {
    font-size: 12px;
    color: #666666;
  }
}
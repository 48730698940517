.new-season-lp {
	padding-bottom: 15px;
	img {
		width: 100%;
		height: auto;
	}

	.banner{
		padding: 0;
		margin: 0;
	}
	.description_text {
		margin-inline: auto;
		font-stretch: normal;
		text-align: center;
		color: #000;
		line-height: normal;
		letter-spacing: 0.78px;
		font-size: 9px;
    max-width: 330px;
    margin-block: 14px;
    font-weight: 400;
		padding-inline: 15px;
		h3 {
			font-size: 20px;
		}
		@media screen and (min-width:768px) {
			max-width: 301px;
			font-size: 20px;
			margin-block: 44px;
			max-width: 1085px;
			padding-inline: 0px;
			h3 {
				font-size: 1.75rem;
			}
		}
	}

	.image-with-product {
		display: grid;
		gap: 14px;
		grid-template-columns: repeat(2, 1fr);
		align-items: flex-start;
		padding-inline: 4px;
		@media screen and (min-width:768px) {
			padding-inline: 0;
			gap: 40px;
		}
		.banner{
			height: 100%;
			img{
				height: 100%;
				object-fit: cover;
			}
		}
		.js-product-wrapper{
			.product-item__content {
				max-width: 480px;
				width:100%;
				text-align: center;
				padding-left: 0;
				.no-variants,&.bottom{
					text-align: left;
					.justify-content-center{
						justify-content: flex-start !important;
					}
				}
			}
		}
		.js-product-wrapper{
			.fast-buy{
				bottom: 3px;
				@media screen and (min-width:768px) {
					bottom: 13px;
				}
			}
		}
	}

	.image-with-products {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		align-items: flex-start;
		margin-bottom: 40px;
		padding-left: 0px;
		padding-right: 15px;
		gap: 24px;
		@media screen and (min-width:310px) {
			gap: 7px;
		}		
		@media screen and (min-width:375px) {
			gap: 24px;
		}
		@media screen and (min-width:768px) {
			gap: 40px;
			padding-inline: 15px;
			margin-bottom: 100px;
		}
		&__banner{
			position: relative;
		}
		.products-fourth-list {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 10px 5px;
			@media screen and (min-width:375px) {
				gap: 10px 18px;
			}
			@media screen and (min-width:768px) {
				gap: 60px;
			}
		}
		.js-product-wrapper{
			.product-item-price-wrapper-list{
				.product-item-campaign{
					span{
						font-size: 7px;
						@media screen and (min-width:768px) {
							font-size: 16px;
							font-family: 'Plain Regular';
						}
					}
					
				}
			}
			.product-item-favourite-elem{
				display: none;
			}
			.product-item__content{
				padding-top: 10px;
				padding-inline:0px;
				@media screen and (min-width:768px) {
					padding-inline:8px;
					padding-top: 14px;
				}
			}
			.fast-buy .fast-buy__sizes{
				border-bottom: 0;
			}
			.product-image-wrapper{
				.product-item__img{
					aspect-ratio: 2/3 auto;
				}
			}
		}
		.slider-actions{
			i{
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				color: #000;
				font-size: 16px;
				font-weight: bold;
				cursor: pointer;
				&.prev{
					left: -5px;
				}
				&.next{
					right: -5px;
				}
				@media screen and (min-width:768px) {
					font-size: 35px;
					&.prev{
						left: -20px;
					}
					&.next{
						right: -20px;
					}
				}
			}
		}
	}


	.product-list-wrapper {
		max-width: 1300px;
		margin-inline: auto;
		margin-bottom: 32px;
		position: relative;
		padding-inline: 24px;
		@media screen and (min-width:768px) {
			padding-inline: 45px;
			margin-bottom: 80px;
		}
		.product-list{
			height: auto !important;
		}

		.js-product-wrapper{
			.product-item-favourite-elem{
				display: none;
			}
			.fast-buy{
				.fast-buy__sizes{
					border-bottom: 0;
				}
			}
			.product-item__content{
				padding-top: 10px;
				padding-inline:0px;
				@media screen and (min-width:768px) {
					padding-inline:8px;
					padding-top: 14px;
					
				}
			}
			.product-image-wrapper{
				.product-item__img{
					aspect-ratio: 2/3 auto;
				}
			}
		}
		.slider-actions{
			i{
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				color: #000;
				font-size: 16px;
				font-weight: bold;
				cursor: pointer;
				&.prev{
					left: 12px;
				}
				&.next{
					right: 12px;
				}
				@media screen and (min-width:768px) {
					font-size: 35px;
					&.prev{
						left: 0px;
					}
					&.next{
						right: 0px;
					}
				}
			}
		}
	}

	.product-item-price-wrapper-list{
		& > .d-flex{
			@media screen and (min-width:768px) {
				margin-bottom: 6px !important;
			}
			gap: 5px;
		}

		.product-item-campaign{
			line-height: 0;
			span{
				font-size: 7px;
				@media screen and (min-width:768px) {
					font-size: 16px;
					font-family: 'Plain Regular';
				}
				&:not(:first-child){
					display: none;
				}
			}
		}
	}
	.single-collection {
		max-width: 214px;
		margin: auto;
		position: relative;
		@media screen and (min-width:768px) {
			max-width: 1024px;
		}
	}

	.single-product {
		max-width: 214px;
		margin: auto;
		margin-bottom: 32px;
		@media screen and (min-width:768px) {
			margin-bottom: 100px;
			max-width: 600px;
		};

		.js-product-wrapper{
			.product-item__content {
				max-width: 480px;
				width:100%;
				text-align: center;
				margin: auto;
				padding-left: 0;
				.no-variants,&.bottom{
					text-align: left;
					.justify-content-center{
						justify-content: flex-start !important;
						margin-left: 0 !important;
					}
				}
			}
			.fast-buy{
				bottom: 3px;
				@media screen and (min-width:768px) {
					bottom: 14px;
				}
			}
		}
	}

	.featured-slide-wrapper{
		padding-inline: 1.5rem;
		position: relative;
		@media screen and (min-width:768px) {
			padding-inline: 0rem;
		}

		.featured-slider {
			display: flex;
			width: 100%;
			margin-bottom: 35px;
			height: auto !important;
			@media screen and (min-width:768px) {
				gap: 40px;
				margin-bottom: 75px;
				margin-top: 10px;
			}
			
			.featured-slider-item {
				transform: scale(0.5);
				transition: transform 200ms linear;
			}

			.featured-slider-item.active {
				transform: scale(0.5);

				&+.featured-slider-item {
					transform: scale(0.7);

					&+.featured-slider-item {
						transform: scale(1);

						&+.featured-slider-item {
							transform: scale(0.7);

							&+.featured-slider-item {
								transform: scale(0.5);
							}
						}
					}
				}
			}

			img {
				aspect-ratio: 140/463 auto;
			}
		}
		.slider-actions{
			i{
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				color: #000;
				font-size: 16px;
				font-weight: bold;
				cursor: pointer;
				&.prev{
					left: 8px;
				}
				&.next{
					right: 8px;
				}
				@media screen and (min-width:768px) {
					font-size: 35px;
					&.prev{
						left: 0px;
					}
					&.next{
						right: 0px;
					}
				}
			}
		}
	}

	.two-products {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 15px;
		width: 100%;
		margin-bottom: 32px;
		padding-inline: 15px;
		@media screen and (min-width:768px) {
			margin-bottom: 100px;
			gap: 60px;
			padding-inline: 0;
		};
		.js-product-wrapper{
			.product-item__content {
				max-width: 480px;
				width:100%;
				text-align: center;
				.no-variants,&.bottom{
					text-align: left;
					.justify-content-center{
						justify-content: flex-start !important;
					}
				}
			}
			.fast-buy{
				bottom: 3px;
				@media screen and (min-width:768px) {
					bottom: 13px;
				}
			}
		}
	}

	.triple-products {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		gap: 12px;
		margin-bottom: 32px;
		max-width: 300px;
		margin-inline: auto;
		@media screen and (min-width:768px) {
			gap: 60px;
			margin-bottom: 100px;
			max-width: unset;
		};

		.triple-product.first {
			flex: 1;
			max-width: 748px;
			position: relative;
			.js-product-wrapper{
				.fast-buy{
					bottom: 3px;
					@media screen and (min-width:768px) {
						bottom: 13px;
					}
				}
			}
		}

		.triple-products-left {
			max-width: 81px;
			@media screen and (min-width:768px) {
				max-width: 290px;
			}
			.triple-product {
				max-width: 400px;
				&:not(:last-child){
					margin-bottom: 10px;
				}
				.fast-buy .fast-buy__sizes{
					border-bottom: 0;
				}
				.product-item-price-wrapper-list{
					.product-item-campaign{
						span{
							font-size: 7px;
							@media screen and (min-width:768px) {
								font-size: 16px;
								font-family: 'Plain Regular';
							}
						}
						
					}
				}
			}
		}
		.js-product-wrapper{
			.product-item-price-wrapper-list{
				.product-item-campaign{
					display: none;
					display: flex;
					column-gap: 5px;
					flex-wrap: wrap;
				}
			}
			.product-item-favourite-elem{
				display: none;
			}
			.product-item__content{
				padding-top: 6px;
				padding-inline:0px;
				@media screen and (min-width:768px) {
					padding-inline:8px;
					padding-top: 14px;
				}
			}
			.product-image-wrapper{
				.product-item__img{
					aspect-ratio: 2/3 auto;
				}
			}
		}
		.slider-actions{
			i{
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				color: #000;
				font-size: 16px;
				font-weight: bold;
				cursor: pointer;
				&.prev{
					left: -5px;
				}
				&.next{
					right: -5px;
				}
				@media screen and (min-width:768px) {
					font-size: 35px;
					&.prev{
						left: -20px;
					}
					&.next{
						right: -20px;
					}
				}
			}
		}
	}

	.featured-collections {
		display: flex;
		gap: 12px;
		justify-content: space-between;
		width: 100%;
		padding-inline: 15px;
		@media screen and (min-width:768px) {
			margin-block: 100px;
			gap: 60px;
			padding-inline: 0px;
		}

		.featured-collection-item {
			position: relative;

			img {
				aspect-ratio: 220 / 357 auto;
			}
		}
	}

	.video{
		iframe{
			width: 100%;
			max-height: 400px; 
			@media screen and (min-width:768px) {
				max-height: unset;
			}
		}
	}
	.white-button{
		position: absolute;
		background-color: #fff;
		padding: 3px 10px;
		line-height: normal;
		font-size: 7px;
		color: #000;
		font-weight: 600;
		bottom: 14px;
		left: 50%;
		transform: translateX(-50%);
		font-style: normal;
		white-space: nowrap;
		text-align: center;
		@media screen and (min-width:768px) {
			font-weight: 300;
			bottom: 26px;
			padding: 10px 25px;
			font-size: 16px;
		}	
		@media screen and (min-width:1024px) {
			bottom: 70px;
			padding: 10px 45px;
			font-size: 20px;
		}
	}

	.lSAction>a {
		opacity: 1;
		background-image: unset;

		i {
			font-size: 14px;
			font-weight: bold;
			color: #000;
			opacity: 1;
			@media screen and (min-width:768px) {
				font-size: 44px;
			}
		}
	}

	.js-product-wrapper{
		&:hover{
			.fast-buy{
				display: flex;
				.fast-buy__sizes{
					display: inline-flex;
				}
			}
		}
		.product-item__name{
			font-size: 7px;
			font-weight: 300;
			line-height: normal;
			margin-bottom: 0.3rem;
			width: 100%;
			@media screen and (min-width:768px) {
				font-size: 1rem;
			}
		}

		@mixin price{
			font-family: 'Plain Regular';
			font-weight: 400;
			line-height: normal;
			font-size: 7px;
			@media screen and (min-width:400px) {		
				font-size: 8px;
			}
			@media screen and (min-width:768px) {
				font-weight: 300;
				font-size: 1rem;
			}
		}

		.product-item__badge{
			display: none
		}

		.product-item__price{
			@include price;
			&.overline{
				text-decoration: line-through
			}
		}

		.discounted_price{
			@include price; 
			color:#b32a2d;
		}

		.product-item__content {
			padding-block: 2px;
			@media screen and (min-width:768px) {
				padding-block: 8px;
			}
		}
		.product-item-campaign{
			span {
				@include price;
			}
		}

		.fast-buy{
			border: 0;
			padding-block: 4px;
			display: none;
			@media screen and (min-width:768px) {
				padding-block-start:12px;
				padding-block-end: 6px;
				bottom: -2px;
			}
			.-mobile {
				display: none !important;
			}
			.fast-buy__text{
				margin-bottom: 0;
				font-size: 8px;
				font-weight: 200;
				line-height: normal;
				text-transform: capitalize;
				display: block;
				@media screen and (min-width:768px) {
					margin-bottom: 6px;
					font-size: 18px;
				}
			}
			.fast-buy__sizes{
				@media screen and (min-width:768px) {
					padding-bottom: 10px;
					margin-bottom: 8px;
				}
				.fast-buy__size{
					font-family: 'Plain Regular';
					font-size: 8px;
					font-weight: 500;
					padding-inline: 3px;
					@media screen and (min-width:768px) {
						padding-inline: 6px;
						font-size: 1rem;
					}
				}
			} 
		}
	}
	.content-position-bottom {
		.product-item__content{
			text-align: left !important;
		}
		.justify-content-center{
			justify-content: flex-start !important;
		}
		.fast-buy{
			.fast-buy__sizes{
				border-bottom: 0;
			}
		}
	}
}
.orders__head {
	margin-bottom: 30px;

	h2{
	  font-weight: 500;
	  font-stretch: normal;
	  font-style: normal;
	  line-height: 1.2;
	  letter-spacing: normal;
	  color: #000;
	}
  
	.account__content__description{
	  color: #666;
	}
  

	@media (max-width: 991px){ 
		margin-bottom: 16px;
	}
}

.order__list--head {
	padding: 8px 0;
  border-bottom: solid 1px #dfdfdf;

  @media (max-width: 991px){
  	display: none !important;
  }
}

.order__list-empty {
	font-size: 14px;

	@media (min-width: 991px){
		padding: 13px 15px;
	}
}

.order__list {
  width: 30%;
  text-align: left;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  position: relative;
  color: #000;

  &-amount{
    color: red !important;
    font-size: 23px;
  }

  @media (max-width: 991px){
    width: 100%;
    padding: 0;

    &:before {
      content: attr(data-label);
	  position: absolute;
      left: 0;
      color: #666666;
    }
  }

  &__details{
    font-size: 26px;
    margin-right: 40px;

    @media (max-width: 991px){
      margin-right: 5px;
    }


    &--link {
      color: #212529 !important;
      .icon{
	    font-size: 18px;
      color: #212529 !important;
      font-weight: bold;
      }
    }
  }
}



.order__list--head .orders__list__column {
	color: #666666;	
}

.order__list__item {
  background-color: #f2f2f2;
  margin: 25px 0;
  padding: 9.5px 0 20px;
  border: solid 0.5px #b3b3b3;

  .head{
	padding: 0 15px 10px 15px;
	border-bottom: solid 0.5px #b3b3b3;
  }
}

.order__list__product-image {
  width: 110px;
  height: 131px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.order__list__image-wrapper {
  padding: 20px 15px 0;

  &-easy_return {
    position: relative;

    .order-detail-easy_return-wrapper {
	  position: absolute;
	  bottom: 15px;
	  left: 5px;
	  margin: 0;
	  padding: 6px 0 3px 0;
	  width: calc(100% - 20px);
	  background-color: #fff;
	}

	.order-detail-easy_return-text {
	   font-size: 8px;
	}

  }

  .mobile-screen{
  	margin-left:auto;

	@media (max-width: 991px){
		margin-left: unset;
	}

  }

  .status-label{
    color: #c2000b;
    font-size: 18px;
  }
  .status{
    font-size: 18px;
  }
  .order-statu{
    color: #212529;
    font-weight: bold;
  }
}
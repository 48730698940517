@import './variables.scss';

@import '../node_modules/lightslider/dist/css/lightslider.min.css';
@import '../node_modules/lightgallery/src/sass/lightgallery.scss';

.product-slider {
  width: 100%;

  @include make-container-max-widths();

  @media (min-width: 992px) {
    max-width: $product-slider-width;
    flex-shrink: 0;
  }
}

.product-slider__wrapper {
  position: relative;
  
  @include media-breakpoint-up(sm) {
    .product-images {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      margin: 0;
    }
    .slider-product-images{
      flex: 0 0 50%;
      max-width: 50%;
      padding-top: 13px;
      padding-right: 14px;

      a:hover{
        cursor: zoom-in;
      }

      img {
        width: 100%;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0;
    padding-top: 10px;
  }
}

.product-images__wrapper {
  height: auto !important;
  padding-left: 0px;

  @media (min-width: 992px) {
    margin-left: 117px;
  }
}

.product-thumbnail__wrapper {
  padding-left: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.product-slider__image {
  @include img-fluid();
  width: 100%;
  cursor: zoom-in;

  @media (max-width: 567px) {
    margin-bottom: 15px;
  }
}

.badge-box-slider {
  position: absolute;
  z-index: 1;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    .righttop {
      right: 10px !important;
    }
    .rightbottom {
      bottom: 25px !important;
    }
  }
  .lefttop{
    left: 36px !important;
  }
  .leftbottom{
    left: 36px !important;
    bottom: 25px !important;
  }
}

.product-thumbnail__item {
  position: relative;
  &.video-thumbnail:before {
      content: '▶';
      height: 1rem;
      width: 1rem;
      color: white;
      position: absolute;
      left: 28%;
      top: 35%;
      background: black;
      padding: .9rem 1rem 1.35rem 1.25rem;
      border-radius: 50%;
      font-size: 1.3rem;
      display: flex;
      align-items: center;
      justify-content: center;
  }
  img {
    width: $product-thumbnail-width;
    height: $product-thumbnail-height;
    opacity: 0.3;
    margin-bottom: 17px;
  }

  &.active img {
    opacity: 1;
  }
}
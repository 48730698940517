@import "get-widget/index";
@import "black-friday-sale/index";
@import "application-download/index";
@import "gift-to-man/index";
@import "collection-page/index";
@import "collection-third/index";
@import "otp-login/index";
@import "dowry-set/index";
@import "ds-damat-yeni-sezon/index";

.flatpage {
  margin: 40px auto;
  position: relative;

  &__sidebar {
    padding-right: 70px;

    &:after{
      content: "";
      right: 0;
      top: -40px;
      width: 1px;
      height: 100%;
      position: absolute;
      background-color: #dfdfdf;
    }
  }

  &__content {
    padding-left: 40px;

    img {
      width: 100%;
    }

    @media only screen and (max-width: 768px) {
      padding-left: 0;
    }

    .contact-form-radio{
      margin-right: 20px;

      .input-wrapper{
        label{
          margin:0;

          input[type=radio]:before{
            content: '';
            border: 1px solid #333;
            width: 18px;
            height: 18px;
            border-radius: 100%;
            display: inline-block;
            vertical-align: top;
          }

          input[type=radio]:after{
            content: '';
            width: 8px;
            height: 8px;
            position: absolute;
            left: 5px;
            right: 0;
            top: 3px;
            margin: auto;
            border-radius: 100%;
          }

          input[type=radio]:checked:after{
            content: '';
            background: #333;
          }
        }
      }
    }
  }

  &__description{
    color: #c9c9c9;
    font-size: 16px;
    margin-top: 15px;
  }

  &__title {
    font-size: 24px;
    margin-bottom: 16px;
    line-height: normal;
  }

  &__menu {
    margin: 0;
    padding: 0;

    &-link {
      margin-top: 12px;

      a {
        color: #666666;
        font-size: 13px;
        font-weight: 300;
      }

      &.active {
        a {
          color: #000000;
          font-weight: 700;
          text-decoration: underline !important;
        }
      }
    }
  }



}
@import './variables.scss';

.product {
  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  
    @media (min-width: 992px) {
      flex-direction: row;
    }
  }

  &__content {
    @media (min-width: 992px) {
      margin-left: $product-content-margin-left; 
      position: relative; 
    }

    @media (max-width: 992px) {
      padding-left: 15px;
      padding-right: 15px;
    }

    &__fix{
      @media (min-width: 992px) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }
    }
    
  }

  &__brand {
    font-size: $product-brand-size;
    color: $product-brand-color;
  }

  &__title {
    font-size: $product-title-size;
    color: $product-title-color;
  }

  &__text {
    font-size: $product-text-size;
    color: $product-text-color;
  }

  &__price {
    font-size: $product-price-size;
    color: $product-price-color;

    &--currency {
      font-size: $product-price-currency-size;
    }

    &--retail {
      font-size: $product-retail-price-size;
      color: $product-retail-price-color;
      text-decoration: $product-retail-price-decoration;
    }
  }

  &__retail__price {
    color: $product-price-color;
  }

  &__favorite {
    font-size: $product-favorite-size;
    color: $product-favorite-color;
    cursor: pointer;

    &.active,
    &:hover {
      color: $product-favorite-color-active;
    }
  }
}
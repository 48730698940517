@import './variables';

.select2-wrapper {
	.select {
		width: $select-width;
	}
	
	.select2-selection--single {
		height: 52px !important;
		border-radius: 0 !important;
		border-color: #c9c9c9 !important;
	}
	
	.select2-selection__placeholder {
		line-height: 52px;
		color: #9d9d9d !important;
		font-size: 14px;
	}
	
	.select2-selection__rendered {
		padding-left: 12px !important;
		padding-right: 59px !important;
		color: #000 !important;
		line-height: 50px !important;
		font-size: 14px !important;
	}
	
	.select2-selection__arrow {
		width: 44px !important;
		height: 100% !important;
	
		&:before {
			position: absolute;
			font-family: 'icomoon';
			display: inline-block;
			content: "\e910";
			top: 50%;
			right: 16px;
			margin-top: -8px;
			line-height: 1;
		}
	
		b {
			display: none;
		}
	}
}
@import './variables';

.empty-basket {
  text-align: center;
  padding: 2rem;

  &__title {
    color: $empty-basket-title-color;
    font-size: 1.25rem;
    font-weight: bold;
  }

  &__content {
    color: $empty-basket-content-color;
    padding: 1rem 0;
  }

  &__action > a {
    color: $empty-basket-action-color;
    text-decoration: underline !important;
  }
}

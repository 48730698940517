.fancybox-toolbar div {
    display: inline-block;
    height: 44px;
    line-height: 42px;
    background: rgba(30,30,30,0.6);
    color: #ccc;
    font-size: 12px;
    font-weight: bold;
    min-width: 42px;
    text-align: center;
    white-space: nowrap;
  }
  .fancybox-button {
    height: 65px;
  }
  
  .fancybox-thumbs {
    top: auto;
    width: auto;
    bottom: 0;
    left: 0;
    right : 0;
    height: 95px;
    padding: 10px 10px 0 10px;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.3);
  }
  
  .fancybox-show-thumbs .fancybox-inner {
    right: 0;
    bottom: 95px;
    overflow: visible;
  }
  .fancybox-thumbs__list a::before {
    border: 4px solid #042147;
  }
  
.mobile {
  &__menu {
    top: 0;
    opacity: 0;
    left: -100%;
    width: 100%;
    z-index: 999;
    height: 100vh;
    position: fixed;
    overflow-y: scroll;
    visibility: hidden;
    background-color: #ffffff;
    .menu-logo{
      width: 45px;
    }
    &.active {
      left: 0;
      opacity: 1;
      visibility: visible;
      transition: all 0.2s linear;
    }

    &__head {
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: solid 1px #dfdfdf;

      i {
        font-size: 45px;
      }

      span {
        color: #818181;
        font-size: 14px;
      }
    }

    ul {
      padding: 10px 15px 0 15px;
    }

    &__item {
      padding: 15px;
      border-bottom: solid 1px #dfdfdf;
    }

    &__main {
      display: flex;
      align-items: center;
      justify-content: space-between;

      a {
        color: #21252a;
        font-size: 16px;
      }
    }

    &__end {
      height: 64px;
      width: 100%;
    }
  }

  &__submenu {
    top: 0;
    opacity: 0;
    left: -100%;
    width: 100%;
    z-index: 999;
    height: 100vh;
    position: fixed;
    overflow-y: scroll;
    background-color: #ffffff;

    &.active {
      left: 0;
      opacity: 1;
      visibility: visible;
      transition: all 0.2s linear;
    }

    &__item {
      padding: 10px 15px 124px 15px;

      .mega-menu-main {
        padding: 0;
        width: 100%;

        a {
          padding: 16px 5px;
          border-bottom: solid 1px #dfdfdf;
          display: block;
          font-weight: bold;

          &:last-child {
            margin-top: 0;
            text-decoration: none !important;
          }
        }
      }

      div {
        padding: 16px 5px;
        color: #666666;
        font-size: 14px;
        border-bottom: solid 1px #dfdfdf;
      }
    }

    &__head {
      display: flex;
      padding: 25px 15px;
      align-items: center;
      justify-content: space-between;
      border-bottom: solid 1px #dfdfdf;

      span {
        color: #818181;
        font-size: 14px;

        i {
          padding-right: 8px;
          vertical-align: middle;
        }
      }

      h4 {
        margin-bottom: 0;
      }

      .close__button {
        color: #818181;
        font-size: 14px;
      }
    }

    a {
      color: #666666;
      font-size: 14px;
    }
    .red {
      color: #d72b01 !important;
    }
  }
}
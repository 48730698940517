.footer {
  &.dowry-footer-active {
    margin-bottom: 200px;
  }
}

.dowry {
  &-breadcrumb {
    .item {
      color: #000;
      font-size: 12px;
    }
    
    .divider {
      color: #dfdfdf;
      font-size: 12px;
    }

    .page-name {
      color: #9d9d9d;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 30px auto;
    text-align: center;
    color: #0d2144;

    &__title {
      margin-bottom: 30px;
    }

    &__text {
      font-size: 18px;
      font-weight: 500;
    }

    &__warning {
      font-size: 18px;
    font-weight: 500;
    }

    &__fixed {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__banner {
      width: 100%;
      height: auto;
      margin-top: 10px;
    }
  }

  &-set {
    &-context {
      padding-left: 15px;

      &__title {
        font-size: 14px;
        color: #000;
      }
      &__length {
        font-size: 12px;
        color: #9d9d9d;
      }
    }
    .lSAction {

      .lSPrev, .lSNext {
        color: #000000;
        opacity: 1;
        background-image: none;
        background-color: #fff;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1.75rem;
        height: 1.75rem;
        top: 40%;
        &:after {
          font-family: 'icomoon' !important;
          speak: none;
          font-style: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          color: #000;
          font-weight: bold;
          font-size: 16px;
        }
      }
  
      .lSPrev {
        &:after {
          content: "\e913";
        }
      }
  
      .lSNext {
        &:after {
          content: "\e912";
        }
      }
    }
  }



  .desktop-view {
    display: block;
    .dowry-set-context__title {
      font-weight: bold;
      font-size: 16px;
    }
  }

  &-footer {
    display: flex;
    transform: translateY(0%);
    position: fixed;
    z-index: 99;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    padding: 10px 0;
    border-top: 1px solid #636363;
    transition: transform .3s;

    &.-hide {
      transform: translateY(176px);
    }

    &__observe {
      flex-grow: 1;
      display: flex;
      overflow-x: auto;
      margin-right: 10px;
      height: 155px;

      &-img {
        margin-right: .9688rem;
        margin-top: 5px;
        position: relative;

        .dory-item-img {
          width: 100px;
        }

        .dory-item-quantity {
          margin-right: 10px;

          &::before {
            content: attr(data-quantity);
            position: absolute;
            top: -5px;
            width: 26px;
            height: 26px;
            background-color: #0d2144;
            color: #fff;
            border-radius: 100%;
            line-height: 24px;
            font-size: 12px;
            font-weight: bold;
            text-align: center;
          }
        }

        .delete-product {
          position: absolute;
          cursor: pointer;
          top: -5px;
          right: 5px;
        }
      }
    }

    &__actions {
      position: relative;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 1rem;
      height: 100%;
      margin-right: 30px;

      .absolute {
        position: absolute;
        bottom: 190px;
        right: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 57px;


        .back-to-top {
          width: 67px;
          height: 67px;
          background-color: #e9ebee;
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 100%;
        }

        .whatsapp-button {
          a {
            cursor: pointer;
            color: #000;
            font-size: 18px;
            font-weight: bold;
            display: flex;
            align-items: center;
            gap: .9375rem;
          }
        }
      }

      .button {
        padding-right: 40px;
        padding-left: 40px;
        border-radius: 6px;
        font-weight: bold;
        white-space: nowrap;

        &-dark {
          border: 1px solid #000;
          background-color: #000;
          color: #fff;

          &-outline {
            background-color: #fff;
            border: 1px solid #000;
            color: #000;
          }
        }
      }

    }
  }
  
}

@include media-breakpoint-down(sm) {
  .dowry {
    &-content {
      width: 100%;
      margin: 15px 0;
      text-align: left;
      align-items: start;
      
      &__title {
        font-size: 26px;
        margin-bottom: 10px;
      }

      &__text {
        font-size: 16px;
      }

      &__warning {
        font-size: 16px;
      }

      &__banner {
        display: none;
      }
    }

    &-set {
      &-context {
        padding-left: 0;

        &__length {
          display: none;
        }
      }
    }

    &-footer {
      flex-direction: column;

      &.-show {
        .dowry-footer__actions {
          .absolute {
            .whatsapp-button {
              padding: 10px 28px 0px 16px !important;
            }
          }
        }
      }

      &__observe {
        width: 100%;
        margin-right: 24px !important;
      }

      &__actions {
        flex-direction: column;
        margin-right: 0;
        align-items: flex-end;

        &::before {
          top: 33% !important;
          left: -20px !important;
        }

        .button {
          padding-left: 0;
          padding-right: 0;
          height: 38px;
          width: 165px;
          font-size: .7656rem;
          line-height: 33px;
        }

        .absolute {
          bottom: 134px !important;

          .whatsapp-button {
            padding: 10px 28px 10px 16px !important;

            a {
              font-size: 14px;
            }
            
            img {
              width: 25px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .dowry {
    &-footer {

      &.-show {
        .dowry-footer__actions {
          .absolute {
            .whatsapp-button {
              padding: 10px 55px 0px 32px;
            }
          }
        }
      }

      &__observe {
        margin-right: 40px;
      }

      &__actions {
        flex-direction: column;
        &::before {
          content: '>';
          position: absolute;
          top: 50%;
          left: -30px;
          font-size: 18px;
          font-weight: bold;
        }

        .absolute {
            bottom: 145px;
            right: 0px;

          .whatsapp-button {
            background-color: #fff;
            padding: 10px 55px 10px 32px;
            border-top: 1px solid #636363;
            border-left: 1px solid #636363;
            border-right: 1px solid #636363;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;

            a {
              span {
                white-space: nowrap;
              }
            }
          }
          .back-to-top {
            display: none;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1024.5px) {
  .dowry {
    &-footer {
      &__actions {
        position: absolute;
        right: 0;
        &::before {
          display: none;
        }
      }
    }
  }
}
  
.order-tracking-form {
  border: 1px solid #f1f1f1;
  margin-bottom: 70px;
  padding: 15px 0;

  @media (max-width: 991px){
    padding: 15px;
    flex-direction: column;
    margin-bottom: 35px;
  }
}

.order-tracking-title {
  font-family: 'Financier Medium';
  font-size: 36px;
  display: inline-block;
  margin-right: 22px;
  margin-bottom: 0;

   @media (max-width: 991px){
    font-size: 24px;
  }
}

.order-tracking-button {
  display: inline-block;
  width: 228px;
}

.order-tracking-form-input {
  height: 52px;
  line-height: 50px;
  padding-left: 22px;
  font-size: 14px;
  border: solid 1px #c9c9c9;
  border-right: 0 none;
}
.pagination {
  margin-bottom: 62px;
}

.pagination__item {
  min-width: 32px;
  padding: 7px;
  line-height: 1;
  margin-right: 7px;
  margin-bottom: 10px;
  text-align: center;
  border: 1px solid #ececea;
  background-color: #f9f9f9;
  color: #8c8c8c;

  &:hover {
    color: #000000;
    border-color: #000000;
  }

  &.active {
    border-color: #000000;
    background-color: transparent;
    color: #000000;
  }
}

.pagination__arrow {
  background-color: transparent;
  border-color: #000000;
  color: #000;
}
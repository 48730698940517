.accordion-item {
  &__title {
    position: relative;
    color: #000;
    padding: 5px 0;
    font-family: "Plain Regular";

    .accordion-item__icon:after {
      content: '+';
      position: absolute;
      top: 50%;
      right: 10px;
      font-size: 1.5rem;
      margin-top: -1rem;
      line-height: 1;
      font-style: normal;
    }
  }

  &__content {
    display: none;

    &--box {
      font-family: "Plain Light";
    }
  }

  &.active {
    .accordion-item__content {
      display: block;
    }

    .accordion-item__icon:after {
      content: '-';
    }
  }
}

.stores {
  &__form {
    padding-bottom: 10px;
    margin: 40px 0 30px 0;
    border-bottom: 1px solid #eeeeee;

    .input-wrapper {
      margin-right: 15px;
      width: calc(33% - 5px);
    }

    .button-wrapper {
      width: calc(33% - 5px);
    }

    @media (max-width: 767px){
      .input-wrapper , .button-wrapper {
        width: 100%;
      }
    }
  }

  &-city-group-wrapper {
    padding-bottom: 15px;
    margin: 15px 0 15px 0;
    border-bottom: 1px solid #eeeeee;
  }

  &__list {
    height: 50vh;
    flex-basis: 0;
    flex-grow: 0.7;
    max-width: 100%;
    overflow-y: scroll;
    position: relative;
    padding-right: 10px;

    &__title {
      color: #000000;
      font-size: 16px;
      font-weight: 500;
    }

    &__item {
      display: none;

      p {
        color: #666666;
      }

      .address__info {
        font-size: 13px;
        line-height: 19px;
      }

      .tel__info {
        color: #666666;
        font-size: 20px;
        line-height: normal;
        font-family: 'Financier Regular';

        a {
          color: #666666;
          display: block;
        }
      }

      .time {
        &__title {
          color: #000000;
          font-size: 16px;
        }

        &__text {
          color: #666666;
          font-size: 13px;
          line-height: 19px;

          p{
            margin-bottom: 0;
          }
        }
      }
    }

    @media (max-width: 767px){
      flex-basis: initial;
      margin-bottom: 30px;
    }
  }

  &__title {
    color: #666666;
    font-size: 13px;
    cursor: pointer;
    line-height: 1.92;

    i {
      display: none;
      font-weight: bold;
      padding-right: 20px;
    }
  }

  &__map {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    height: 430px;

    @media (max-width: 767px){
      flex-basis: initial;
    }
  }


  .active {
    top: 0;
    width: 100%;
    height: 100vh;
    position: absolute;
    background-color: #ffffff;

    .stores {
      &__list {
        height: 100vh;
        flex-basis: 0;
        flex-grow: 0.7;
        max-width: 100%;
        overflow-y: scroll;

        &__item {
          display: block;
        }
      }

      &__title {
        color: #000000;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 20px;

        i {
          display: inline-block;
        }
      }
    }
  }
}
.search {
  &__box {
    cursor: pointer;

    @media only screen and (max-width: 768px) {
     width: auto;
    }

    i {
      font-size: 22px;
    }

    &.active {
      .search-form {
        input {
          opacity: 1;
          width: 420px;
          height: 50px;
          padding: 5px 5px 5px 55px;
          visibility: visible;
          transition: all 0.3s linear;
          color: #001937;

          @media only screen and (max-width: 768px) {
              width: 100%;
              border-radius:0;
          }
        }
        button{
          visibility: visible;
          margin-top: 10px;
          margin-left: 5px;
        }
      }

      .search__box__icon {
        display: none;
      }
    }

    .search-form {
      position: relative;

      input {
        opacity: 0;
        z-index: 10;
        border: none;
        outline: none;
        box-shadow: none;
        visibility: hidden;
        position: relative;
        background-color: transparent;
        border: 1px solid gray;

        @media only screen and (max-width: 768px) {
          border-top: none;
          border-left: none;
          border-right: none;
          border-bottom: 1px solid #000000;
        }
      }
    }

    button {
      top: 5px;
      z-index: 0;
      height:30px;
      left: 10px;
      cursor: auto;
      border: none;
      outline: none;
      color: #000000;
      box-shadow: none;
      position: absolute;
      visibility: hidden;
      background-color: transparent;
      @media only screen and (max-width: 768px) {
        left: 0;
      }
    }
  }
}

.autocomplete {
  &__dropdown {
    min-width: 500px;
  }

  &__img {
    width: 60px;
  }
}

.sitemap-header span {
	color: #333;
	font-size: 36px;
	letter-spacing: 3px;

	@media only screen and (max-width: 768px) {
		padding-left: 15px;
	}
}

.flatpage-sitemap {
	padding: 0 15px 30px 15px;

	.sitemap-list {
		display: flex;
		flex-flow: wrap;
	}

	.sitemap-row {
		width: 25%;

		a {
			display: block;
			color: black;
			font-size: 14px;
			line-height: 1.5rem;
		}

		.sitemap-level-0 {
			margin-bottom: 15px;
			font-weight: bold;
			font-size: 16px;
		}

		.sitemap-level-1 {
			font-weight: normal;

			&:hover {
				transition-duration: .25s;
				color: rgb(110, 110, 110);
			}
		}

		@media only screen and (max-width: 768px) {
			width: 50%;
			padding: 0 15px;
		}
	}

	@media only screen and (max-width: 768px) {
		padding-bottom: 30px;
	}
}
@import 'variables';

.basket__voucher {
  margin-top: 1rem;
  margin-bottom: 0;

  &__container {
    cursor: pointer;
  }

  &__title {
    background: $basket-voucher-button-bg-color;
    color: $basket-voucher-button-tx-color;
    border: $basket-voucher-button-border;
    padding: 1rem;
    text-align: left;
    margin-bottom: 0;

    & > i {
      padding: 0.25rem;
      margin-right: 12px;
      font-size: 17px;
    }
  }  

  &__input {
    outline: none;
    width: 100%;
    height: 54px;
    line-height: 50px;
    border: 1px solid #c9c9c9;
    padding: 0 16px;
    font-size: 14px;
  }

  &__form {
    display: none;
    margin: 0 auto;

    & input {
      padding: 0.5rem 0.3rem;
    }

    & button {
      border: 0;
      height: 54px;
      padding: 0 2rem;
      font-size: 0.9rem;
      color: $pz-color-text-primary-light;
      background: $pz-color-primary-dark;
    }
  }

  &__error {
    text-transform: capitalize;
    color: red;
    font-size: 14px;
  }
}


.order-cancellation-wrapper {
  max-width: 684px;

  .order-detail-title{
    font-family: 'Financier Regular';
    font-size: 24px;
    line-height: 1.2;
    color: #000;
    font-weight: bold;
  }

  .order-detail-shipping{
    border-bottom: 1px solid #1a1a1a;
    padding-bottom: 20px;
    div{
      font-size: 18px;
    }
    span{
      color: #c2000b;
    };
  }

  .order-detail-list-head__status{
    width: 100%;
    margin-bottom: 20px;
    &.cancelled-or-returned{
      width: 60%;
      .status-box{
        width: 72px;
      }
    }
  }
}

.order-cancellation-list-title {
  font-size: 22px;
  padding-bottom: 7px;
  border-bottom: solid 1px #1a1a1a;
  font-weight: bold;
}

.order-cancellation-action {
  margin-top: 18px;
  
  form {
    max-width: 100% !important;
  }

  .input-wrapper {
    margin-bottom: 0 !important;
  }
}

.order-cancellation-checkbox {
  width: 16px;

  @media (max-width: 991px){
    display: inline-block;
  }
}

.order-cancellation-button {
  width: 160px;
  height: 36px;
  line-height: 34px;
  font-size: 14px;

  @media (max-width: 991px){
    width: 100%;
  }
}

.order-cancellation-agreement {
  .contracts-link {
    font-size: 14px !important;
    color: #9d9d9d !important;
  }
}

.order-cancellation-product {
  padding: 10px 0;
  border-bottom: solid 1px #1a1a1a;
}


.order-cancellation-image-wrapper {
  margin-left: 18px;
}
  
.order-cancellation-select {
  width: 259px;

  @media (max-width: 991px){
    margin-top: 20px;
    width: 100%;
  }

  .select2-selection--single {
    height: 36px !important;
  }

  .select2-selection__rendered, 
  .select2-selection__placeholder {
    line-height: 34px !important;
  }
}

.order-cancellation-product-info {
  padding-left: 15px;
  width: calc(100% - 389px);

  @media (max-width: 991px){
    width: calc(100% - 140px);
  }
}

.order-cancellation-info {
  padding: 5% 0 5% 5%;
  border-bottom: solid 1px #1a1a1a;
  margin-bottom: 18px;
  
  input {
    border: solid 1px #c9c9c9;
    background-color: #ffffff;
    padding: 8px 5px 9px 16px;
  }

  p {
    color: #818181;
    font-family: Plain;
  }
  
  .info-box { 
    
    .form-row {
      margin-bottom: 8px;
      flex-wrap: nowrap;

      input {
        border: solid 1px #c9c9c9;
        background-color: #ffffff;
        padding: 8px 5px 9px 16px;
        
        @media (max-width: 991px){
          width: 100%;
        }
        
      }
      .input-iban {
        width: 100%;
      }
    }
  }
}

.order-refund {
  &-title {
    font-size: 24px;
    color: #000;
    font-weight: bold;
  }

  &-shipping{
    color: #666;
    font-size: 20px;

    span{
      color: #c2000b;
    }
  }

  &-logo {
    display: flex;
    margin: 40px 0;
    align-items: center;
  }

  &-product{
    font-size: 24px;
    font-weight: bold;
    color: #000;
  }

  &-company {
    font-size: 24px;
    color: #000;
    font-weight: bold;
    border-bottom: 1px solid #1a1a1a;

    div{
      border-bottom: 1px solid #1a1a1a;
      padding-bottom: 5px;
    }

    img {
      width: 143px;
      height: 27px;
      margin: 25px 0;
    }
  }

  &-code{
    font-size: 24px;
    color: #000;
    font-weight: bold;
    border-bottom: 1px solid #1a1a1a;
    padding-bottom: 25px;
    margin: 25px 0;

    span {
      color: #c2000b;
    }
  }

  &-desc{
    font-size: 24px;
    font-weight: normal;
    color: #666;
    margin: 40px 0;
    padding-bottom: 40px;
    border-bottom: 1px solid #1a1a1a;
  }

  button{
    float: right;
    font-size: 20px;
    font-weight: 500;
    background: #000;
    padding: 5px 10px;
    outline: none;

    a {
      color: #fff;
    }
  }
}
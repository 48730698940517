@import '../address';
@import '../profile';
@import '../menu';
@import '../coupons';
@import '../favourites';
@import '../orders';
@import '../contact';
@import '../order-tracking';
@import '../notification-settings';
@import '../orders-detail';
@import '../orders-cancellation';

.account {
	&__content {
		@media (min-width: 991px){
			padding-left: 75px;
		}
		
		@media (max-width: 991px){
			padding: 35px 15px;
		}
	}

	&__content__description {
		color: #c9c9c9;

		@media (max-width: 991px){
			font-size: 14px;
		}
	}
}

#addressWrapper {
	@media ( max-height: 736px ){
		padding-bottom: 330px;
	}
	@media ( min-height: 737px ){
		padding-bottom: 420px;
	}
}

.account__container {
	@media (min-width: 991px){
		padding-top: 32px;
		padding-bottom: 100px;
		@media ( min-height: 737px ){
			padding-bottom: 220px;
		}
	}

	.change-email-form {
		margin: 0 auto;;
	}

	h2 {
		line-height: 1;

		@media (max-width: 991px){
			font-size: 24px;
		}
	}
}


.account__index__box-wrapper {
	margin-bottom: 65px;

	@media (max-width: 991px){
		flex-direction: column;
		margin-bottom: 15px;
	}
}

.account__index__box {
	color: #818181;
	font-size: 13px;
	
	@media (min-width: 991px){
		width: 30%;
		margin-right: 5%;
		padding-right: 45px;
		border-right: solid 1px rgba(151, 151, 151, 0.21);
	}

	@media (max-width: 991px){
		margin-bottom: 25px;
		border-bottom: solid 1px rgba(151, 151, 151, 0.21);
	}

	a {
		color: #818181;
		text-decoration: underline !important;
	}
	
	&:last-child{
		margin-right: 0;
		border-right: 0 none;
		border-bottom: none;
	}
}

.account__index__box__title {
	font-family: 'Financier Medium';
	font-size: 24px;
	color: #000000;
	margin-bottom: 28px;

	@media (max-width: 991px){
		line-height: 1;
		margin-bottom: 10px;
	}

}

.account__index__sss {
	margin-bottom: 18px;
	border-bottom: 1px solid #f1f1f1;

	&-title {
		font-family: 'Financier Medium';
		font-size: 24px;
	}

	&-description, 
	.accordion-item {
		font-size: 13px;
		color: #818181;
	}
}

.account__index {
	@media (max-width: 991px){
		margin: 0;
	}

	.accordion-item__title {
		font-size: 13px;
		color: #818181;
	}

	.accordion-item__content {
		font-size: 13px;
	}
}





@import 'mini-basket-list';
@import 'mini-basket-list-item';

.mini-basket {
  cursor:pointer;

  .icon-area{
    position: relative;
    color: #000000;
    i {
      font-size: 24px;
      color: #9d9d9d;
      width: 24px;
      height: 24px;
      @media only screen and (max-width: 767px){
        color: #000000;
      }
    }
    .mini-basket-total-quantity {
      left:0;
      top: 7px;
      width:100%;
      font-size:13px;
      text-align: center;
      position: absolute;
      font-family: "Plain Regular";
    }

    &__box {
      display: flex;
      flex-direction: column;
      align-items: center;

      .icon-cart-text {
        font-size: 12px;
        color: #000000;
        font-family: 'Plain Regular' !important;
        line-height: 1.6;
          @media only screen and (max-width: 768px){
            display: none !important;
          }
      }
    }
  }

  &__header {
    min-height: 55px;
    border-bottom: 1px solid $pz-color-text-disabled-dark;

    h4 {
      margin-left: 70px;
    }
  }

  &__close-button {
    color: $pz-color-text-secondary-dark;
    font-size: 0.9rem;
    cursor: pointer;
  }

  &__full-screen-container {
    position: fixed;
    display: none;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    background: #00000022;
    z-index: 101;
  }

  &__content {
    flex-direction: column;
    max-width: 500px;
    width: 100%;
    right: 0;
    top: 0;
    bottom: 0;
    background: #fff;
    z-index: 1;
    position: fixed;
    .shipping_info_w {
      margin-top: 10px;
    }
  }

  &__continue-checkout__container {
    background: #f1f1f1;
    padding: 1rem;
  }

  &__continue-checkout {
    background: $pz-color-primary;
    color: $pz-color-text-primary-light;
    padding: 1rem;
    text-align: center;

    &:hover {
      background: $pz-color-primary-light;
      color: $pz-color-text-primary-light;
    }
  }

  &__list {
    padding-bottom: 2rem;
    overflow: hidden;
    overflow-y: auto;
  }

  &__text{
    color : #000000;

    &:hover{
      color : #000000;
    }
  }

  &__total-amount {
    margin-bottom: 1rem;

    & > div:nth-child(1) {
      align-self: center;
    }

    & > div:nth-child(2) {
      font-family: "Financier Bold";
      font-size: 1.75rem;

      .--currency {
        font-family: "Financier Medium";
        font-size: 1.2rem;
      }
    }
  }
}

.mini-basket-mobile {

  &__back-drop{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
  
  &__container{
    background-color: #fff;
    position: fixed;
    top:50%;
    left: 15px;
    right: 15px;
    transform: translateY(-50%);
    z-index: 2;
    text-align: center;
  }

  &__title{
    display: block;
    border-bottom: 1px solid #d8d8d8;
    margin-bottom: 20px;
    padding: 15px;
  }

  p{
    padding: 15px;
    margin-bottom: 20px;
  }

  &__buttons{
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    padding: 15px 0;

    a{
      padding: 1rem;
    }
  }
}
@import './variables';

.pz-button__action {
  @keyframes spinner {
    to {transform: rotate(360deg);}
  }

  position: relative;

  &.loading {
    &:before {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: inherit;
      z-index: 2;
    }

    &:after {
      content: '';
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      left: 50%;
      width: $pz-spinner-size;
      height: $pz-spinner-size;
      margin-top: (($pz-spinner-size / 2) * -1);
      margin-left: (($pz-spinner-size / 2) * -1);
      border-radius: 50%;
      border: $pz-spinner-thickness solid $pz-spinner-color;
      border-top-color: transparent;
      animation: spinner $pz-spinner-speed linear infinite;
      z-index: 3;
    }
  }
}
@import './variables';

.account-menu__item-list {
  padding: $account-menu__item-list-padding;
}

.account-menu__item-icon {
	display: none;
  font-size: $account-menu__item-icon-font-size;
  text-align: $account-menu__item-icon-text-align;
}

.account-menu__item-title {
  font-weight: $account-menu__item-title-font-weigth;
}

.account-menu__item-link {
  color: $account-menu__item-link-color;
}

.account-menu__item-text {
	color: #666666;
	font-size: 13px;

	&.active {
		color: #000;
    text-decoration: underline;
	}

	@media (max-width: 991px) {
		display: inline-block;
		vertical-align: top;
		padding-top: 3px;
	}
}
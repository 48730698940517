.black-friday-container-two {
    max-width: 1200px;
    margin: 0 auto;
    overflow: hidden;
  
    picture,
    img {
      display: block;
      width: 100%;
    }
  
    .top-banner {
      margin: 60px 0 30px;
      text-align: center;
  
      @media (max-width: 767px) {
        margin-top: 0;
      }
  
      h1 {
        font-size: 48px;
        padding: 70px 15px;
  
        @media (max-width: 767px) {
          font-size: 30px;
        }
      }
    }
  
    .banner-section {
      margin-bottom: 30px;
    }
  
    .flex-section {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
  
      @media (max-width: 767px) {
        padding: 0 14px;
      }
  
      &.double a {
        width: calc(50% - 15px);
        max-width: 585px;
  
        @media (max-width: 767px) {
          width: calc(50% - 7px);
        }
      }
  
      &.triple a {
        width: calc(33% - 15px);
        max-width: 380px;
  
        @media (max-width: 767px) {
          width: calc(33% - 7px);
        }
      }
    }
  }
  
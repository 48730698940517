@import './variables';

.textarea {
  width: $textarea-width;
}

textarea {
	height: 94px;
	padding: 15px;
	resize: none;
	border: 1px solid #c9c9c9;
	font-size: 14px;

	&::placeholder {
	  color: #9d9d9d;
	}
}
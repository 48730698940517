@import '~jquery-ui.1.11.1/themes/base/datepicker.css';

.profile-form {
	padding-bottom: 20px;
}

.account__container {
	.account__content__description {
		color: #9d9d9d;
	}
	#changePasswordFormWrapper {
		.input-wrapper {
			margin-bottom: 0;
		}
		.password-input-wrapper {
			margin-bottom: 20px;
		}
		.password-description {
			font-size: 14px;
			color: #9d9d9d;
		}
		.p-error-msg {
			font-size: 12px;
			text-align: right;
			display: block;
			color: #cc5965;
		}
		label{
			&.error{
				font-size: 12px;
				text-align: right;
				display: block;
			}	
		}
	}
	#ChangeEmailFormWrapper {
		.input-wrapper {
			margin-bottom: 0;
		}
		.email-input-wrapper {
			margin-bottom: 20px;
		}	
		.p-error-msg {
			font-size: 12px;
			text-align: right;
			display: block;
			color: #cc5965;
		}
		label{
			&.error{
				font-size: 12px;
				text-align: right;
				display: block;
			}	
		}
	}
}

#profileFormWrapper {
	margin-top: 32px;

	.input-label-wrapper {
		position: relative;

		&:nth-child(1) {
			margin-right: 11px;
		}
		&:nth-child(-n+2) {
			width: calc(50% - 8px);
			display: inline-block;
		}
		.label_ {
			position: absolute;
			top: 5px;
			left: 17px;
			font-size: 10px;
			color: #9d9d9d;
		}
		input,
		textarea {
			&:focus {
				&::placeholder {
					color: transparent;
				}
			}
		}
	}

	.requirement-info-text {
		font-size: 14px;
		margin: 24px 0 32px 0;
		color: #9d9d9d;
	}

	label {
		&.error {
			font-size: 12px;
			text-align: right;
			display: block;
		}
	}

	.change-email-content {
		display: flex;

		.input-wrapper {
			flex-grow: 1;
			margin-right: 10px;
		}

		.change-email-btn {
			border: 1px solid #9d9d9d;
			margin-bottom: 20px;
			height: 54px;
			display: flex;
			align-items: center;
			padding: 0 10px;
			background-color: #000;
			
			a {
				color: #fff;
			}
		}
	}
	
}

#ui-datepicker-div {
	background-color: #fff;
	border: 1px solid #9d9d9d;
	padding: 0 20px;
	color: #9d9d9d;

	a {
		color: #000;
	}
}

.contracts-link {
	font-weight: bold;
	color: #000;
	text-decoration: underline !important;
}

.delete-account-modal-button{
	border: 0;
	outline: 0;
	padding: 0;
	color: #969696;
	background-color: white;
  font-weight: 500;
	width: 12.5rem;
	display: block;
	margin-inline: auto;
	text-align: center;
	font-size: 0.75rem;
	margin-top: 1rem;
	@media screen and(min-width: 768px) {
		margin-top: 1.5rem;
		font-size: 0.875rem;
	}
	&:focus,&:active{
		outline: 0;
	}
}

.delete-account-modal{
	&:not(.-visible),.pz-modal-dialog__header{
		display: none;
	}

	.error-text {
		color: #cc5965;
	}

	.pz-modal-dialog {
		max-width: 37.75rem;
		width: 100%;

		.modal-body {
			text-align: center;
			font-size: 1.1rem;
			max-width: 24rem;
			margin: 0 auto;
			padding: 1rem 1rem 0 1rem;

			img {
				margin-bottom: 1rem;
			}
		}

		.modal-footer {
			border-top: none;
			flex-wrap: nowrap;

			button {
				width: 100%;
				height: 3.25rem;
				font-weight: 500;
				border-radius: 0;
				color: black;
				font-size: 1rem;
			}

			.js-close-button{
				border: solid 1px #000;
				background-color: white;
			}

			.delete-account-button {
				background-color: black;
				color: white;
			}
		}

		@media screen and (max-width: 768px) {
			max-width: 22.125rem;

			.modal-footer {
				padding: 0;
			}
		}
	}
}
.campaign {
  background-color: #001937;
  text-align: center;
  margin-top: 15px;
  height: 30px;
  
  &__text {
    color: #ffffff;
    font-size: 14px;
  }
}
.basket-summary {

  &__receipt-container {
    padding-top: 15px
  }

  &__total-amount,
  &__receipt-item,
  &__gift-package{
    display: flex;
    margin: 10px 0;
    flex-direction: row;
    justify-content: space-between;

    &>div:nth-child(1) {
      font-weight: bold;
    }

    &>div:nth-child(2) {
      min-width: 90px;
      text-align: right;
    }
  }
  
  &__gift-package.hidden{
    display: none;
  }

  &__total-amount {
    margin-top: 30px;

    &>div:nth-child(1) {
      align-self: center;
    }

    &>div:nth-child(2) {
      font-family: "Financier Bold";
      min-width: 150px;
      font-size: 1.5rem;

      .--currency {
        font-family: "Financier Medium";
      }
    }
  }

  &__title {
    border-bottom: 1px solid $pz-color-text-disabled-dark;
    padding-bottom: 15px;
    margin: 0
  }

  @media (max-width: 767px) {
    &__title {
      padding-top: 25px;
    }
  }
}
.favourites__head {
  margin-bottom: 56px;
  
  @media (max-width: 991px){
    margin-bottom: 15px;
  }
}

.favourites-items__remove {
  font-size: 14px;
  padding: 22px 0;

  .icon-basket {
    color: #9d9d9d;
    margin-right: 10px;
  }
}

.favourites__items--head {
  padding: 8px 0;
  border-bottom: solid 1px #dfdfdf;

  @media (max-width: 991px){
    display: none;
  }
}

.favourites__items--title {
  font-size: 14px;
  color: #666666;
}

.favourites__list--empty {
  font-size: 14px;
}

.favourites__wrapper {
  flex-direction: column;
}

.favourites__item {
  padding: 25px 0;
  border-bottom: 1px solid #dfdfdf;
}

.favourites__item__img-wrapper {
  max-width: 94px;

  @media (max-width: 991px){
    float: left;
  }
}

.favourites__item__product--info {
  padding-left: 15px;
  width: 55%;

  @media (max-width: 991px){
    line-height: 1;
    max-width: calc(100vw - 124px);
    margin-bottom: 22px;
  }
}

.favourites__item__product-name {
  margin-bottom: 8px;

  @media (max-width: 991px){
    line-height: 1;
    height: 23px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.favourites__item__product-attributes {
  font-size: 12px;
  color: #818181;

  @media (max-width: 991px){
    display: block;
    line-height: 1;
  }
}

.favourites__item__product-prices {
  @media (max-width: 991px){
    float: right;
    max-width: calc(100vw - 124px);

    & > div {
      padding-left: 0;
    }
  }
}

.favourites__item__product-price {
  line-height: 1;
  @media (max-width: 991px){
    margin-bottom: 3px;
  }
}

.favourites__item__product-retail-price {
  line-height: 1;
  font-size: 14px;
  color: #818181;
  text-decoration: line-through;
}

.remove-from-favourites {
  color: #9d9d9d !important;
}

.remove-from-all-favourites {
  color: #000 !important;
}

.favourites__item__product-img {
  width: 94px;
}

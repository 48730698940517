.product-image-wrapper {
	padding-bottom: 149.567%;

	.product-item__img {
		position: absolute;
		top: 0;
		left: 0;
	}

  .add-to-cart {
    display: none;
  }
}

.product-item {
  &__content__list {
    min-height: 9.25rem;

  }
}

.product-item-favourite-elem {
	position: relative;
  height: 44px;

  &:after {
  	font-family: 'icomoon';
  	content: "\e902";
  	color: #212529;
  }

  &.active:after {
  	content: '\e901';
  }
}

.product-item__price-border {
	border-left-style: solid;
  border-left-color: #000;
  border-left-width: 0.5px;
}

.product-item-offer-box {
	display: flex;
  flex-direction: column;
  align-items: center;
}

.product-item__price--retail {
	margin-right: 0 !important;
}

.product__retail__price {
  margin: 0 !important;
}

.product-item__name {
  font-size: 14px;
  font-family: "Plain Regular";
}

.product-item-price-wrapper-list {
  .product-item-campaign {
    margin-left: 0;
  }
  .product-item-campaign-price {
    margin-left: 5px;
    @media (max-width:768px) {
      font-size: 14px;
      margin-left: 10px;
    }
  }
}

@include media-breakpoint-up(sm) {
  
  .dowry-item:hover {
      .action {
        display: block;
        transform: translateY(0);
      }
      .product-amount {
        &__quantity {
          display: flex;
        } 
      }
  }
  .product-image-wrapper {  
     .js-mobile-add {
        display: none;
      }
}
}

.action {
  display: none;
  position: absolute;
  z-index: 20;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(100%);
  user-select: none;
  transition: transform 300ms;
  
  &__error {
    display: none;
    font-size: 0.75rem;
    color: red;
  }
  
  &__loading {
    height: 2.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.85);
    font-weight: bold;
    font-size: 0.75rem;
    padding: 0 1rem;
    cursor: pointer;
    overflow: hidden;
    position: relative;
  }

  &__content {
    display: none;
    padding: 1.25rem;
    background-color: rgba(255, 255, 255, 0.85);
    flex-direction: column;

    &--novariants {
      text-align: center;
      background: #000;
      color: #fff;
      cursor: pointer;
      width: 60px;
      margin: 0 auto;
    }

    &--novariants__mobile {
      display: none;
    }

    button {
      height: 2.75rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: black;
      color: white;
      border-radius: 0.5rem;
      font-weight: bold;
      font-size: 0.75rem;
      padding: 0 1rem;
      cursor: pointer;
      overflow: hidden;
      position: relative;
    
      &:hover {
        opacity: 0.8;
      }
    
      &.hidden {
        display: none;
      }
    }
  }

  .variant {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;

    &__label {
      font-size: 0.75rem;
      font-weight: 500;
    }
    
    &__options {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    
    &__option {
      font-size: 0.8125rem;
      padding: 0.375rem 0.6875rem;
      cursor: pointer;
      color: #070707;
    
      &:not(.-disabled):hover,
      &.-selected {
        background-color: #949497;
      }
    
      &.-disabled {
        color: #9e9e9e;
        cursor: not-allowed;
      }
    }
  }

  &.-ready {
    .action__trigger {
      display: none;
    }

    .action__content {
      display: flex;
    }
  }

  &.-has-error {
    .action__error {
      display: block;
    }
  }
}

  .product-image-wrapper {  
    .add-to-cart {
      display: none;
      cursor: pointer;
      width: 70%;
      text-align: center;
      padding: 5px;
      background-color: #0d2144;
      color: #fff;
      z-index: 21;
      border-radius: 6px;
      margin: 10px auto;
    }
  }

  .action {
    &.show-variants {
      display: block;
      transform: translateY(2px);
    }
    &__content {
      position: relative;
      padding: 0.50rem 0.25rem;

     
      &--novariants {
        display: none;
      }

      &--novariants__mobile {
        display: block;
      }
    }
  }

  .product-amount {

    &__quantity {
      display: none;
      width: fit-content;
      background-color: #0d2144;
      border-radius: 6px;
      align-items: center;
      height: 2rem;
      margin: 0 auto;
      &.show-amount {
        display:flex;
      }
      & > .js-product-amount-item-quantity {
        background: #fff;
        color: #000;
        border-radius: 6px;
        padding: 0 8px;
        }
  
      & > button {
        background: transparent;
        display: block;
        font-size: 0.9rem;
        border: none;
        width: 2rem;
        min-width: 1.5rem;
        height: 2rem;
        padding: 0;
        color: #fff;
  
        &:focus {
          outline: 0;
        }
  
        i {
          font-size: 8px;
        }
      }
    }
  }



  @include media-breakpoint-down(sm) {
    .product-image-wrapper {  
      .js-mobile-add {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
      .desktop-add-to-cart {
        display: none;
      }
    }
    .product-item {
      width:49%;
    }
  }

  .loader {
    display: none;
    border: 8px solid #f3f3f3;
    border-top: 8px solid #130f49;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
  }
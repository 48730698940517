@import '../components/address-form/index.scss';
@import './checkout/payment-options/fundsTransfer.scss';
@import './checkout/click-collect/';
@import '~pz-masterpass/';

.checkout {
  &__summary-product-name {
    height: 22px;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__basket-item-price-wrapper > div {
    line-height: 1;

    &.basket-item__retail-price {
      margin-top: 3px;
    }
  }

  .basket-item__price {
    font-size: 16px;
  }

  &__summary-products-list {
    position: relative;
    padding-bottom: 30px;

    @media (max-width: 991){
      padding-bottom: 45px;
    }
  }

  &__summary-product-item-bottom {
    position: absolute;
    width: calc(100% - 0.5rem);
    left: 0.5rem;
    bottom: 0;
  }

  &__header {
    font-size: 60px;
    text-align: center;
    border-bottom: 1px solid #dee2e6;
    img{
      width: 60px;
      margin-bottom: 15px;
    }
  }
  &__tab {
    color: #ababab;
    font-size: 16px;

    @media (min-width: 576px) {
      font-size: 13px;
    }

    &.active {
      color: #212529;
    }
  }

  &__card-img {
    position: absolute;
    bottom: 0px;
    right: 0px;
  }

  &__steps {
    @media (max-width: 575px) {
      justify-content: space-around;
    }
  }

  &__steps-icon {
    color: #ababab;
  }

  &__shipping,
  &__installments {
    @media (min-width: 576px) {
      border-left: 1px solid #dee2e6;
    }
  }

  &__installments {
    &-title {
      @media (max-width: 575px) {
        display: none;
      }
    }

    &-content {
      @media (max-width: 575px) {
        border-top: 1px solid #dee2e6;
      }
    }
  }

  &__gift-package{
    &.hidden{
      display: none;
    }
  }
  
  &__summary {
    &-title {
      font-size: 23px;
      line-height: 1.3;
    }

    &-gift-button{
      color: #000;
      font-size: .8rem;

      span{
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }

  &__address-box {
    & > div {
      margin: 0.5rem;
      padding: 0.5rem;
      border: 1px solid #dee2e6 !important;
      font-size: 14px;
      
      @media (max-width: 575px) {
        height: auto !important;
      }
    }

    .icon-plus_circle{
      font-size: 19px;
      margin-right: 8px;
      padding-top: 3px;
    }
    
    .address-box__content {
      word-break: break-all;
      overflow: hidden;
    }

    .adress-box__title {
      overflow: hidden;
    }
  }

  &__payment-tabs{
    z-index: 1;
  }

  &__payment-tab {
    background-color: rgba(215, 215, 200, 0.1);
    color: #9b9b9b;
    border: 1px solid #e5e5e5;
    font-size: 16px;

    &.active {
      background-color: #ffffff;
      color: #000000;
      border: 1px solid #dee2e6;
      border-bottom: 1px solid #ffffff;
    }
    
    &-iyzico{
      padding-inline: 1rem;
    }
  }

  &__payment-contents {
    margin-top: -1px;
  }

  &__success-items {
    width: 300px;

    @media (min-width: 768px) {
      width: 600px;
    }
  }

  &__success-item {
    @media (min-width: 768px) {
      &:nth-child(odd) {
        padding-right: 0.5rem;
        border-right: 1px solid #979797;
      }

      &:nth-child(even) {
        padding-left: 0.5rem;
      }
    }
  }

  &__success-image {
    width: 58px;
    height: 88px;
  }

  &__success-title {
    font-size: 30px;
    text-align: center;

    @media (min-width: 576px) {
      font-size: 36px;
    }
  }

  &__success-muted {
    color: #818181;
    font-size: 12px;
  }

  &__success-price {
    line-height: 1;
  }

  &__success-retail {
    color: #818181;
    line-height: 1;
    text-decoration: line-through;
  }

  &__success-button {
    width: unset;
    padding: 0px 30px;
  }

  input[type=checkbox],
  input[type=radio] {
    font-size: 1em;
  }

  .border,
  .border-bottom {
    border-color: #dfdfdf;
  }

  .basket-item {
    border-bottom: 1px solid #dfdfdf;
  }

  .basket-item__image {
    width: 58px !important;
    height: 88px !important;

    @media (max-width: 991px){
      width: 94px !important;
      height: 143px !important;
    }
  }

  &__date-picker {
    flex: 1;
    height: 38px;
    
    .select {
      border-color: #ced4da;
    }
  }

  div.error {
    color: #d72b01;
  }

  .form-control.has-errors {
    border-color: #d72b01;
  }

  label.has-errors {
    input:before {
      color: #d72b01;
    }
  }

  &-auth-page {
    max-width: unset !important;

    .nav-tabs {
      border-bottom: 1px solid #c9c9c9;
    }

    .nav-link {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      border: 1px solid #c9c9c9;
      color: #495057;
      background-color: #f5f5f5;
      
      @media (max-width: 768px) {
        padding: 0.5rem;
        font-size: 16px;
      }

      &.active {
        border-color: #c9c9c9 #c9c9c9 #fff;
        background-color: #fff;
      }
    }
  }

  .icon-siparis_onay {
    font-size: 150px;
  }

  .cvc-desc {
    color: #3f4737;
    font-size: 14px;
    position: relative;

    img{
      left:0;
      top: 25px;
      z-index: 1;
      opacity: 0;
      transition: .2s;
      position: absolute;
      visibility: hidden;
    }

    &:hover{
      img{
        opacity: 1;
        visibility: visible;
      }
    }

    @media (max-width: 768px) {
      position: initial;
      img{
        top: 95px;
        width: 300px;
        left: -200px;
      }
    }
  }

  label.error {
    margin-left: 0;
    font-size: 14px;
  }

  .cvc-group .error {
    order: 3;
  }

  &-payment {
    &-iframe-wrapper {
      position: fixed;
      height: 100vh;
      width: 100%;
      background-color: #fff;
      z-index: 50;
      top: 0;

      &-close-button {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #000;
        color: #fff;
        width: 32px;
        height: 32px;
        top: 28px;
        z-index: 40;

        i {
          color: #fff;
          pointer-events: none;
        }
      }

      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.yurtici, .YURTICI {
  font-size: 0 !important;
  background: url('../orders/shipping-logos/yurtici-kargo.png') no-repeat left center;
  background-size: contain;
}

.scty, .SCTY {
  font-size: 0 !important;
  background: url('../orders/shipping-logos/scotty-logo.png') no-repeat left center;
  background-size: contain;
}

.kg, .KG {
  font-size: 0 !important;
  background: url('../orders/shipping-logos/kg.png') no-repeat left center;
  background-size: 35%;
  @media only screen and (max-width: 1200px) {
    background-size: 50%;
  }
  @media only screen and (max-width: 992px) {
    background-size: 75%;
  }
  @media only screen and (max-width: 576px) {
    background-size: 15%
  }
  @media only screen and (max-width: 435px) {
    background-size: 23%
  }
}

.ks, .KS {
  font-size: 0 !important;
  background: url('../orders/shipping-logos/kargom-sende.png') no-repeat left center;
  background-size: contain;
}

.packup_nextday_delivery, .PACKUP_NEXTDAY_DELIVERY {
  font-size: 0 !important;
  background: url('../orders/shipping-logos/packupp.png') no-repeat left center;
  background-size: contain;
}

#GarantiPay {
  .checkout-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.2rem;
    border-bottom: 0.4px solid #d4d4d4;
    &__title {
      width: 100%;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #181818;
    }
    &__img {
      width: 131px;
      @media (max-width: 567px) {
        width: 100px;
      }
    }
  }
  .payment-gpay {
    padding: 1rem;
    &__info {
      p {
        padding: 0.5rem 0.5rem;
        font-size:14px;
        color: #666666;
      }
    }
    &__agreement {
      label.err {
        border-color: red;
      }
      label {
        max-width: 100%;
        padding: 0.2rem 0.8rem;
        color: #4a4a4a;
        line-height: 19px;
        text-align: left;
        cursor: pointer;
        display: flex;
        align-items: center;
        input[type=checkbox] {
          position: relative;
          cursor: pointer;
          width: 15px;
          height: 13px;
        }
        a {
          color: #4a4a4a;
        }
      }
    }
    &__error { 
      color:red;
      font-size:12px;
    }
    &__button {
      position: relative;
      .checkout__submit_gpay {
        width: 252px;
        height: 47px;
        max-width: 100%;
        vertical-align: top;
        color: $white;
        left: 0;
        right: 0;

        &:before {
          color: $white;
          font-size: 10px!important;
          position: absolute;
          left: 1rem;
          top: 50%;
          transform: translateY(-50%);
        }
        @media (max-width: 567px) {
          width: 100%;
        }
      }
    }
  }
}


/* MasterPass */
.pz-mp-direct-purchase {
  padding: 0;
  margin-top: 20px;
}

.pz-mp-save-card-cb-w__logo {
  width: 100px;
}

.pz-mp-save-card-cb-w i{
  margin-left: auto;
}

.pz-mp-save-card-cb-w__text{
  margin: 0;
  margin-top: 10px;
}
.pz-mp-card-list-info,
.pz-mp-switch-payment-type-btn {
  font-size: 14px;
}
.pz-mp-card-list {
  padding-left: 0;
  &__item-delete {
    &-btn {
      border: none;
      padding: 0;
      width: auto;
      height: auto;
      line-height: 1;
      background-color: transparent;
    }
    &-icon {
      font-size: 32px;
      &:after {
        font-family: 'pz-icon' !important;
        font-style: normal;
        font-weight: normal !important;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\f12e";
      }
    }
  }
}
.sales-information{
  padding-top: 0!important;
  .agreement-title {
    padding-top: 0!important;
  }
}
.agreement-wrapper {
  width: 100%;
  padding-top: 15px;
  .agreement-title {
    font-size: 22px;
    padding-top: 15px;
    padding-bottom: 15px;
    text-decoration: underline;
    a {
      color: #000;
      text-decoration: underline;
    }
  }
  .agreement-contect {
    font-size: 14px;
    padding: 30px 20px 20px 20px;
    border: 1px solid #dee2e6;
  }
}

.success-guest-form {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 1rem 0.5rem;

  form {
    max-width: 346px !important;
  }

  h3 {
    font-size: 22px;
    margin: 1.5rem 0 2rem;
    max-width: 550px;
    text-align: center;
    @media (max-width: 567px){
      font-size: 1rem;
      margin: 1.5rem 0 1rem;

    }
  }

  .auth-form {
    max-width: 430px;

    .password-label {
      color: #9d9d9d;
    }
    input {
      border-radius: 0 !important;
    }

    .muted-text {
      color: #9d9d9d;
      font-size: 0.9rem;
      font-weight: 400;
      margin: 3px 0 0 5px;
      line-height: 1.5;

      .link {
        color: #000;
        font-weight: 500;
        text-decoration: underline !important;
      }
    }
  }
}

.mobile-app {
  .ccp---nb-interstitial-overlay {
    display: none !important;
    visibility: hidden;

    .ccp---nb {
      display: none !important;
      visibility: hidden;
    }
  }
}

#salesModal{
  .modal-header{
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 2;
    .save-sales-document-button{
      background-color: transparent;
      border: 0;
    }
  }
}
@media print {
  @page {margin: 0;}
  body.checkout-page {
    padding: 0 !important;
    #CheckoutPage,
    .checkout__header,
    #salesInformation,
    .modal-backdrop {
      display: none;
    }

    #salesModal {
      position: relative;
      padding-right: 0 !important;
      overflow: hidden;

      .modal-header{
        position: static;
        .save-sales-document-button,button.close{
          display: none;
        }
      }

      .modal-dialog{
        width: 100%;
        height: 100%;
        max-width: 100%;
        margin: 0;
      }
    }
  }
}


.iyzico-payment-tab-content{
  padding: 25px;
  .checkout-payment-type{
    &__head{
      font-size: 18px;
      border-bottom: 2px solid #d7d7d7;
      padding-bottom: 10px;
      font-weight: 500;
      margin-bottom: 25px;
    }
    &__description{
      color: #58585a;
      font-size: 14px;
      text-align: left;
    }
  }
}

.checkout-pay-with-iyzico {
  display: flex;
  flex-direction: column;
  width: 100%;
  .checkout__submit{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    font-size: 15px;
    color: #fff;
    background-color: #1e64ff;
    border-radius: 22px;
    text-decoration: none;
    transition: all 0.3s ease;
    width: 100%;
    @media screen and (min-width:922px) {
      width: 50%;
    }

    @keyframes spinner {
      to {transform: rotate(360deg);}
    }
  
    position: relative;
  
    &.loading {
      &:before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: inherit;
        z-index: 2;
        border-radius: 22px;
      }
  
      &:after {
        content: '';
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 50%;
        width: $pz-spinner-size;
        height: $pz-spinner-size;
        margin-top: (($pz-spinner-size / 2) * -1);
        margin-left: (($pz-spinner-size / 2) * -1);
        border-radius: 22px;
        border: $pz-spinner-thickness solid $pz-spinner-color;
        border-top-color: transparent;
        animation: spinner $pz-spinner-speed linear infinite;
        z-index: 3;
      }
    }
  }

  &__complete {
    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-top: 1.5rem;
      margin-bottom: 1.5rem;
      gap: 1rem;
      label {
        text-align: left;
        color: #58585a;
        font-size: 13px;
        line-height: 19px;
        max-width: 440px;
        display: flex;
        align-items: center;
      
        a {
          color: #58585a;
          text-decoration: underline !important;
        }
      }
    }
  }
}

.iyzico-footer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;

  img {
    width: 25px;
    margin-right: 10px;
  }

  p {
    margin-bottom: 0;
    color: #58585a;
    font-size: 13px;
  }
}
.click-collect{
  &.-active {
    border:2px solid black;
    .click-collect-checkbox {
      display: block !important;
      position: absolute;
      top: 8px;
      left: 15px;
    }
  }
}
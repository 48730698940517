.catalog {

  &-banner {
    max-width: 1440px;
    position: relative;

    &-img {
      width: 100%;
    }

    &-content {
      left: 0;
      right: 0;
      top: 25%;
      bottom: 0;
      margin: auto;
      max-width: 690px;
      position: absolute;
      text-align: center;

      h1 {
        font-size: 36px;
      }

      a , p{
        color: #000000;
      }

      @media (max-width: 992px) {
        padding: 0 35px;
      }
    }
  }

  .seperator{
    width: 100%;
    height: 75px;
    overflow:hidden;

    img{
      width: 100%;
    }
  }

  .double-banner {
    display: flex;
    padding: 0 85px;
    margin: 60px auto 0 auto;
    justify-content: space-between;

    &-image {
      width: 100%;
      max-width: 44.8%;
      @media (max-width: 540px) {
        max-width: 100%;
      }
    }

    &-content {
      width: 100%;
      display: flex;
      max-width: 56.2%;
      justify-content: center;
      @media (max-width: 540px) {
        max-width: 100%;
        padding: 0 35px;
      }
    }

    .content-wrapper {
      display: flex;
      max-width: 370px;
      flex-direction: column;
      justify-content: center;

      .cta-button {
        color: #000;
        width: 220px;
        padding: 20px;
        font-size: 16px;
        text-align: center;
        margin: 20px auto 0 auto;
        border: solid 1px #c9c9c9;
        background-color: #ffffff;
      }

      @media (max-width: 540px) {
        margin-top: 15px;
      }
    }

    @media (max-width: 540px) {
      padding: 0;
      margin-top: 15px;
      flex-direction: column !important;
    }
  }

  &-widget {
    padding: 55px 0;

    .catalog-slider {
      height: auto !important;
    }

    &-item {
      &-img {
        width: 100%;
        margin-bottom: 15px;
      }

      &-name {
        margin: auto;
        color: #000000;
        margin-bottom: 15px;
      }

      &-price {
        font-family: 'Financier Regular';
        font-size: 18px;
        color: #000000;
        font-weight: bold;
      }

      &-retail {
        color: #818181;
        text-decoration: line-through;
        font-size: 13px;
      }
    }

    .lSAction {

      .lSPrev, .lSNext {
        color: #000000;
        opacity: 1;
        background-image: none;

        &:after {
          font-family: 'icomoon' !important;
          speak: none;
          font-style: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          color: #000;
          font-weight: bold;
          font-size: 21px;
        }
      }

      .lSPrev {
        &:after {
          content: "\e913";
        }
      }

      .lSNext {
        &:after {
          content: "\e912";
        }
      }
    }
  }


  .main-text {
    width: 100%;
    display: flex;
    max-width: 60%;
    color: #666666;
    font-size: 30px;
    text-align: center;
    margin: 100px auto 100px auto;
    font-family: 'Financier Medium';

    @media (max-width: 992px) {
      max-width: 100%;
      padding: 0 35px;
      font-size: 24px;
      margin: 15px auto;
    }
  }

  .first-template {
    padding: 0 80px;
    margin-bottom: 65px;

    .image-w-text {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: space-between;

      .text-area {
        display: flex;
        margin-top: 55px;
        justify-content: center;
      }

      .img-area, .text-area {
        flex-grow: 1;
        max-width: 100%;
        flex-basis: 0;
      }

      .text-content {
        color: #666666;
        max-width: 60%;
        font-size: 16px;
      }
    }

    .triple-block {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: space-between;

      .first-block {
        margin-top: 200px;
      }

      .first-block, .second-block {
        flex-grow: 1;
        max-width: 100%;
        flex-basis: 0;
      }

      .second-block {
        margin-top: -100px;
      }

      .second-block {
        display: flex;
        flex-direction: column;

        .first-image {
          align-self: flex-end;
        }

        .second-image {
          align-self: flex-start;
          margin: 100px 0 0 80px;
        }
      }
    }

    @media (max-width: 992px) {
      padding: 0;
      margin-bottom: 40px;

      .image-w-text {
        flex-direction: column;

        .img-area{
          margin: auto;
        }

        .text-area {
          padding: 0 30px;
          margin: 15px 0 65px 0;
          flex-basis: 100%;

          .text-content {
            max-width: 100%;
          }
        }
      }

      .triple-block {
        align-items: center;

        .first-block {
          flex-grow: 1.1;
          margin-top: 0px;

          img {
            padding-right: 20px;
          }
        }

        .second-block {
          flex-grow: 0.5;
          margin-top: 0;

          .second-image {
            margin: 30px 0 0;
          }
        }
      }
    }
  }

  .second-template {
    padding: 0 175px;
    margin: 50px 0 65px 0;

    .first-block {
      display: flex;
      align-items: center;
      justify-content: space-around;

      .text-area, .image-area {
        flex-grow: 1;
        max-width: 100%;
        flex-basis: 0;
      }

      .text-area {
        flex-grow: 1.33;

        .text-content {
          width: 60%;
          margin: 0 auto;
          color: #666666;
          font-size: 16px;
        }
      }
    }

    .second-block {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;

      .triple-area, .single-area {
        flex-grow: 1;
        max-width: 100%;
        flex-basis: 0;
      }

      .triple-area {
        display: flex;
        flex-grow: 1.33;

        .double-image {
          display: flex;
          margin: 70px 0 0 60px;
          flex-direction: column;

          img {
            &:nth-child(2) {
              margin-top: 30px;
            }
          }
        }
      }

      .single-area {
        margin-top: 180px;
        text-align: right;
      }
    }

    @media (max-width: 992px) {
      padding: 0;
      margin: 0 0 40px 0;

      .first-block {
        flex-direction: column;

        .img-area{
          margin: auto;
        }

        .text-area {
          flex-grow: 1;
          padding: 0 30px;
          flex-basis: 100%;
          margin: 40px 0 65px 0;

          .text-content {
            width: 100%;
          }
        }
      }

      .second-block {
        flex-direction: column;

        .triple-area {
          flex-basis: 100%;
          margin: 60px auto 40px auto;

          .first-image {
            img {
              padding-right: 15px;
            }
          }

          .double-image {
            margin: 20px 0 0 0;

            img {
              margin-top: 20px;
              padding-left: 15px;
            }
          }
        }

        .single-area {
          margin: 0 auto;
          flex-basis: inherit;
        }
      }
    }
  }


  .third-template {
    padding: 0 230px;
    margin: 80px 0 65px 0;

    .image-w-text {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row-reverse;
      justify-content: space-between;

      .img-area {
        text-align: right;
      }

      .text-area {
        display: flex;
        margin-top: 15px;
        justify-content: center;
      }

      .img-area, .text-area {
        flex-grow: 1;
        max-width: 100%;
        flex-basis: 0;
      }

      .text-content {
        margin: 0 auto;
        color: #666666;
        max-width: 80%;
        font-size: 16px;
      }
    }

    .triple-block {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: space-between;

      .first-block, .second-block {
        max-width: 100%;
        flex-basis: 0;
      }


      .first-block {
        flex-grow: 0.8;
        margin-top: -250px;
      }

      .second-block {
        flex-grow: 1.2;
        margin-top: 35px;
      }

      .second-block {
        display: flex;

        .first-image {
          margin-top: 90px;
        }

        .second-image {
          margin-left: 35px;
        }
      }
    }

    @media (max-width: 992px) {
      padding: 0;
      margin: 0;

      .image-w-text {
        flex-direction: column-reverse;

        .img-area{
          margin: auto;
        }

        .text-area {
          margin: 0;
          padding: 40px 30px;
          flex-basis: 100%;

          .text-content {
            max-width: 100%;
          }
        }
      }

      .triple-block {
        align-items: center;
        margin: 65px 0 40px;

        .first-block {
          margin-top: 0;
          flex-grow: 1.3;
          padding-right: 15px;
        }

        .second-block {
          margin-top: 0;
          flex-grow: 0.8;
          padding-left: 15px;
          flex-direction: column;

          .first-image {
            margin-top: 0;
          }

          .second-image {
            margin: 20px 0 0 0;
          }
        }
      }
    }
  }

  .forth-template {
    padding: 0 155px;
    margin: 80px 0 65px 0;

    .image-w-text {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: space-between;

      .text-area {
        display: flex;
        margin-top: 15px;
        justify-content: center;
      }

      .img-area, .text-area {
        flex-grow: 1;
        max-width: 100%;
        flex-basis: 0;
      }

      .text-content {
        margin: 0 auto;
        color: #666666;
        max-width: 65%;
        font-size: 16px;
      }
    }

    .triple-wrapper {
      position: relative;

      .double-block {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: space-between;

        .first-image, .second-image {
          flex-grow: 1;
          max-width: 100%;
          flex-basis: 0;
        }

        .first-image {
          margin-left: 70px;
          margin-top: 130px;
        }

        .second-image {
          text-align: right;
          margin-top: -500px;
        }
      }

      .single-image {
        text-align: right;
        margin-top: -370px;
        margin-right: 180px;
      }
    }

    @media (max-width: 992px) {
      padding: 0;
      margin: 0;

      .image-w-text {
        flex-direction: column-reverse;

        .img-area{
          margin: auto;
        }

        .text-area {
          margin: 0;
          padding: 40px 30px;
          flex-basis: 100%;

          .text-content {
            max-width: 100%;
          }
        }
      }

      .triple-wrapper {

        .double-block {
          align-items: center;
          margin: 45px 0 65px 0;
          flex-direction: row-reverse;

          .first-image {
            margin: 0;

            img {
              padding-left: 15px;
            }
          }

          .second-image {
            margin-top: 0;
            flex-grow: 1.3;

            img {
              padding-right: 15px;
            }
          }
        }

        .single-image {
          margin: 0;
          text-align: center;
        }
      }
    }
  }


  .collection-cta {
    width: 37%;
    margin: auto;
    padding-bottom: 75px;

    .button-area {
      text-align: center;

      p {
        color: #666666;
        font-size: 16px;
        margin-bottom: 10px;
      }

      a {
        width: 85%;
        padding: 15px;
        margin: 0 auto;
        color: #ffffff;
        display: block;
        font-size: 14px;
        background-color: #000;
      }
    }

    .slider {
      margin-top: 65px;

      &-title {
        font-size: 30px;
        text-align: center;
      }

      &-item {
        &-link {
          color: #000;
          margin-top: 5px;
          text-decoration: underline !important;
        }
      }


      &-cta {
        color: #000;
        display: block;
        font-size: 14px;
        margin-top: 50px;
        text-align: center;
        text-decoration: underline !important;
      }
    }

    @media (max-width: 992px) {
      width: 100%;
      margin: 45px 0 0 0;

      .button-area {
        padding: 0 30px;
        a{
          width: 100%;
        }
      }

      .slider {
        margin-top: 55px;

        &-title {
          padding: 0 30px;
          margin-bottom: 25px;
        }

        .lSSlideOuter{
          padding-left: 30px;
        }

        &-item{
          margin-right: 20px !important;
        }
      }
    }
  }

}
@import './variables.scss';
@import './fancybox.scss';
@import '../components/product/slider/index.scss';
@import '../components/product/content/index.scss';
@import '~pz-checkout-providers/';

/* Slider Start */
$product-thumbnail-active-indicator: #bae4f6;
$product-thumbnail-active-border-width: 2px;

%thumbnail-after {
  content: '';
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  border-color: $product-slider-thumbnail-border-color;
  border-bottom-color: $product-thumbnail-active-indicator;
  border-style: $product-slider-thumbnail-border-type;
}

/* Slider End */

/* Product Start */
.product {
  &__page {
    .pz-breadcrumb {
      @media (max-width: 991px) {
        padding-left: 10px;
      }
    }
  }

  &__content {
    min-width: 300px;
    flex: 1;

    @media (max-width: 991px) {
      width: 100%;
    }

    .fav-icon-button {
      display: flex;
      @media (max-width: 567px) {
        display: none;
      }
    }

    .fav-icon-button-sm {
      position: absolute;
      top: 5px;
      right: 1%;
    }


    .border-line {
      width: 100%;
      height: 1px;
      background-color: #6c757d;
    }

    &-first {
      @media (max-width: 768px) {
        margin:0.25rem 0 0.75rem !important;
      }

      
    }
  }

  &__wrapper {
    padding-bottom: 47px;

    @media (max-width: 768px) {
      position: relative;
    }

    .product-detail-slider {
      flex: 3;
    }
  }

  &__ghost-button {
    color: #212529;
    display: block;
    height: 52px;
    text-align: center;
    border: 1px solid;
    line-height: 50px;
    font-size: 0.70rem;
    align-items: center;
    margin-bottom: 0.2rem;
    margin-right: 5px;
    cursor: pointer;
    background-color: #ffffff;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      color: #212529;
    }
  }

  &__ghost-button-sm {
    i {
      color: #000;
      font-size: 20px;
    }
  }

  &__add-cart-button-wrapper {
    margin-bottom: 10px;
   
    @media (max-width: 761px) {
      display: flex;
      justify-content: space-between;
      position: fixed;
      width: 100%;
      left: 0;
      bottom: 0;
      z-index: 100;
      background-color: #fff;
      margin-bottom: 0;
      padding: 10px 15px;
    }

    .product__price, .product__price--currency {
      @media (max-width: 761px) {
        font-size: 18px;
      }
    }

    .product__retail__price {
      @media (max-width: 761px) {
        font-size: 14px;
        margin-left: 0;
      }

      .product__price--currency {
        @media (max-width: 761px) {
          font-size: 14px;
        }
      }
    }

  }

  &__title {
    font-family: 'Plain Regular';
  }

  &__retail__price {
    font-family: 'Financier Regular';
    text-decoration: line-through;
    line-height: 1;
    margin-left: 8px;
    padding-top: 6px;

    @media (max-width: 567px) {
      padding-top: 0;
    }
  }

  &__shared-icons {
    padding: 25px 0 25px 0;
  }

  &__shared-icon {
    margin-right: 25px;
  }

  &__variants__wrapper:last-child {
    @media (max-width: 991px) {
      border-bottom: 1px solid #dfdfdf;
      margin-bottom: 0.5rem;
    } 
  }

  &__favourite.active {
    i:after {
      content: '\e901';
      font-family: 'icomoon';
    }
  }

  &__stock--error {
    width: 100px;
    text-align: right;
    margin-bottom: 10px;
    font-size: 12px;
    color: #818181;
  }

  &__offer--text {
    margin-bottom: 10px;
    font-size: 12px;
    color: #9d9d9d;
  }

  &__instructions--image {
    height: 18px;
  }

  &__basket-offer {
    border: 1.5px solid #f27a1a;
    border-radius: 50px;
    color: #f27a1a;
    font-size: 13px;
    padding: 5px 10px;
    display: inline-block;
  }
}
/* Product End */


/* Accordion Start */
.accordion-item{
  margin-bottom: 25px;

  &__title {
    border-bottom: solid 1px #dfdfdf;
  }

  &__content {
    color: #666666;
    font-size: 0.88rem;
    padding-top: 5px;

    &--box {
      

      p {
        list-style: disc;
        display: list-item;
        margin-bottom: 2px;
        margin-left: 18px;

      }
      .plain_text {
        list-style: none;
        margin-left: 0;
        padding-bottom: 10px;
      }
    }
  }
}
/* Accordion End */


/* Product Widget Start */
.product__widget {
  @media (min-width: 991px){

    .lSSlideWrapper {
      height: 350px;
      .js-reco-slider.lSSlide {
        height: 350px !important;
      }
    }
  }
    .product__widget__item {
      a {
        color: #000;
        display: block;
      }
      .product-item-slider-retail-price {
        margin-right: 0 !important;
      }
    }
  padding: 56px 0;
  @media (max-width: 991px) {
    padding: 32px 0;    
  }

  h3 {
    @media (max-width: 991px) {
      font-size: 24px;
      margin-bottom: 25px;
    }
  }

  &:nth-child(even){
    background-color: #f7f7f7;
  }

  &__item__img {
    width: 200px;
    margin-bottom: 15px;
  }

  &__item__name {
    width: 200px;
    margin: auto;
    margin-bottom: 15px;

    @media (max-width: 991px) {
      font-size: 13px;
    }
  }

  &__item__price {
    font-family: 'Financier Regular';
    font-size: 18px;
    font-weight: bold;

    @media (max-width: 991px) {
      font-size: 16px;
    } 
  }
}
/* Product Widget End */

.size-modal-lg {
  @media (min-width: 768px) {
    max-width: 80% !important;
  }
}

.share-button-sm {
  position: absolute;
  top: 2%;
  right: 1%;

  .social-sm-size {
    display: none;

    &__icon {
      margin-bottom: 8px;
    }
  }

  button {
    border: none;
    background: transparent;
    padding: 0;
    outline: none;
  }

  img {
    width: 20px;
  }
}

.social-mobile {
  .modal-header {
    border-bottom: none;
  }

  .close {
    outline: none;
  }

  .modal-body {
    padding: 2rem;
  }

  .modal-content {
    width: 80%;
    margin: 0 auto;
  }
}

.find-store-btn {
  font-size: 12px;
  color: #6c757d;
  display: flex;
  align-items: center;
  height: 44px;

  @media (max-width:576px){
    display: none !important;
  }
}

.js-find-in-store-two {
  border-bottom: 1px solid #dfdfdf;
  height: 29px !important;

  .find-store-sm {
    color: #000;
    font-size: 16px;
    position: relative;
    display: flex;
  }

  .accordion-item__icon:after {
    content: '+';
    position: absolute;
    top: 50%;
    right: 10px;
    font-size: 1.5rem;
    margin-top: -1rem;
    line-height: 1;
    font-style: normal;
  }

}


.installment-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-height: 65vh;
  overflow-y: auto;
  

  
  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: #666;
    border: 0px none #ffffff;
    border-radius: 50px;
  }

  &::-webkit-scrollbar-thumb:active {
    background: transparent;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border: 0px none #ffffff;
    border-radius: 50px;
  }

  &::-webkit-scrollbar-track:active {
    background: #666;
  }

  &__wrapper {
    &.active {
      .installment-content__table {
        display: block;
        margin-top: 8px;
      }
  
      .installment-content__icon:after {
        content: '-';
      }
    }

    .installment-content__icon:after {
      content: '+';
      font-size: 1rem;
      margin-top: -1rem;
      color: #000;
      line-height: 1;
      font-style: normal;
    }

  }

  &__bank-info-wrapper {
    background-color: #f2f2f2;
    padding: 10px;
    display: flex;
    justify-content: space-between;

    img {
      max-height: 28px;
      height: fit-content;
      width: auto;
    }
  }



  &__table {
    width: 100%;
    display: none;

    &-header {
      font-weight: 600;
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        font-weight: 700;
        display: block;
        width: 100px;
        white-space: nowrap;
        margin-left: 8px;
        color: #000;
        font-size: 12px;
      }
    }
  }

  &__table-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #666666;
    font-weight: 300;

    p {
      display: block;
      width: 100px;
      white-space: nowrap;
      margin-left: 8px;

      &:not(:first-child) {
        padding-left: 5px;
      }
    }

  }
}

.find-in-store-modal {
  .modal-header {
    background-color: #000;
    border-radius: 0;
  }

  .modal-body {
    padding: 1.5rem;
  }

  &.fade .modal-dialog {
    margin-top: 10%;
  }

  .find-in-store-close {
    background-color: transparent;
    border: none;
    margin: 0 0 0 auto;
    height: 15px;
    span {
      width: 15px;
      height: 2px;
      background-color: #fff;
      display: block;

      &:first-child {
        transform: rotate(45deg);
        
      }

      &:last-child {
        transform: rotate(-45deg);
        margin-top: -2px;
      }
    }
  }
}

.find-in-store {
  border: solid 1px #e1e1e1;
  padding: 20px;
  &__title {
    font-size: 18px;
    letter-spacing: -0.3px;
    color: #212529;
    margin: 0 28px 13px 0px;
  }

  &__subtitle {
    font-size: 13px;
    line-height: 1.54;
    letter-spacing: -0.3px;
    color: #6c757d;
  }

  &__filter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;

    select {
      font-size: 13px;
    }

    .select-wrapper {
      height: 40px;
    }

    .input-wrapper {
      width: 49%;
    }
  }

  &__result-title {
    display: flex;
    justify-content: space-around;
    line-height: 1.67;
    letter-spacing: -0.18px;
    color: #212529;
    font-size: 12px;
    border-top: solid 1px #e1e1e1;
    border-bottom: solid 1px #e1e1e1;
    padding: 10px 0;
    margin-bottom: 10px;

    span {
      width: 25%;
      text-align: center;
    }
  }
}

.result-list {
  border: solid 1px #d8d8d8;
  background-color: #f5f4f4;
  max-height: 300px;
  overflow-y: auto;
  
  &__item {
    display: flex;
    justify-content: space-around;
    line-height: 1.17;
    letter-spacing: -0.18px;
    text-align: center;
    color: #212529;
    font-size: 12px;
    padding: 15px;
    
    span {
      width: 25%;

      a {
        color: #212529;
      }
    }
  }
}
.combin-product-desc:after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #dfdfdf;
  margin-top: 10px;
}
.combin-name,
.combin-product-title {
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000;
}

.combin-product-item {
  img {
    width: 100%;
  }

  .combin-product-name {
    font-size: 14px;
    line-height: 1.43;
    color: #000;
  }
  .product__price  {
    font-size: 14px;
    line-height: 1.43;
    font-weight: bold;
    &--retail {
      font-size: 16px;
    }
  }
  .product__price--currency {
    font-size: 14px;
  }

  .combin-product-img {
    max-width: 65px;
    height: auto;
  }

  .js-combine-stock {
    color: #f00;
  }
  .js-combine-success, .js-combine-stock {
    display: none;
    font-size: 14px;
    line-height: 1.43;
  }

  .js-combine-success.show, .js-combine-stock.show {
    display: block;
  }

  .pz-button__add-to-cart.disabled {
    pointer-events: none;
  }
}

.combin-basket {
  &__wrapper {
    padding: 10px 0 30px;
  }

  &__add-cart-button {
    position: relative;
    display: block;
    width: 100%;
    height: 40px;
    border: none;
    background-color: #000;
    color: #fff;
    &[disabled] {
      pointer-events: none;
      opacity: .65;
      user-select: none;
    }
    &.loading {
      &:before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: inherit;
        z-index: 2;
      }
  
      &:after {
        content: '';
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 50%;
        width: $pz-spinner-size;
        height: $pz-spinner-size;
        margin-top: (($pz-spinner-size / 2) * -1);
        margin-left: (($pz-spinner-size / 2) * -1);
        border-radius: 50%;
        border: $pz-spinner-thickness solid $pz-spinner-color;
        border-top-color: transparent;
        animation: spinner $pz-spinner-speed linear infinite;
        z-index: 3;
      }
    }
  }

  &__price {
    font-size: 24px;
    font-weight: bold;
  }
}

.combin-size-buttons {

  &:focus {
    outline: none;
    box-shadow: none;
    border-color: #000;
  }

  button {
    width: 34px;
    height: 34px;
    margin: 0 9px 9px 0px;
    padding: 3px;
    border: solid 1px #dfdfdf;
    background-color: #fff;

    &.selected {
      border: solid 1px #212529;
    }

    @media (min-width: 768px) {
      width: 44px;
      height: 50px;
      margin: 0 12px 9px 0px;
    }
  }
}

.js-find-in-store-loading {
  &::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 43%;
    left: 60%;
    width: 30px;
    height: 30px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #333;
    border-top-color: transparent;
    -webkit-animation: spinner 1s linear infinite;
    animation: spinner 1s linear infinite;
    z-index: 3;
  }
}

.js-installment-loading {
  position: absolute;
  top: 45%;
  left: 50%;

  &::after {
    content: "";
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #333;
    border-top-color: transparent;
    -webkit-animation: spinner 1s linear infinite;
    animation: spinner 1s linear infinite;
    z-index: 3;
  }
}
@import 'summary';
@import 'voucher-form';
@import 'basket-list-item';
@import 'basket-list';

.basket {
  min-height: 500px;
  padding: 0 0 1rem 0;
  max-width: 1040px !important;

  &__title {
    padding-bottom: 15px;
    margin: 0;
  }

  &__continue-shopping {
    color: $pz-color-text-primary-dark;
    text-decoration: none;

    span {
      padding-bottom: 8px;
      vertical-align: middle;
      display: inline-block;
      font-size: 14px;
      margin-left: 4px;
    }

    i {
      font-size: 17px;
    }
  }

  &__continue-checkout {
    background: $pz-color-primary;
    color: $pz-color-text-primary-light;
    padding: 1rem;
    font-size: 14px;

    &:hover {
      background: $pz-color-primary-light;
      color: $pz-color-text-primary-light;
    }
  }

  .shipping_info_w {
    margin-top: 10px;
  }

  @media (max-width: 767px) {
    &__continue-shopping {
      color: #666666;
    }

    .continue-shopping-link {
      position: absolute;
      top: 10px;
      right: 15px;

      i {
        display: none;
      }
    }

    &__continue-shopping-refresh-remove {
      width: 100%;

      .refresh-link {
        width: 49%;
        display: inline-block;
      }

      .remove-all-link {
        width: 49%;
        text-align: right;
        margin-left: 0 !important;
        display: inline-block;
      }
    }

    &__continue-checkout-container {
      left: 0;
      bottom: 0;
      padding: 15px;
      width: 100%;
      z-index: 100;
      position: fixed;
      background-color: #f5f5f5;
    }
  }
  
  &.force{
    height: unset !important;
  }
}

.basket-upsell-message{
  border: solid 1px #000000;
  display: inline-block;
  padding: 5px;
  p {
    margin: 0 !important;
  }
}

.hide-upsell-message {
  display: none;
}

.advantages {
  padding: 54px 0 145px 0;

  .title {
    color: #000000;
  }
}
.collection{
  overflow-x: hidden;
}
.collection-page{
  width:100%;
  font-size: 1vw;

  .collection-page-product {
    width:100%;
    &-img{
      width:100%;
      img{
        width:100%;
      }
    }
  }

  &__wrapper{
    width:100%;
    padding:0 35px;

    @media (max-width: 1024px) {
      padding: 0 10px;
    }

  }
  
  &__title{
    margin:15px 0;
    width:100%;
    text-align: center;
    background: #1d1d1b;
    font-size: 3.35em;
    color: #fff;
    line-height: 5.8em;
    font-weight: 700;

    @media (max-width: 1024px) {
      margin: 10px 0;
    }

    @media (max-width: 500px) {
      font-size:20px;
      line-height: 4;
    }
  }
  
  &__step1{
    padding:15px 0;
    width:100%;

    @media (max-width: 1024px) {
      padding: 10px 0;
    }

    .cp-title{
      font-size: 20px;
      color: #1d1d1b;
      letter-spacing: -0.1px;
      width:100%;
      text-align: center;
      padding: 30px 0 0 0;
      line-height: 1;
    }

    .cp-price{
      width:100%;
      text-align: center;
      margin: 15px 0 0 0;

      .cp-price-regular{
        font-size: 20px;
        color: #818181;
        text-decoration: line-through;
        display: inline-block;
        vertical-align: top;
        margin: 0 15px;
        line-height: 1;
      }

      .cp-price-discount{
        font-size: 20px;
        color: #1d1d1b;
        display: inline-block;
        vertical-align: top;
        margin: 0 15px;
        line-height: 1;
      }
    }
  }

  &__step2{
    padding:25px 0;
    width:100%;

    @media (max-width: 1024px) {
      padding: 15px 0;
    }

    ul{
      padding:0;
      margin: 0 -15px;
      display:flex;

      @media (max-width: 1024px) {
        margin: 0 -10px;
      }

      li{
        width:50%;
        padding:0 15px;

        @media (max-width: 1024px) {
          padding: 0 10px;
        }
      }
    }

    .cp-title{
      font-size: 25px;
      color: #1d1d1b;
      letter-spacing: -0.1px;
      width:100%;
      text-align: center;
      padding: 30px 0 0 0;
      line-height: 1;
    }

    .cp-price{
      width:100%;
      text-align: center;
      margin: 20px 0 0 0;

      .cp-price-regular{
        font-size: 25px;
        color: #818181;
        text-decoration: line-through;
        display: inline-block;
        vertical-align: top;
        margin: 0 15px;
        line-height: 1;
      }

      .cp-price-discount{
        font-size: 25px;
        color: #1d1d1b;
        display: inline-block;
        vertical-align: top;
        margin: 0 15px;
        line-height: 1;
      }
    }
  }

  &__step3{
    padding:25px 0;
    width:100%;

    @media (max-width: 1024px) {
      padding: 15px 0;
    }

    .cp-title{
      font-size: 20px;
      color: #1d1d1b;
      letter-spacing: -0.1px;
      width:100%;
      text-align: center;
      padding: 30px 0 0 0;
      line-height: 1;
    }

    .cp-price{
      width:100%;
      text-align: center;
      margin: 15px 0 0 0;

      .cp-price-regular{
        font-size: 20px;
        color: #818181;
        text-decoration: line-through;
        display: inline-block;
        vertical-align: top;
        margin: 0 15px;
        line-height: 1;
      }

      .cp-price-discount{
        font-size: 20px;
        color: #1d1d1b;
        display: inline-block;
        vertical-align: top;
        margin: 0 15px;
        line-height: 1;
      }
    }
  }

  &__step4{
    padding:25px 0;
    width:100%;
    overflow:hidden;

    .lSSlideOuter{
      margin:0 -5.05%;

      @media (max-width: 1024px) {
        margin:0 -14%;
      }
    }
    
    .collection-product-slider {
      height: unset !important;
      display: flex;
      padding-left: 9.5rem;
      padding-right: 9.5rem;

      .non-slider-item{
        margin-right: 40px;
        width: 370px;

        @media (max-width: 768.9px) {
          margin-right: unset;
          width: unset;
          margin-bottom: 10px;
        }
      }

      &.non-slider-item-two{
        @media (max-width: 768.9px) {
          flex-direction: column;
          display: flex;
          padding-left: 1rem !important;
          padding-right: 1rem !important;
        }
      }

      @media (max-width: 1024.9px) {
        padding-left: 17.5rem;
        padding-right: 17.5rem;
      }
      @media (max-width: 768.9px) {
        padding-left: 12.5rem;
        padding-right: 12.5rem;
      }
      @media (max-width: 425.9px) {
        padding-left: 7.5rem;
        padding-right: 7.5rem;
      }
      @media (max-width: 375.9px) {
        padding-left: 7rem;
        padding-right: 7rem;
      }
      @media (max-width: 320.9px) {
        padding-left: 6.5rem;
        padding-right: 6.5rem;
      }


    }


    .cp-title{
      font-size: 20px;
      color: #1d1d1b;
      letter-spacing: -0.1px;
      width:100%;
      text-align: center;
      padding: 30px 0 0 0;
      line-height: 1;
    }

    .cp-price{
      width:100%;
      text-align: center;
      margin: 15px 0 0 0;

      .cp-price-regular{
        font-size: 20px;
        color: #818181;
        text-decoration: line-through;
        display: inline-block;
        vertical-align: top;
        margin: 0 15px;
        line-height: 1;
      }

      .cp-price-discount{
        font-size: 20px;
        color: #1d1d1b;
        display: inline-block;
        vertical-align: top;
        margin: 0 15px;
        line-height: 1;
      }
    }
  }

  &__step5{
    padding:25px 0;
    width:100%;

    @media (max-width: 1024px) {
      padding: 15px 0;
    }

    .cp-title{
      font-size: 20px;
      color: #1d1d1b;
      letter-spacing: -0.1px;
      width:100%;
      text-align: center;
      padding: 30px 0 0 0;
      line-height: 1;
    }

    .cp-price{
      width:100%;
      text-align: center;
      margin: 15px 0 0 0;

      .cp-price-regular{
        font-size: 20px;
        color: #818181;
        text-decoration: line-through;
        display: inline-block;
        vertical-align: top;
        margin: 0 15px;
        line-height: 1;
      }

      .cp-price-discount{
        font-size: 20px;
        color: #1d1d1b;
        display: inline-block;
        vertical-align: top;
        margin: 0 15px;
        line-height: 1;
      }
    }
  }

  &__step6{
    padding:25px 0;
    width:100%;

    @media (max-width: 1024px) {
      padding: 15px 0;
    }

    ul{
      padding:0;
      margin: 0 -15px;
      display:flex;

      li{
        width:33.3333333%;
        padding:0 15px;
      }
    }


    .cp-title{
      font-size: 20px;
      color: #1d1d1b;
      letter-spacing: -0.1px;
      width:100%;
      text-align: center;
      padding: 30px 0 0 0;
      line-height: 1;
    }

    .cp-price{
      width:100%;
      text-align: center;
      margin: 15px 0 0 0;

      .cp-price-regular{
        font-size: 20px;
        color: #818181;
        text-decoration: line-through;
        display: inline-block;
        vertical-align: top;
        margin: 0 15px;
        line-height: 1;
      }

      .cp-price-discount{
        font-size: 20px;
        color: #1d1d1b;
        display: inline-block;
        vertical-align: top;
        margin: 0 15px;
        line-height: 1;
      }
    }
  }

  &__step7{
    font-size: 14ox;
    line-height: 22px;
    color:#000;
    padding:25px 0;
    width:100%;
    border-top: 1px solid #dfdfdf;
    margin-top: 150px;

    @media (max-width: 1024px) {
      padding: 15px 0;
      margin: 80px 0 0 0;
    }
  }
  @media (max-width: 1024px) {
    .cp-title{
      font-size:15px !important;
      padding-top: 15px !important;
    }
    .cp-price{
      margin:10px 0 0 0 !important
    }
    .cp-price-regular{
      font-size:15px !important;
      margin:0 5px !important;
    }
    .cp-price-discount{
      font-size:15px !important;
      margin:0 5px !important;
    }
  }
}
.basket-gift-package{
	margin-bottom: 1.5rem;
	&__checkbox{
		display: none;
		&:checked {
			& + label {
				span{
					background-color: #000;
					border-color: #000;
				}
				& + .basket-gift-package__content{
					display: block;
				}
			}
		}
		&-placeholder{
			width: 20px;
			height: 20px;
			border-radius: 20px;
			border: 1px solid #7e7e7e;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #fff;
		}
	}
	label{
		display: flex;
		align-items: center;
		gap: 5px;
		user-select: none;
		cursor: pointer;
		font-weight: 600;
	}
	&__content{
		padding-left: 26px;
		display: none;
		font-size: 14px;
    color: #555;
	}
}
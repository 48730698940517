.store-popup{
  margin-bottom: .5rem;
  padding: 12px 0;
  box-shadow: 0 0 10px -3px rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  display: none;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  height: 70px;

  &.active{
    display: flex;
  }

  img{
    margin-left: 0;
    margin-right: 10px;
  }

  div{
    margin-left: 0;
    margin-right: auto;
  }
  
  .store-title{
    display: block;
    font-size: 12px;
    font-weight: bold;
  }

  .store-desc{
    display: block;
    font-size: 10px;
    color: #8e8e8e;
  }

  .store-button{
    background-color: #042147;
    padding: 7px 20px;
    margin-right: 14px;
    color:#fff;
    letter-spacing: 1px;
    font-size: 11px;
    width:71px;
    height:33px;
  }

  .star-icons {
    display:flex;
    
    img {
      margin-right:5px;
    }

  }

  .close-icon{
    padding: 10px 15px;
    color: #8e8e8e;
  }
}
.blog-list {
  .title {
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 3px;
    margin-bottom: 60px;

    @media (max-width: 767px) {
      margin-bottom: 30px;
    }
  }

  h3 {
    @media (max-width: 767px) {
      margin-top: 5px;
    }
  }
  a {
    text-decoration: none;
    color: #000;
  }

  &__button {
    border-bottom: 2px solid #000;
    margin-bottom: 10px;
  }

  .list-content {
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      height: 100px;
    }
  }

  .load-more {
    background-color: #000;
    color: #fff;
    text-transform: uppercase;
  }

  .js-blog-list {
    display: none;
  }
}

.basket-reco-item {
    height: 432px;

    &__img {
        aspect-ratio: 2/3;
    }

    &__name {
        font-size: 12px;
        text-align: center;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 3em;
        line-height: 1.5em;
    }

    &__price {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .btn {
        background-color: #fff;
        color: #000;
        font-size: 12px;
        border: 1px solid #000;
        padding: 8px 0;
        margin-bottom: 5px;
        max-width: 100%;
    }

    &__controls {
        font-size: 30px;
        font-weight: bold;
        color: black;
    }

    .text-ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        height: 18px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .product-item-campaign {
        text-align: center;
        gap: 2px;
    }

    .product-item-campaign-price {
        font-size: 12px;
        margin-left: 0;
        min-width: 75px;
    }

    &__list {
       gap: 8px;
       margin: 8px 0;
    }
}

.basket-reco {
    .lSSlideOuter {
        height: 460px !important;
    }

    &__title {
        text-align: center;
        font-size: 18px;
        margin: 20px 0;
        font-weight: 600;
    }

    .lSAction, .lSPrev, .lSNext {
        background: none;
    }
}
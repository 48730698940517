.bwt1 {
  position: relative;

  &-text {
    padding: 3rem;

    @media (min-width: 992px) {
      padding: 0;
      position: absolute;
      top: 10%;
      max-width: 380px;
      left: 10%;
    }
  }
}

.bwt2 {
  @media (min-width: 768px) {
    &-content {
      padding-left: 80px;
    }

    &-text {
      border: 1px solid #dee2e6;
      margin-right: -40px;
      background-color: white;
    }
  }
}

.bwt3 {
  &-img-left {
    max-width: 450px;
  }

  &-text {
    @media (min-width: 768px) {
      max-width: 560px;
      position: absolute;
      top: 20%;
      left: 20%;
      background-color: white;
      border: 1px solid #dee2e6;
    }
  }
}

.bwt4 {
  &-text {
    @media (min-width: 768px) {
      max-width: 560px;
      position: absolute;
      background-color: white;
      border: 1px solid #dee2e6;
    }
  }
}

.bwt5 {
  &-text {
    @media (min-width: 768px) {
      margin-top: -30px;
      background-color: white;
      border: 1px solid #dee2e6;
    }
  }
}

.bwt6 {
  &-text {
    max-width: 370px;
  }
}

.bwt7 {
  &-text {
    max-width: 690px;
  }
}

.bwt8 {
  @media (max-width: 768px) {
    padding: 0px;
  }

  &-text {
    max-width: 370px;
  }
}

.bwt10 {
  a {
    width: 300px;
    background-color: black;
    color: white;
  }
}

.bwt11 {
  @media (max-width: 768px) {
    padding: 0px;
  }

  &-collections {
    overflow-x: scroll;
  }
}

.blog {

  &-page {
    .flatpage {
      @media (max-width: 768px) {
        margin: 0;
      }
    }
  }

  &__banner {
    &--bottom {
      display: flex;
      justify-content: center;
      border-bottom: 1px solid #ccc;
      margin-bottom: 20px;
    }
  }

  &__landing {

    &--header {
      display: block;
      position: relative;
    }

    &--date {
      color: #000;
      font-size: 16px;
      font-weight: 600;
    }

    &--text {
      margin-top: 25px;
    }

    &--head {
      a {
        color: #000;
        font-size: 18px;
        font-family: "FinancierText";
      }
    }

    &--content {
      color: #9d9d9d;
      font-size: 13px;
      line-height: normal;
    }

    &--more {
      display: block;
      margin-top: 15px;

      a {
        color: #000000;
        font-size: 13px;
        font-weight: 500;
        display: inline-block;
        text-decoration: underline !important;
      }
    }
  }

  &__pagination {
    position: relative;
    display: inline-flex;
    background: #fff;
    margin-bottom: -9px;

    a {
      color: #202020;
      font-size: 17px;
      padding: 8px;
    }

    .pages {
      font-size: 17px;
      line-height: 21px;
      letter-spacing: .85px;

      .seperator {
        font-size: 15px;
      }
    }
  }
}

.card-blog {
  display: none;
  margin-bottom: 30px;

  &__wrapper {
    padding: 25px;
    border: solid 1px #dfdfdf;
  }

  &.active {
    display: flex;
    flex-direction: column;
  }
}

.blog-content {
  &__title {
    position: relative;
    background-color: #fff;
    text-align: center;
    padding: 20px;
    margin: -40px 20px 15px 20px;

    h1 {
      font-size: 21px;
      letter-spacing: 1px;
      text-align: center;
      color: #202020;
      font-weight: 600;
      margin: 15px 0;
    }
  }

  &__date {
    font-size: 13px;
    color: #202020;
    font-weight: normal;
  }

  &__post {
    padding: 5px;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: .7px;
    color: #202020;
  }
}


.blog-sidebar {
  &__item {
    margin-bottom: 50px;
  }

  &__item-text {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: .7px;
    font-weight: 300;

    h2 {
      position: relative;
      background-color: #fff;
      margin: -50px 10px 10px 10px;
      padding: 10px 15px;
    }
  }

  &__item-button {
    display: inline-block;
    color: #202020;
    font-size: 14px;
    font-weight: 500;
    border-bottom: 1px solid #202020;
    margin-top: 20px;
  }
}

.blog {
  &__sidebar {
    &:after {
      content: "";
      top: -40px;
      width: 1px;
      right: 35px;
      height: 85%;
      position: absolute;
      background-color: #dfdfdf;
    }

    @media (max-width: 768px) {
      &:after {
        display: none;
      }
    }
  }

  &__sidebar-card {
    margin-bottom: 30px;
  }

  &__sidebar-card-header {
    display: flex;

    @media (max-width: 768px) {
      margin: 0 -15px;
      background-color: #000000;
    }
  }

  &__sidebar-card-title {
    display: flex;
    color: #000000;
    font-size: 24px;
    align-items: center;
    padding: 5px 10px 5px 0;
    font-family: "FinancierText";
    @media (max-width: 768px) {
      flex: 1;
      padding: 15px;
      color: #ffffff;
      font-size: 14px;
      font-family: "Plain Regular";
      justify-content: space-between;

      i {
        font-weight: bold;
        transform: rotate(90deg);
      }
    }
  }

  &__sidebar-card-body {
    li {
      display: block;
      padding: 15px 15px 0 0;

      &:last-child {
        border-bottom: 0;
      }

      a {
        display: flex;
        justify-content: space-between;
        font-size: 13px;
        color: #666666;

        &:hover {
          color: #000000;
        }

        &.active {
          color: #000000;
          text-decoration: underline !important;
        }

        .post-count {
          font-weight: bold;
        }
      }
    }
  }
}
@import '../components/address-form/index.scss';

.account__address__wrapper {
  .address__box {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    border-bottom: solid 1px #dfdfdf;
    padding-bottom: 25px;
    margin-bottom: 15px;

    &:hover {
      border-color: #dfdfdf;
    }

    &:last-child {
      border-bottom: 0 none;
    }
  }

  .address-box__title {
    margin-bottom: 8px;
    color: #000;
    font-weight: 500;
  }

  .address-box__address {
    max-width: 388px;
  }

  .address-box__content {
    order: 1;
    flex-basis: 100%;
    margin-bottom: 25px;
    color: #818181;
    font-size: 14px;
  }

  .address-box__radio-wrapper {
    order: 2;
    flex-basis: 50%;
  }

  .address-box__action-wrapper {
    order: 3;
    flex-basis: 50%;
  }

  .address__aciton--link {
    font-size: 14px;

    i {
      margin-right: 10px;
    }

    &:hover {
      text-decoration: none !important;
    }
  }

  .account__address-form__wrapper[data-address-content="new-address"]{
    .address-form__new-address-title {display: block;}
    .address-form__update-address-title {display: none;}
  }

  .account__address-form__wrapper[data-address-content="update-address"]{
    .address-form__new-address-title {display: none;}
    .address-form__update-address-title {display: block;}
  }

  .new-address-text {
    cursor: pointer;
    line-height: 1;
    margin-left: 10px;
    font-size: 14px;
  }

  .back-address-link {
    font-size: 14px;
    color: #000;
    display: block;
    line-height: 1;
    margin: 32px 0;

    i {
      display: inline-block;
      font-size: 17px;
    }

    span {
      display: inline-block;
      vertical-align: top;
      line-height: 1;
    }
  }
}
.blog-reco {
  h3 {
    font-weight: bold;
    font-size: 35px;
  }

  h4 {
    font-weight: 500;
    text-transform: uppercase;
  }

  a {
    color: #000;
  }
}
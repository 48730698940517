.account__contact__company-info {
  color: #c9c9c9;

  a{
    color: #000000;
  }
}

.success_modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
  cursor: pointer;



  .modal{


    &-wrapper{
      left: 50%;
      top: 50%;
      width: 30%;
      padding: 20px;
      margin: auto 0;
      position: fixed;
      background-color: #ffffff;
      transform: translate(-50%, -50%);




      i{
        top: 10px;
        right: 10px;
        position: absolute;
      }

      @media (max-width:768px){
        width:70%;
      }
    }
    &-head{
      margin: 0 -20px;
    }
  }
}
.account__contact--form-wrapper {
	@media (max-width: 991px){
		margin-bottom: 25px;
	}
}

.contact-form-information {
  font-size: 14px;
  margin-top: 15px;
}

.contact-choice-wrapper{
  .input-wrapper{
    position: relative;
    padding-bottom: 10px;
    #choice-error{
      position: absolute;
      left: 0px;
      top: 30px;
      width: 180px;
    }
  }
}
.footer {
  background: #ffffff;
  padding: 25px 25px 25px 25px;
  border-top: 1px solid #e1e1e1;
  max-width: 1440px;
  margin: auto;

  @media only screen and (max-width: 991px){
    padding: 20px 15px 30px 15px;
  }

  @media only screen and (max-width: 767px){
    padding-bottom: 116px;
  }
  @media only screen and (max-width: 567px){
    padding-bottom: 0;
  }

  &__mobile__app {
    width: 100%;
    &--images {
      justify-content: space-between;
      margin-top: 15px;
      
      @media only screen and (max-width: 991px){
        justify-content: space-evenly;
      }

      img {
        width: 160px;
        height: 45px;
        border-radius: 12px;

        @media only screen and (max-width: 991px){
          width: 130px;
          height: auto;
        }
      }
    }
    span {
      font-size: 30px;
      font-family: "Plain Light";
      font-weight: 200;
      line-height: 1.28;
      text-align: left;

      @media only screen and (max-width: 991px){
        text-align: center;
        font-size: 24px;
      }
    }
  }

  nav {
    @media only screen and (max-width: 991px) {
      padding-left: 0;
      padding-top: 30px;
    }
  }

  .subscribe {
    &__box {
      width: 25%;
      @media only screen and (max-width: 991px) {
        width: 100%;
      }

      .social-title {
        font-size: 30px;
        font-family: "Plain Light";
        font-weight: 200;
        margin-bottom: 15px;

        @media only screen and (max-width: 991px) {
          font-size: 24px;
        }
      }

      form {
        input {
          &[type="email"] {
            width: 295px;
            height: 44px;
            border: none;
            padding: 0 10px;
            background-color: #ffffff;
            border-bottom: 1px solid #000000;

            &::placeholder {
              font-size: 14px;
              color: #818181;
            }
          }
        }

        button {
          width: 94px;
          border: none;
          height: 44px;
          color: #ffffff;
          position: relative;
          background-color: #000000;
        }
      }

      @media only screen and (max-width: 991px) {
        padding-left: 0;
      }
    }

    &__text {
      color: #000;
      padding: 20px 0 15px;
      font-size: 14px;
      line-height: 1.57;
      letter-spacing: normal;
      font-weight: 200;
      font-family: "Plain Light";

      p {
        font-size: 30px;
      }
    }
  }

  &__menu {
    justify-content: space-evenly;
    &__content {
      @media only screen and (max-width: 991px) {
        align-items: center;
        display: flex;
        flex-direction: column;
      }
      
    }

    &__title {
      display: flex;
      color: #000000;
      font-size: 14px;
      align-items: center;
      margin-bottom: 24px;
      font-family: "Plain Bold";
      justify-content: space-between;

      .icon-chevron_top {
        display: none;
      }

      &.active {
        .icon-chevron_top {
          display: block;
        }

        .icon-chevron_down {
          display: none;
        }
      }

      @media only screen and (max-width: 991px) {
        + .footer__menu_subtitle {
          display: none;
        }

        &.active {
          margin-bottom: 0;
          + .footer__menu_subtitle {
            display: block;
          }
        }
      }
    }

    &_subtitle {
      li {
        display: block;
        color: #666666;
        font-size: 14px;
        font-weight: 500;
        text-align: left;
        line-height: 1.57;
        padding-bottom: 5px;
        font-family: "Plain Regular";

        a {
          display: block;
          color: #666666;
          font-size: 14px;
          font-weight: 300;
          line-height: 1.57;
          font-family: "Plain Light";
        }
      }

      &.support__menu {
        li {
          padding-bottom: 15px;
        }
      }
    }
  }

  .social_box {
    a {
      color: #000000;
      font-size: 34px;
      padding-right: 15px;
      position: relative;
      margin-right: 35px;
    }
    img {
      width: 54px;
      top: -7px;
      position: absolute;
    }
  }

  &__lang {
    span {
      color: #666666;
      font-size: 14px;
      padding-right: 25px;
    }

    a {
      color: #666666;
      font-size: 14px;
      line-height: 1.57;
      padding-right: 15px;
      text-decoration: underline !important;

      &.active {
        color: #000000;
      }
    }
  }

  &__copyright {
    color: #666666;
    font-size: 14px;
    @media only screen and (max-width: 567px){
      text-align: center;
      margin-top: 80px;
    }
  }

  .customer__experience {
    &-text {
      line-height: 1.57;
      letter-spacing: normal;
      font-weight: 200;
      font-family: "Plain Light";
      font-size: 21px;
      color: #000;
      margin-bottom: 10px;
    }

    p {
      font-size: 16px;
      margin-bottom: 0;    
    }
    span {
      color: #0a4ac9;
      text-decoration: underline;
    }
  }

}

.footer ul {
  padding-inline-start: 0;
}
.auth-page {
  &__title {
    text-align: center;
    padding: 0 30px;

    h1 {
      font-size: 24px;
    }

    @media (min-width: 768px) {
      padding: 0;
    }
  }

  .auth-btn{
    background-color: #0b2240;
  }

  .muted-text {
    font-size: 14px;
    color: #9d9d9d;
  }

  label.error {
    font-size: 14px;
    color: #d72b01;
  }

  .form-control {
    padding: 26px 10px;
    font-size: 14px;
    color: #000000;
    border-radius: 0px;
    box-shadow: none;

    &.error {
      border-color: #d72b01;
    }
  }

  .form-control:hover, .form-control:focus {
    border: 1px solid #3e5071;
  }

  .link {
    font-size: 14px;
    color: #000000;
    text-decoration: underline !important;
  }

  @media (min-width: 768px) {
    max-width: 410px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .form-group--password {
    position: relative;
  }

  .password-display {
    position: absolute;
    right: 10px;
    top: 18px;
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
  }

  &__login-choice {
    width: 100%;
    display: flex;
    margin-bottom: 10px;

    .choose {
      width:50%;
      border: 1px solid #000;
      height: 52px;
      line-height: 50px;
      text-align: center;
    }

    .gsm {
      border-left:none;
      color: white;
      background-color: #000;

      a {
        color: white;
      }
    }
  }

  .form-notification {
    display: flex;
    flex-direction: column;

    &__checkbox {
      display: flex;

      .form-group {
        margin-right: 25px;
      }
    }
  }
}


.modal-container {
  .modal-content {
    .close {
      text-align: right;
      margin-right: 33px;
      margin-top: 15px;
    }
  }

  .modal-header {
    justify-content: center;
    border-bottom: none;

    h5 {
      font-size: 35px;
    }
  }

  .modal-body {
    padding-left: 33px;
    padding-right: 33px;
    .modal__text {
      font-size: 14px;
      color: #9d9d9d;
    }

    input {
      width: 100%;
      padding: 13px;
      border: 1px solid #c9c9c9;
      outline: none;

      &::placeholder {
        color: #9d9d9d;
      }
    }

    .account__form-group--offset {
      margin-top: 15px;
    }

    .modal__text {
      a {
        font-size: 14px;
        color: #9d9d9d;
        margin-top: 15px;
        display: block;
      }
    }
  }
}

.register-timer {
  font-size: 18px;
  margin-left: 30px;
  color: black;
  font-weight: 600;

  @media (max-width: 768px) {
    display: block;
    text-align: center;
    margin-left: 0;
  }
}

.register-sms-resend {
  cursor: pointer;
  font-size: 15px !important;
  display: inline-block !important;
}

.akifast-wrapper {
  .belt {
    position: absolute;
    top: 25%;
    left: 0;
    background-color: #fff;
    border-radius: 18px;
    padding: 6px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    z-index: 1;
    &__button {
      width: 12px;
      height: 12px;
      border-radius: 12px;
      background-color: #d02c2f;
    }
  }
  .btn:disabled {
    opacity: .4;
  }
  .form-group {
    width: 100%;
    label {
      display: flex;
      font-size: 12px;
      input[type=checkbox]::before,
      input[type=checkbox]::after {
        display: none;
      }
      a {
        font-size: 12px;
        text-decoration: underline !important;
      }
    }
  }
  p {
    font-size: 12px;
  }
}
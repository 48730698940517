@import './variables';

.pz-button {
  &__add-to-cart {
    color: $add-to-cart-color;
    background-color: $add-to-cart-background;
    border-width: $add-to-cart-border-width;
    border-style: $add-to-cart-border-style;
    border-color: $add-to-cart-border-color;
    border-radius: $add-to-cart-border-radius;

    @media (max-width: 761px) {
      max-width: 66%;
    }

    &:hover {
      color: $add-to-cart-color-hover;
      background-color: $add-to-cart-background-hover;
      border-color: $add-to-cart-border-color-hover;
    }
  }
}


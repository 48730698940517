.slider {
  overflow: hidden;
  .lSNext,
  .lSPrev {
    background: none;

    i {
      color: #000;
      font-size: 32px;
    }
  }

  .loader {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #2a2e31;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    position: absolute;
    left: 50%;
    top: 50%;
  }

  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .lSPager.lSPager {
    left: 0;
    right: 0;
    bottom: 30px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;

    li {
      a {
        opacity: 0.5;
        background-color: #ffffff;
      }

      &.active {
        a {
          opacity: 1;
          background-color: #ffffff;
        }
      }

      &:hover {
        a {
          opacity: 1;
          background-color: #ffffff;
        }
      }
    }
    @media only screen and (max-width: 768px){
      bottom: 15px;
    }
  }

  &__item{
    position: relative;
    max-width: 1440px;
    width: 100vw;
    img{
      width: 100% !important;
      transition: transform 300ms ease-out;
      &:hover {
        transform: scale(1.05);
      }
    }
    &-content{
      top:0;
      left:0;
      right:0;
      bottom: 0;
      margin: auto;
      max-width: 600px;
      position:absolute;
      max-height: 190px;
      text-align: center;
      h2{
        color:#ffffff;
        font-size: 64px;
        font-weight: 500;
        line-height: 1.09;
        margin-bottom: 40px;
        @media only screen and (max-width: 768px){
          font-size: 30px;
          margin-bottom: 20px;
        }
      }
      a {
        background-color: #fff;
        color: #000;
        border-radius: 2px;
        padding: 5px 10px;
        font-weight: 600;
        font-size: 18px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 7px;
        @media only screen and (max-width: 768px){
          font-size: 13px;
          padding: 2px 6px;
        }
      }
      
      &-context {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
      }

      @media only screen and (max-width: 768px){
        max-width: 250px;
        max-height: 100px;
      }
    }
  }
}

.three__box {
  display: flex;
  margin-top: 25px;
  justify-content: space-between;
  overflow: hidden;

  &-item {
    position: relative;
    width: calc(33% - 12.5px);
    img{
      width: 100%;
      aspect-ratio: 1 / 1;
      @media only screen and (max-width: 768px){
        aspect-ratio: 360 / 168;
      }
      transition: transform 300ms ease-out;
      &:hover {
        transform: scale(1.05);
      }
    }

    @media only screen and (max-width: 768px){
      width: 100%;
      height:180px;
      overflow: hidden;
      &:not(:first-child){
        margin-top: 15px;
      }
    }
    @media only screen and (max-width: 768px){
      width: 100%;
    }
  }

  &-desc{
    top: 47%;
    left: 7.5%;
    width: 85%;
    margin: auto;
    color: #000000;
    padding: 0 10px;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    position: absolute;
    background-color: #ffffff;
  }
  &-foot{
    left:0;
    right:0;
    bottom: 30px;
    margin: 0 auto;
    color: #ffffff;
    padding: 0 10px;
    font-size: 18px;
    font-weight: 500;
    max-width: 380px;
    max-height: 41px;
    position: absolute;
    width: max-content;
    background-color: #000000;
    @media only screen and (max-width: 768px){
      bottom:20px;
    }
  }
  @media only screen and (max-width: 768px){
    padding: 0 15px;
    text-align: center;
    flex-direction: column;
    justify-content: center;
  }
}

.four__box {
  display: flex;
  padding: 0 25px;
  margin-top: 25px;
  justify-content: space-between;
  overflow: hidden;
  &-item {
    position: relative;
    width: calc(25% - 12.5px);
    img{
      width:100%;
      transition: transform 300ms ease-out;
      &:hover {
        transform: scale(1.05);
      }
    }
    @media only screen and (max-width: 768px){
      width: 100%;
      margin-right:15px;
      img{
        width:auto;
      }
    }
  }

  &-desc{
    position: absolute;
    left: 50%;
    bottom: 30px;
    max-height: 41px;
    padding: {
      right: 10px;
      left: 10px;
    }
    color: #000000;
    font-size: 18px;
    font-weight: 500;
    background-color: #ffffff;
    transform: translateX(-50%);
  }

  @media only screen and (max-width: 768px){
    padding: 0 15px;
    overflow-x: scroll;

    &-desc{
      width: 50%;
      max-height: none;
      text-align: center;
    }
  }
}

.banner {
  padding: 0 25px;
  margin-top: 25px;
  position: relative;
  overflow: hidden;
  &__item{
    img{
      width: 100%;
      transition: transform 300ms ease-out;
      &:hover {
        transform: scale(1.05);
      }
    }
  }

  &__desc {
    top: 0;
    bottom: 0;
    left:150px;
    margin: auto;
    max-width: 500px;
    text-align:center;
    max-height: 290px;
    position: absolute;
    width: max-content;

    h1 {
      color:#ffffff;
      font-size: 64px;
      font-weight: 600;
      line-height: 1.11;
      font-family: "FinancierText"
    }
    h4 {
      color:#ffffff;
      font-size:20px;
      font-family: "Plain Regular";
    }
    span {
      width:240px;
      color: #000000;
      cursor:pointer;
      padding: 15px 0;
      margin-top:45px;
      font-size: 20px;
      text-align:center;
      line-height: normal;
      display: inline-block;
      background-color: #ffffff;

      i {
        padding-left:15px;
        vertical-align:bottom;
      }
    }

    @media only screen and (max-width: 768px){
      left:0;
      right:0;
      max-width: 300px;
      max-height: 220px;

      h1 {
        font-size: 38px;
      }
      h4 {
        font-size: 12px;
      }
      span {
        font-size: 12px;
        i{
          padding-left:15px;
          vertical-align:bottom;
        }
      }
    }
  }
  @media only screen and (max-width: 768px){
    padding:0;
    overflow: hidden;
  }
}

.two__box {
  display: flex;
  padding: 0 25px;
  margin-top: 25px;
  flex-wrap: wrap;
  
  &-item{
    position: relative;
    width: calc(50% - 12.5px);
    margin-bottom: 25px;
    overflow: hidden;
    &:nth-child(2n) {
      margin-left: 25px;
      @media only screen and (max-width: 768px){
        margin-left: 0;
      }
    }
    img{
      width: 100%;
      transition: transform 300ms ease-out;
      &:hover {
        transform: scale(1.05);
      }
    }
    @media only screen and (max-width: 768px){
      margin-bottom: 0;
      width: 100%;
      &:not(:first-child){
        margin-top:15px;
      }
      img{
        width: 100%;
      }
    }
  }

  &-desc{
    left:0;
    right:0;
    top: 15%;
    margin: 0 auto;
    max-width:400px;
    position:absolute;
    width: max-content;
    text-align: center;

    h3{
      color: #ec0f0f;
      font-size: 54px;
      font-weight: 500;
      line-height: 1.33;
      font-family: "Plain Regular";
    }

    p{
      color: #ec0f0f;
      font-size: 38px;
      font-weight: 500;
      margin:15px 0 0 0;
      line-height: normal;
      font-family: "FinancierText";
    }
    @media only screen and (max-width: 768px){
      max-width:265px;
      h3{
        font-size: 28px;
      }

      p{
        font-size: 18px;
      }
    }
  }

  @media only screen and (max-width: 768px){
    flex-direction: column;
    padding: 0 15px;
  }
}

.brand {
  margin-top: 100px;

  &__box {
    display: flex;
    padding: 0 25px;
    margin-top: 20px;
    justify-content: space-between;
    overflow: hidden;
    &-item {
      position: relative;
      width: calc(50% - 12.5px);

      img{
        width: 100%;
      }
      @media only screen and (max-width: 768px){
        width: 100%;

        &:last-child{
          margin-top: 20px;
        }
      }
    }

    &-title{
      span{
        display:block;
        color: #818181;
        padding: 10px 0;
        font-size: 14px;
      }
      h4{
        color: #000000;
        font-size: 26px;
        margin-bottom:0;
        font-family: "Plain Regular";
      }
      p{
        color: #000000;
        font-size: 18px;
        margin-top: 8px;
        font-family: FinancierText;
      }
      @media only screen and (max-width: 768px){
        padding:0 15px;
      }
    }
    @media only screen and (max-width: 768px){
      padding:0;
      flex-direction: column;
    }
  }

  h2 {
    color: #000000;
    font-size: 38px;
    text-align: center;
    font-family: "Plain Regular";
  }

  @media only screen and (max-width: 768px){
    margin-top: 35px;
    h2 {
      font-size: 19px;
    }
  }
}

.advantages-home {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: solid 1px #dfdfdf;

  a {
    color: #000000;

    &:after {
      content: "";
      left: 0;
      width: 1px;
      top: -96px;
      height: 42px;
      position: absolute;
      background-color: #dfdfdf;
    }
  }

  .advantage {
    width:190px;
    display: flex;
    min-height: 150px;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    i{
      font-size: 62px;
    }

    span{
      margin-top:20px;
      text-align: center;
    }
  }

  @media only screen and (max-width: 1199px){
    flex-wrap: wrap;
    text-align: center;
    justify-content: space-evenly;
    padding: 10px 0;

    a{
      width: 30%;

      &:after{
        display: none;
      }
    }

    .title {
      width: 100%;
      max-width: none;
      font-size: 22px;
      padding: {
        right: 55px;
        left: 55px;
      }
      margin-bottom: 20px;
    }

    .advantage{
      width: 100%;
      margin-top: 20px;

      span {
        margin-top:30px;
      }
    }
  }
}

.shipping_info_w {
  font-size: 12px;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  &-text {
    color: #9d9d9d;
  }
}

.free-cargo {
  padding: 5px;
  background-color: #f2f2f2;
  
  &-text {
    font-size: 12px;
  }
}

.home_stories {
  &__container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: baseline;
    margin: 10px;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__item {
    flex: 1 0 0;
    margin: 0 10px;
  }

  &__label {
    text-align: center;
    margin-top: 5px;
    font-size: 11px;
  }

  &__link {
    display: block;
    text-align: center;
    width: 70px;

    img {
      border-radius: 50%;
    }
  }
}

.home-band-countdown {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #021031;
  max-width: 1440px;
  margin: 0 auto;

  &-title {
    font-size: 26px;
    font-weight: 500;
    letter-spacing: -0.5px;
    color: #ffffff;
    padding: 25px 0;

    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 1.14;
      width: 70px;
      text-align: center;
      padding: 10px 0;
    }
  }

  .countdown-second-title {
    @media (max-width: 767px) {
      display: none;
    }
  }

  .home-band-countdown-date {
    display: flex;
  }

  .date-item-number {
    font-size: 46px;
    line-height: 1.33;
    letter-spacing: 0.3px;
    color: #ffffff;

    @media (max-width: 767px) {
      font-size: 30px;
      letter-spacing: 0.2px;
      font-weight: 500;
    }
  }

  .date-item-text {
    font-size: 12px;
    color: #ffffff;
    margin-left: 10px;

    @media (max-width: 767px) {
      font-size: 10px;
      margin-left: 4px;
    }
  }

  .date-item-text-second {
    margin-left: 7px !important;

    @media (max-width: 767px) {
      margin-left: 0px !important;
    }
  }

  .date-item-box {
    display: flex;
  }

  .point {
    margin: 0 10px;
  }
}

.home-blog {
  padding: 0 25px;
  overflow: hidden;
  @media (max-width: 768px) {
    padding: 0 15px;
    margin-top: 20px;
  }

  .brand__box {
    padding: 0;
    img{
      transition: transform 300ms ease-out;
      &:hover {
        transform: scale(1.05);
      }
    }
  }

  h2 {
    font-size: 2rem;
  }

  .see-all-blog {
    color: #666;
    text-decoration: underline !important;
    font-size: 14px;
    padding:  20px 0 35px;

  }
}

.product-detail-note {
  padding: 5px;
  background-color: #f2f2f2;
  
  &-text {
    font-size: 12px;
  }
}

.js-home-slider:not(.lightSlider){
  display: flex;
}

.js-home-slider .slider__item,.js-solo-banner-observe img{
    aspect-ratio: 2 auto;
}

@media screen and (max-width:768px) {
  .js-solo-banner-observe img{
    aspect-ratio: 1.8 auto;
  }
}
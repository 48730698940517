.otp-page {
  &__title {
    text-align: center;
    padding: 0 30px;

    h1 {
      font-size: 24px;
    }

    @media (min-width: 768px) {
      padding: 0;
    }
  }

  &__form {
    width: 100%;
    text-align: center;

    .input-wrapper {
      margin-bottom: 20px;
      .phone__input {
        width: 100%;
        height: 56px;
        padding: 19px 11px 18px 11px;
        border: solid 1px #c9c9c9;
        background-color: #ffffff;
        outline: none;
      }
    }
    .otp-btn {
      width: 100%;
      background-color: #000;
      color: #fff;
      height: 52px;
      line-height: 50px;
      text-align: center;
      border: 0 none;
    }

    .error-code-message {
      display: block;
      font-size: 14px;
      color: #9d9d9d;
      margin-top: 8px;
    }
    .error-code {
      display: block;
      font-size: 14px;

      &__resend {
        color: #000;
        text-decoration: underline !important;
      }
    }
  }

  .muted-text {
    font-size: 14px;
    color: #9d9d9d;
  }

  .link {
    font-size: 14px;
    color: #000000;
    text-decoration: underline !important;
  }

  &__login-choice {
    width: 100%;
    display: flex;
    margin-bottom: 10px;

    .choose {
      width:50%;
      border: 1px solid #000;
      height: 52px;
      line-height: 50px;
      text-align: center;
    }

    .email {
      background-color: #000;

      a {
        color: #fff;
      }
    }
    
    .gsm {
      border-left:none;
      color: #000;
      background-color: #fff;
    }
  }

  @media (min-width: 768px) {
    max-width: 410px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@import './partials/user-panel/authenticated/index.scss';
@import './partials/user-panel/unauthenticated/index.scss';
@import './partials/user-panel/mini-basket/index.scss';

.user-panel {
  display: flex;

  &__favorite {

    &.panel-tracking {
      display: block !important;

      span {
        @media only screen and (max-width: 768px) {
          display: none !important;
        }
      }
    }

    @media only screen and (max-width: 768px) {
      display: none !important;
    }

    a{
      display: flex;
      flex-direction: column;
      align-items: center;

      .icon-tracking {
        font-size: 30px;
        width: 30px;
        height: 30px;
      }
  
      i{
        font-size: 24px;
        color: #9d9d9d;
        width: 24px;
        height: 24px;

        @media only screen and (max-width: 768px) {
          color: #000000;
        }
      }
  
      span {
        font-size: 12px;
        color: #000000;
      }
    }
  }
}